import { Icon } from "@iconify/react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/use-auth";
import Api from "src/libs/api";
import CRMUtils from "src/utils";

export const SwitchBetweenOrganizationsButton = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchUserOrganization = async (organization_id) => {
    try {
      if (organization_id != user.organization_id) {
        setIsLoading(organization_id);
        const { data } = await Api.switchUserOrganization(
          user.id,
          organization_id
        );
        const currentRoute = window.location.origin + window.location.pathname;

        window.location.href = CRMUtils.formatUrlAccordingToOrganizationToken(
          data.data.organization_token,
          currentRoute
        );
      }
    } catch (error) {
      if (error.status == 403) {
        toast.error(t("common:general.forbidden"), {
          duration: 5000,
          style: { backgroundColor: "rgb(211 39 39 / 77%)" },
        });
      } else {
        toast.error(t("common:general.error_message"), {
          duration: 5000,
          style: { backgroundColor: "rgb(211 39 39 / 77%)" },
        });
      }
    } finally {
      setIsLoading(null);
    }
  };

  return (
    <>
      <Button
        style={{ marginLeft: 10 }}
        variant="outlined"
        endIcon={
          <Icon
            icon={"iconamoon:arrow-down-2"}
            style={{ transform: anchorEl ? "rotate(180deg)" : "unset" }}
          />
        }
        onClick={handleClick}
      >
        {user?.organization?.data?.name || "None"}
      </Button>

      <Menu
        PaperProps={{ style: { padding: "0px 6px" } }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {user?.availableOrganizations?.data?.map((org) => {
          return (
            <MenuItem
              disabled={isLoading}
              style={{ padding: 5, minWidth: "fit-content" }}
              onClick={() => {
                switchUserOrganization(org.id);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 8px",
                  paddingLeft: 2,
                }}
              >
                {org.id == user.organization_id ? (
                  <Icon
                    icon="tabler:point-filled"
                    style={{ color: "#10bd65", width: 24, height: 24 }}
                  />
                ) : (
                  ""
                )}
                <Typography
                  style={{
                    marginLeft: 4,
                    fontSize: 14,
                    fontWeight: org.id == user.organization_id ? 600 : 400,
                  }}
                >
                  {org.name}
                </Typography>
                {isLoading && isLoading == org.id ? (
                  <Icon
                    style={{ marginLeft: 4 }}
                    icon={"eos-icons:three-dots-loading"}
                  />
                ) : (
                  ""
                )}
              </div>
            </MenuItem>
          );
        }) || ""}
      </Menu>
    </>
  );
};
