import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip, SvgIcon } from "@mui/material";
import AlignLeft02Icon from "src/icons/untitled-ui/duocolor/align-left-02";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import Building04Icon from "src/icons/untitled-ui/duocolor/building-04";
import CalendarIcon from "src/icons/untitled-ui/duocolor/calendar";
import CheckDone01Icon from "src/icons/untitled-ui/duocolor/check-done-01";
import CreditCard01Icon from "src/icons/untitled-ui/duocolor/credit-card-01";
import CurrencyBitcoinCircleIcon from "src/icons/untitled-ui/duocolor/currency-bitcoin-circle";
import File01Icon from "src/icons/untitled-ui/duocolor/file-01";
import GraduationHat01Icon from "src/icons/untitled-ui/duocolor/graduation-hat-01";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02";
import LineChartUp04Icon from "src/icons/untitled-ui/duocolor/line-chart-up-04";
import Lock01Icon from "src/icons/untitled-ui/duocolor/lock-01";
import LogOut01Icon from "src/icons/untitled-ui/duocolor/log-out-01";
import Mail03Icon from "src/icons/untitled-ui/duocolor/mail-03";
import Mail04Icon from "src/icons/untitled-ui/duocolor/mail-04";
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square";
import ReceiptCheckIcon from "src/icons/untitled-ui/duocolor/receipt-check";
import Share07Icon from "src/icons/untitled-ui/duocolor/share-07";
import ShoppingBag03Icon from "src/icons/untitled-ui/duocolor/shopping-bag-03";
import ShoppingCart01Icon from "src/icons/untitled-ui/duocolor/shopping-cart-01";
import Truck01Icon from "src/icons/untitled-ui/duocolor/truck-01";
import Upload04Icon from "src/icons/untitled-ui/duocolor/upload-04";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import XSquareIcon from "src/icons/untitled-ui/duocolor/x-square";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { Icon } from "@iconify/react";
import { useAuth } from "src/hooks/use-auth";
import { filter, isEmpty } from "lodash";
import CRMUtils from "src/utils";

export const useSections = () => {
  const { t } = useTranslation("common");
  const { user } = useAuth();

  const routes = useMemo(() => {
    return [
      {
        items: [
          {
            title: t("sidebar.Dashboard"),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: t("sidebar.My_orders"),
            path: paths.dashboard.my_orders,
            patient: true,
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="majesticons:list-box-line" />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: t("sidebar.My_family"),
            path: paths.dashboard.my_family,
            patient: true,
            icon: (
              <SvgIcon fontSize="small">
                <Icon icon="vaadin:family" />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: t("sidebar.My_referrals"),
            path: paths.dashboard.my_referrals,
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="gg:list-tree" />
              </SvgIcon>
            ),
            permissions: ['user_refer', 'user_referwithlabel']
          },
        ],
      },
      {
        items: [
          {
            title: t("sidebar.Available_offers"),
            path: paths.dashboard.available_offers,
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="iconamoon:discount-fill" />
              </SvgIcon>
            ),
          },
        ],
      },
      //     {
      //       title: t(tokens.nav.analytics),
      //       path: paths.dashboard.analytics,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <BarChartSquare02Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.ecommerce),
      //       path: paths.dashboard.ecommerce,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LineChartUp04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.crypto),
      //       path: paths.dashboard.crypto,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CurrencyBitcoinCircleIcon />
      //         </SvgIcon>
      //       ),
      //       label: (
      //         <Chip
      //           color="primary"
      //           label="New"
      //           size="small"
      //         />
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.account),
      //       path: paths.dashboard.account,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <HomeSmileIcon />
      //         </SvgIcon>
      //       )
      //     }
      //   ]
      // },
      // {
      //   subheader: t(tokens.nav.concepts),
      //   items: [
      //     {
      //       title: t(tokens.nav.customers),
      //       path: paths.dashboard.customers.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Users03Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.customers.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.customers.details
      //         },
      //         {
      //           title: t(tokens.nav.edit),
      //           path: paths.dashboard.customers.edit
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.productList),
      //       path: paths.dashboard.products.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ShoppingBag03Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.products.index
      //         },
      //         {
      //           title: t(tokens.nav.create),
      //           path: paths.dashboard.products.create
      //         }
      //       ]
      //     },
      // {
      //   title: t(tokens.nav.orderList),
      //   icon: (
      //     <SvgIcon fontSize="small">
      //       <ShoppingCart01Icon />
      //     </SvgIcon>
      //   ),
      //   path: paths.dashboard.orders.index,
      //   items: [
      //     // {
      //     //   title: t(tokens.nav.list),
      //     //   path: paths.dashboard.orders.indexold
      //     // },
      //     {
      //       title: t(tokens.nav.list),
      //       path: paths.dashboard.orders.index
      //     },
      //     // {
      //     //   title: t(tokens.nav.details),
      //     //   path: paths.dashboard.orders.details
      //     // }
      //   ]
      // },
      //     {
      //       title: t(tokens.nav.invoiceList),
      //       path: paths.dashboard.invoices.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ReceiptCheckIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.invoices.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.invoices.details
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.logistics),
      //       path: paths.dashboard.logistics.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Truck01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.dashboard),
      //           path: paths.dashboard.logistics.index
      //         },
      //         {
      //           title: t(tokens.nav.fleet),
      //           path: paths.dashboard.logistics.fleet
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.academy),
      //       path: paths.dashboard.academy.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <GraduationHat01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.dashboard),
      //           path: paths.dashboard.academy.index
      //         },
      //         {
      //           title: t(tokens.nav.course),
      //           path: paths.dashboard.academy.courseDetails
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.jobList),
      //       path: paths.dashboard.jobs.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Building04Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.browse),
      //           path: paths.dashboard.jobs.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.jobs.companies.details
      //         },
      //         {
      //           title: t(tokens.nav.create),
      //           path: paths.dashboard.jobs.create
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.socialMedia),
      //       path: paths.dashboard.social.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Share07Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.profile),
      //           path: paths.dashboard.social.profile
      //         },
      //         {
      //           title: t(tokens.nav.feed),
      //           path: paths.dashboard.social.feed
      //         }
      //   ]
      // },
      //     {
      //       title: t(tokens.nav.blog),
      //       path: paths.dashboard.blog.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LayoutAlt02Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.postList),
      //           path: paths.dashboard.blog.index
      //         },
      //         {
      //           title: t(tokens.nav.postDetails),
      //           path: paths.dashboard.blog.postDetails
      //         },
      //         {
      //           title: t(tokens.nav.postCreate),
      //           path: paths.dashboard.blog.postCreate
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.fileManager),
      //       path: paths.dashboard.fileManager,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Upload04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.kanban),
      //       path: paths.dashboard.kanban,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CheckDone01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.mail),
      //       path: paths.dashboard.mail,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail03Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.chat),
      //       path: paths.dashboard.chat,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <MessageChatSquareIcon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.calendar),
      //       path: paths.dashboard.calendar,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CalendarIcon />
      //         </SvgIcon>
      //       )
      //     }
      //   ]
      // },
      // {
      //   subheader: t(tokens.nav.pages),
      //   items: [
      //     {
      //       title: t(tokens.nav.auth),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Lock01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.login),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.login.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.login.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.register),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.register.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.register.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.forgotPassword),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.forgotPassword.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.forgotPassword.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.resetPassword),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.resetPassword.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.resetPassword.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.verifyCode),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.verifyCode.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.verifyCode.modern
      //             }
      //           ]
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.pricing),
      //       path: paths.pricing,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CreditCard01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.checkout),
      //       path: paths.checkout,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LogOut01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.contact),
      //       path: paths.contact,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.error),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <XSquareIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: '401',
      //           path: paths['401']
      //         },
      //         {
      //           title: '404',
      //           path: paths['404']
      //         },
      //         {
      //           title: '500',
      //           path: paths['500']
      //         }
      //       ]
      //     }
      //   ]
      // },

      {
        subheader: t("sidebar.Management"),
        permissions: ["menu_patients", "menu_orders"],
        items: [
          {
            title: t("sidebar.Patients"),
            path: paths.dashboard.patients.index,
            permissions: ["menu_patients"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="fluent:person-16-regular" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Pending_for_sample"),
            path: paths.dashboard.order_items.pending_for_sample,
            permissions: ["menu_pendingforsample"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="bx:test-tube" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Orders"),
            icon: (
              <SvgIcon fontSize="small">
                <ShoppingCart01Icon />
              </SvgIcon>
            ),
            permissions: ["menu_orders"],
            path: paths.dashboard.orders.index,
          },
          {
            title: t("sidebar.Samples_report"),
            path: paths.dashboard.samples.samples_report,
            permissions: ["menu_samplesreport"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="game-icons:test-tubes" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Kits"),
            path: paths.dashboard.kits.index,
            permissions: ["menu_kits"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="mingcute:test-tube-line" />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t("sidebar.Offers"),
        permissions: ["menu_offers", "menu_offertypes"],
        items: [
          {
            title: t("sidebar.Offers"),
            path: paths.dashboard.offers.index,
            permissions: ["menu_offers"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"solar:ticket-sale-bold"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.OfferTypes"),
            path: paths.dashboard.offers.types,
            permissions: ["menu_offertypes"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"iconamoon:category"} />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t("sidebar.Campaigns"),
        permissions: ["menu_templatecategory", "menu_template"],
        items: [
          {
            title: t("sidebar.Campaigns"),
            path: paths.dashboard.campaigns.index,
            permissions: ["menu_campaigns"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"icon-park-outline:send-email"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Templates"),
            path: paths.dashboard.campaigns.templates,
            permissions: ["menu_template"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"fluent:document-one-page-multiple-24-regular"} />
              </SvgIcon>
            ),
          },

          {
            title: t("sidebar.TemplateCategories"),
            path: paths.dashboard.campaigns.template_categories,
            permissions: ["menu_templatecategory"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"iconamoon:category"} />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t("sidebar.Shipping"),
        permissions: [
          "menu_shippingdashboard",
          "menu_shipping",
          "menu_shippers",
          "menu_senders",
          "menu_laboratories",
          "menu_recipientmodels",
        ],
        items: [
          {
            title: t("sidebar.Shipments_dashboard"),
            path: paths.dashboard.shipping.dashboard,
            permissions: ["menu_shippingdashboard"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"bxs:dashboard"} />
              </SvgIcon>
            ),
          },

          {
            title: t("sidebar.Shipments"),
            path: paths.dashboard.shipping.index,
            permissions: ["menu_shipping"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"icon-park-outline:truck"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Pickups"),
            path: paths.dashboard.shipping.pickups,
            permissions: ["menu_pickups"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"mdi:hand-truck"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Shippers"),
            path: paths.dashboard.shipping.shippers,
            permissions: ["menu_shippers"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"mdi:company"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Senders"),
            path: paths.dashboard.shipping.senders,
            permissions: ["menu_senders"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"fluent:box-multiple-arrow-right-20-regular"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Laboratories"),
            path: paths.dashboard.shipping.laboratories,
            permissions: ["menu_laboratories"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"covid:virus-lab-research-microscope"} />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.RecipientModels"),
            path: paths.dashboard.shipping.recipientmodels,
            permissions: ["menu_recipientmodels"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon={"carbon:package"} />
              </SvgIcon>
            ),
          },
        ],
      },

      {
        subheader: t("sidebar.Admin"),
        permissions: [
          "menuadmin_products",
          "menuadmin_users",
          "menuadmin_roles",
          "menuadmin_settings",
        ],
        items: [
          {
            title: t("sidebar.Products"),
            path: paths.dashboard.products_list.index,
            permissions: ["menuadmin_products"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="la:product-hunt" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Product_types"),
            path: paths.dashboard.product_types.index,
            permissions: ["menuadmin_producttypes"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="iconamoon:category" />
              </SvgIcon>
            ),
          },
          // {
          //   title: t("sidebar.Shipping"),
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <Icon icon={"icon-park-outline:truck"} />
          //     </SvgIcon>
          //   ),
          //   permissions: ["menu_shippers"],
          //   path: paths.dashboard.shipping.senders,
          //   items: [
          //     {
          //       title: t("sidebar.Shipments"),
          //       path: paths.dashboard.shipping.index,
          //       permissions: ["menu_shipping"],
          //     },
          //     {
          //       title: t("sidebar.Shippers"),
          //       path: paths.dashboard.shipping.shippers,
          //       permissions: ["menu_shippers"],
          //     },
          //     {
          //       title: t("sidebar.Senders"),
          //       path: paths.dashboard.shipping.senders,
          //       permissions: ["menu_senders"],
          //     },
          //     {
          //       title: t("sidebar.Laboratories"),
          //       path: paths.dashboard.shipping.laboratories,
          //       permissions: ["menu_laboratories"],
          //     },
          //     {
          //       title: t("sidebar.RecipientModels"),
          //       path: paths.dashboard.shipping.recipientmodels,
          //       permissions: ["menu_recipientmodels"],
          //     },
          //   ],
          // },
          {
            title: t("sidebar.Organizations"),
            path: paths.dashboard.organizations.index,
            permissions: ["menuadmin_organizations"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="clarity:building-solid" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Departments"),
            path: paths.dashboard.departments.index,
            permissions: ["menuadmin_departments"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="mdi:office-building" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Users"),
            path: paths.dashboard.users.index,
            permissions: ["menuadmin_users"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="fluent:people-community-28-regular" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Roles"),
            path: paths.dashboard.roles.index,
            permissions: ["menuadmin_roles"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="icon-park-outline:maslow-pyramids" />
              </SvgIcon>
            ),
          },
          {
            title: t("sidebar.Logs"),
            path: paths.dashboard.logs.index,
            permissions: ["menuadmin_logs"],
            icon: (
              <SvgIcon fontSize="medium">
                <Icon icon="solar:history-line-duotone" />
              </SvgIcon>
            ),
          },
          
          // {
          //   title: t("sidebar.Settings"),
          //   path: paths.dashboard.settings.index,
          //   permissions: ["menuadmin_settings"],
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <Icon icon="solar:settings-linear" />
          //     </SvgIcon>
          //   ),
          // },
        ],
      },
    ];
  }, [t]);

  let permissionRoutes = routes;

  if (user) {
    const permissionTempRoutes = filter(routes, function (item) {
      return item && !isEmpty(item.permissions)
        ? CRMUtils.userHasPermission(user, item.permissions) &&
            (item.patient ? user.is_or_has_patients : true)
        : item.patient
        ? user.is_or_has_patients
        : true;
    });

    permissionRoutes = permissionTempRoutes.map((item, index) => {
      return {
        ...item,
        items: item.items
          ? item.items.filter((item) => {
              return !isEmpty(item.permissions)
                ? CRMUtils.userHasPermission(user, item.permissions) &&
                    (item.patient ? user.is_or_has_patients : true)
                : item.patient
                ? user.is_or_has_patients
                : true;
            })
          : null,
      };
    });
  }

  return permissionRoutes;
};
