import { Box } from "@mui/material";

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100vw",
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: "inline-flex",
        height: 105,
        width: 140,
        alignItems: "center",
        justifyContent: 'center',
        borderRadius: '30px !important',
        boxShadow: "rgba(0, 0, 0, 0.08) 0px 13px 15px",
      }}
    >
      <img
        style={{ width: 135, height: 80 }}
        src="/assets/logo_ytomorrow.png"
      />
    </Box>
  </Box>
);
