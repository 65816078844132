import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Card,
  TextField,
  Unstable_Grid2 as Grid,
  Divider,
  CircularProgress,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import Api from "src/libs/api";
import { debounce, isEmpty } from "lodash";
import CRMUtils from "src/utils";
import { useAuth } from "src/hooks/use-auth";
import { Popup } from "src/sections/components/controls/Popup";
import { KitForm } from "./KitForm";

export const KitSampleSelector = (props) => {
  const { t } = useTranslation();
  const { onSave, defaultResult = null, setCode } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [kits, setKits] = useState([]);
  const [isKitsLoading, setIsKitsLoading] = useState(false);
  const [value, setValue] = useState(defaultResult);
  const [isKitsOpen, setIsKitsOpen] = useState(false);
  const { user } = useAuth();
  const [selectedValue, setSelectedValue] = useState(defaultResult);
  const userCanCreateKits = CRMUtils.userHasPermission(user, ["kit_create"]);
  const [openKitPopup, setOpenKitPopup] = useState(false);
  const [users, setUsers] = useState([]);

  const getKits = async (code) => {
    setKits([]);
    setIsKitsLoading(true);
    setIsKitsOpen(true);
    // console.log("CODE: ", code);
    if (code) {
      const { data } = await Api.getKits(
        "?filter[status]=39&limit=20&page=1&search=" + code
      );
      setKits([...data.data]);
    } else {
      setKits([]);
    }
    setIsKitsLoading(false);
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => getKits(newValue), 1000),
    []
  );

  const addOrEdit = async (values) => {
    try {
      const { data } = await Api.createKit(values);
      setIsKitsOpen(false);
      setSelectedValue(data.data.code);
      setValue(data.data.code);

      toast.success(t("common:general.Created_successfully"), {
        style: { backgroundColor: "rgb(53 166 80 / 77%)" },
      });
    } catch (err) {
      toast.error(t("common:general.error_message"), {
        style: { backgroundColor: "rgb(211 39 39 / 77%)" },
      });
    }
  };

  const getUsers = async () => {
    const { data } = await Api.getUsersToDropdown();

    setUsers([...data.data]);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div style={{padding: '2px 5px'}}>
      {userCanCreateKits ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            startIcon={<Icon icon="ic:round-add" />}
            onClick={() => {
              setOpenKitPopup(true);
            }}
          >
            {t("kits:add_tooltip")}
          </Button>
        </div>
      ) : (
        ""
      )}

      <TextField
        InputProps={{
          endAdornment: isKitsLoading ? (
            <CircularProgress style={{ width: 25, height: 25 }} />
          ) : (
            ""
          ),
        }}
        fullWidth
        onFocus={(e) => {
          if (value != e.target.value) {
            debouncedSearch(e.target.value);
          }
        }}
        required
        label={t("orders_translation:order_items.barcode.kit_code_input")}
        name="code"
        onChange={(data) => {
          debouncedSearch(data.target.value);
          setValue(data.target.value);
        }}
        value={value}
      />

      {
        <Grid style={{ paddingTop: 0 }} item xs={12}>
          {isKitsOpen && (!isKitsLoading || !isEmpty(kits)) ? (
            <Card elevation={10} style={{ backgroundColor: "#ffffff0d" }}>
              <Box>
                <List>
                  <Divider component="li" />
                  {kits.map((item, index) => {
                    return (
                      <>
                        {" "}
                        <ListItem
                          role={undefined}
                          dense
                          key={item.id}
                          button
                          onClick={() => {
                            setValue(item.code);
                            setIsKitsOpen(false);
                            setSelectedValue(item.code);
                            if (setCode) {
                              setCode(item.code);
                            }
                          }}
                        >
                          <ListItemText id={index} primary={item.code} />
                        </ListItem>
                        <Divider component="li" />
                      </>
                    );
                  })}
                </List>
              </Box>
            </Card>
          ) : (
            ""
          )}
        </Grid>
      }
      {onSave ? (
        <div style={{ display: "flex", justifyContent: "right", marginTop: 5 }}>
          <Button
            style={{ marginLeft: 6 }}
            variant="contained"
            disabled={selectedValue != value || !selectedValue}
            onClick={async () => {
              setIsSubmitting(true);
              await onSave(selectedValue);
              setIsSubmitting(false);
            }}
            startIcon={
              isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
            }
          >
            {t("common:genericForm.actions.save")}
          </Button>
        </div>
      ) : (
        ""
      )}

      <Popup
        onClose={() => {
          setOpenKitPopup(false);
        }}
        fullWidth={true}
        maxWidth="sm"
        open={openKitPopup}
        title={t("kits:kit")}
      >
        <KitForm
          onClose={() => {
            setOpenKitPopup(false);
          }}
          addOrEdit={addOrEdit}
          users={users}
        />
      </Popup>
    </div>
  );
};
