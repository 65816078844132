import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enCommonTranslation from "./translations/en/common.json";
import esCommonTranslation from "./translations/es/common.json";
import frCommonTranslation from "./translations/fr/common.json";
import esPatientsTranslations from "./translations/es/patients.json";
import enPatientsTranslations from "./translations/en/patients.json";
import frPatientsTranslations from "./translations/fr/patients.json";
import enMaterialTableTranslations from "./translations/en/materialtable.json";
import esMaterialTableTranslations from "./translations/es/materialtable.json";
import frMaterialTableTranslations from "./translations/fr/materialtable.json";
import enCommentsTranslations from "./translations/en/comments.json";
import esCommentsTranslations from "./translations/es/comments.json";
import frCommentsTranslations from "./translations/fr/comments.json";
import enOrdersTranslations from "./translations/en/orders.json";
import esOrdersTranslations from "./translations/es/orders.json";
import frOrdersTranslations from "./translations/fr/orders.json";
import enProductsTranslations from "./translations/en/products.json";
import esProductsTranslations from "./translations/es/products.json";
import frProductsTranslations from "./translations/fr/products.json";
import enProductTypesTranslations from "./translations/en/producttypes.json";
import esProductTypesTranslations from "./translations/es/producttypes.json";
import frProductTypesTranslations from "./translations/fr/producttypes.json";
import enUsersTranslations from "./translations/en/users.json";
import esUsersTranslations from "./translations/es/users.json";
import frUsersTranslations from "./translations/fr/users.json";
import enOrderItemsTranslations from "./translations/en/orderitems.json";
import esOrderItemsTranslations from "./translations/es/orderitems.json";
import frOrderItemsTranslations from "./translations/fr/orderitems.json";
import enRolesTranslations from "./translations/en/roles.json";
import esRolesTranslations from "./translations/es/roles.json";
import frRolesTranslations from "./translations/fr/roles.json";
import enLoginTranslations from "./translations/en/login.json";
import esLoginTranslations from "./translations/es/login.json";
import frLoginTranslations from "./translations/fr/login.json";
import enPaymentsTranslations from "./translations/en/payments.json";
import esPaymentsTranslations from "./translations/es/payments.json";
import frPaymentsTranslations from "./translations/fr/payments.json";
import enNotificationsTranslations from "./translations/en/notifications.json";
import esNotificationsTranslations from "./translations/es/notifications.json";
import frNotificationsTranslations from "./translations/fr/notifications.json";
import enMyReferralsTranslations from "./translations/en/myreferrals.json";
import esMyReferralsTranslations from "./translations/es/myreferrals.json";
import frMyReferralsTranslations from "./translations/fr/myreferrals.json";
import enLogsTranslations from "./translations/en/logs.json";
import esLogsTranslations from "./translations/es/logs.json";
import frLogsTranslations from "./translations/fr/logs.json";
import enShippingTranslations from "./translations/en/shipping.json";
import esShippingTranslations from "./translations/es/shipping.json";
import frShippingTranslations from "./translations/fr/shipping.json";
import enSamplesTranslations from "./translations/en/samples.json";
import esSamplesTranslations from "./translations/es/samples.json";
import frSamplesTranslations from "./translations/fr/samples.json";
import enTemplatesTranslations from "./translations/en/templates.json";
import esTemplatesTranslations from "./translations/es/templates.json";
import frTemplatesTranslations from "./translations/fr/templates.json";
import enOffersTranslations from "./translations/en/offers.json";
import esOffersTranslations from "./translations/es/offers.json";
import frOffersTranslations from "./translations/fr/offers.json";
import enOrganizationsTranslations from "./translations/en/organizations.json";
import esOrganizationsTranslations from "./translations/es/organizations.json";
import frOrganizationsTranslations from "./translations/fr/organizations.json";
import enKitsTranslations from "./translations/en/kits.json";
import esKitsTranslations from "./translations/es/kits.json";
import frKitsTranslations from "./translations/fr/kits.json";
import enAppSettingsTranslations from "./translations/en/app_settings.json";
import esAppSettingsTranslations from "./translations/es/app_settings.json";
import frAppSettingsTranslations from "./translations/fr/app_settings.json";

import enDepartmentsTranslations from "./translations/en/departments.json";
import esDepartmentsTranslations from "./translations/es/departments.json";
import frDepartmentsTranslations from "./translations/fr/departments.json";

function detectLanguage() {
  const language =
    localStorage.getItem("i18nextLng") ||
    window.navigator.userLanguage ||
    window.navigator.language;
  return language.split("-")[0];
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: enCommonTranslation,
        patients_translations: enPatientsTranslations,
        material_table: enMaterialTableTranslations,
        comments_translation: enCommentsTranslations,
        orders_translation: enOrdersTranslations,
        products_translation: enProductsTranslations,
        product_types: enProductTypesTranslations,
        users: enUsersTranslations,
        order_items: enOrderItemsTranslations,
        roles: enRolesTranslations,
        login: enLoginTranslations,
        payments: enPaymentsTranslations,
        notifications: enNotificationsTranslations,
        my_referrals: enMyReferralsTranslations,
        logs_translations: enLogsTranslations,
        shipping_translations: enShippingTranslations,
        samples: enSamplesTranslations,
        templates: enTemplatesTranslations,
        offers: enOffersTranslations,
        organizations: enOrganizationsTranslations,
        kits: enKitsTranslations,
        app_settings: enAppSettingsTranslations,
        departments: enDepartmentsTranslations,
      },
      es: {
        common: esCommonTranslation,
        patients_translations: esPatientsTranslations,
        material_table: esMaterialTableTranslations,
        comments_translation: esCommentsTranslations,
        orders_translation: esOrdersTranslations,
        products_translation: esProductsTranslations,
        product_types: esProductTypesTranslations,
        users: esUsersTranslations,
        order_items: esOrderItemsTranslations,
        roles: esRolesTranslations,
        login: esLoginTranslations,
        payments: esPaymentsTranslations,
        notifications: esNotificationsTranslations,
        my_referrals: esMyReferralsTranslations,
        logs_translations: esLogsTranslations,
        shipping_translations: esShippingTranslations,
        samples: esSamplesTranslations,
        templates: esTemplatesTranslations,
        offers: esOffersTranslations,
        organizations: esOrganizationsTranslations,
        kits: esKitsTranslations,
        app_settings: esAppSettingsTranslations,
        departments: esDepartmentsTranslations,
      },
      fr: {
        common: frCommonTranslation,
        patients_translations: frPatientsTranslations,
        material_table: frMaterialTableTranslations,
        comments_translation: frCommentsTranslations,
        orders_translation: frOrdersTranslations,
        products_translation: frProductsTranslations,
        product_types: frProductTypesTranslations,
        users: frUsersTranslations,
        order_items: frOrderItemsTranslations,
        roles: frRolesTranslations,
        login: frLoginTranslations,
        payments: frPaymentsTranslations,
        notifications: frNotificationsTranslations,
        my_referrals: frMyReferralsTranslations,
        logs_translations: frLogsTranslations,
        shipping_translations: frShippingTranslations,
        samples: frSamplesTranslations,
        templates: frTemplatesTranslations,
        offers: frOffersTranslations,
        organizations: frOrganizationsTranslations,
        kits: frKitsTranslations,
        app_settings: frAppSettingsTranslations,
        departments: frDepartmentsTranslations,
      },
    },
    lng: detectLanguage(),
    fallbackLng: "es",
    ns: ["translation", "common", "pages", "components"],
    defaultNS: "translation",
    detection: {
      order: ["localStorage", "navigator"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
