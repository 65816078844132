import PropTypes from "prop-types";
// @mui
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

MultiCheckBox.MultiCheckBox = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function MultiCheckBox({ name, options, grid = null, onCheck, ...other }) {


  return (
    <FormGroup sx={{ width: "100%", ml: 3 }}>
      <Grid container>
        {options.map((option) => (
          <Grid item xs={12} md={grid ? grid : 12}>
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={
                    option.isCheck
                  }
                  onChange={(e) => {
                    onCheck(option);
                  }}
                />
              }
              label={option.label}
              {...other}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
}
