import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { useAuth } from "src/hooks/use-auth";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";
import { Icon } from "@iconify/react";
import { useState } from "react";
import Api from "src/libs/api";
import { useEffect } from "react";
import moment from "moment/moment";
import { getMessaging, onMessage } from "firebase/messaging";
import { toast } from "react-hot-toast";
import { Popup } from "src/sections/components/controls/Popup";
import { useRouter } from "src/hooks/use-router";
import { useCallback } from "react";
import { Issuer } from "src/utils/auth";
import RetrySubmitPayment from "./auth/jwt/retrysubmitpayment";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import CRMUtils from "src/utils";
import { LanguageSwitch } from "src/layouts/dashboard/language-switch";
import NMIBehavior from "src/components/NMIBehavior";

const Page = () => {
  const { user, updateState } = useAuth();
  usePageView();
  const isMounted = useMounted();
  const [failureText, setFailureText] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { t } = useTranslation();
  const [uncompleted_payments, setUncompletedPayments] = useState([]);
  const navigate = useNavigate();
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [openSubmitPopup, setOpenSubmitPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const router = useRouter();
  const auth = useAuth();

  const messagingDashboard = getMessaging();

  onMessage(messagingDashboard, async (payload) => {
    var body = JSON.parse(payload.notification.body);
    if (body.user_id == user.id && body.payment_succeeded == true) {
      await handleUncompletedPayments();
      toast.success("Redirecting to you dashboard");
      const { data } = await Api.getUser(user.id);
      updateState({ user: { ...data.data } });

      const indexRoute = window.location.origin + paths.dashboard.index;

      window.location.href = CRMUtils.formatUrlAccordingToOrganizationToken(
        user.organization_token,
        indexRoute
      );
    }
  });

  useEffect(() => {
    if (user && user.first_payment_completed == true) {
      const indexRoute = window.location.origin + paths.dashboard.index;

      window.location.href = CRMUtils.formatUrlAccordingToOrganizationToken(
        user.organization_token,
        indexRoute
      );
    }
  }, [user]);

  const handleUncompletedPayments = async () => {
    var { data } = await Api.uncompletedFirstPayment(user.patient.data.id);
    setUncompletedPayments(data.data);
  };

  const handleClickRetry = (item) => {
    setOpenPaymentForm(true);
    setSelectedItem(item);
  };

  const handleClickSubmit = (item) => {
    setOpenSubmitPopup(true);
  };

  useEffect(() => {
    handleUncompletedPayments();
    const indexRoute = window.location.origin + paths.retry_first_payment;

    var retryFirstPaymentPathName =
      CRMUtils.formatUrlAccordingToOrganizationToken(
        user.organization_token,
        indexRoute,
        true
      );
    window.history.pushState(null, null, retryFirstPaymentPathName);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, null, retryFirstPaymentPathName);
    });
  }, []);

  const retryProductPayment = async (payload) => {
    try {
      const { data } = await Api.retryFirstPayment({
        ...payload,
        ...selectedItem,
      });
      if (
        data.transaction_response &&
        data.transaction_response.status == "approved"
      ) {
        if (isMounted()) {
          toast.success(
            t("payments:payment_form.payment_messages.processing_payment")
          );
          setTimeout(() => {
            const indexRoute = window.location.origin + paths.dashboard.index;

            window.location.href =
              CRMUtils.formatUrlAccordingToOrganizationToken(
                user.organization_token,
                indexRoute
              );
          }, 1000);
        }
      } else if (
        data.transaction_response &&
        data.transaction_response.status == "declined"
      ) {
        setFailureText(
          t("payments:payment_form.payment_messages.transaction_declined")
        );
      } else if (
        data.transaction_response &&
        data.transaction_response.status == "error"
      ) {
        setFailureText(data.transaction_response.message);
      } else {
        setFailureText(
          t("payments:payment_form.payment_messages.general_error")
        );
      }
      setIsFormLoading(false);
    } catch (err) {
      setFailureText(t("payments:payment_form.payment_messages.general_error"));
      setIsFormLoading(false);
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.auth.jwt.login);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router]);

  const renderSelectedProducts = () => {
    return (
      <div style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t("payments:first_payments.columns.status")}
              </TableCell>
              <TableCell>
                {t("payments:first_payments.columns.products")}
              </TableCell>
              <TableCell>
                {t("payments:first_payments.columns.amount")}
              </TableCell>
              <TableCell>
                {t("payments:first_payments.columns.actual_amount")}
              </TableCell>
              <TableCell>{t("payments:first_payments.columns.date")}</TableCell>
              <TableCell>
                {t("payments:first_payments.columns.action")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uncompleted_payments.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell
                    style={{ color: item.status_id == 1 ? "orange" : "red" }}
                  >
                    {CRMUtils.getFieldTranslation(item.status)}
                  </TableCell>
                  <TableCell>
                    {item.products_name.map((item_name) => {
                      return (
                        <Typography>
                          {CRMUtils.getFieldTranslation(item_name)}
                        </Typography>
                      );
                    })}
                  </TableCell>
                  <TableCell>${item.amount}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <Typography style={{ fontSize: "0.875rem" }}>
                        $
                        {item.actual_amount == null
                          ? item.amount
                          : item.actual_amount}
                      </Typography>
                      <Typography
                        style={{ fontSize: "0.875rem", color: "#16B364" }}
                      >
                        {item.actual_amount == null ? "" : "(New)"}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    {moment(item.created_at).format("MM/DD/YYYY") +
                      " at " +
                      moment(item.created_at).format("hh:mm A")}
                  </TableCell>
                  <TableCell>
                    {item.status_id == 4 ? (
                      <Button
                        onClick={() => {
                          handleClickRetry(item);
                        }}
                        startIcon={<Icon icon="mdi:credit-card-refresh" />}
                        style={{
                          width: "100%",
                          marginTop: "2px",
                          height: "25px",
                        }}
                        variant="text"
                      >
                        {t("payments:first_payments.retry")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <>
      <Seo title="Retry" />
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginRight: "16px",
          marginTop: "10px",
        }}
      >
        <LanguageSwitch />
        <Button
          startIcon={<Icon icon="vaadin:arrow-backward" />}
          variant="text"
          onClick={handleLogout}
          size="medium"
        >
          {t("payments:first_payments.back")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Card elevation={16} style={{ maxWidth: "94%" }}>
          <CardHeader
            sx={{ pb: 2 }}
            title={t("payments:first_payments.title")}
          />
          <CardContent>
            <form noValidate>
              {renderSelectedProducts(uncompleted_payments)}
              <Button
                fullWidth
                size="large"
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleClickSubmit}
              >
                {t("payments:first_payments.choose_another_p")}
              </Button>
            </form>
          </CardContent>
        </Card>
        <br />
        <div elevation={1} style={{ textAlign: "center", padding: 10 }}>
          <Typography style={{ fontWeight: 300, fontStyle: "italic" }}>
            {`* ${t("payments:first_payments.message")}`}
          </Typography>
          <Box sx={{ "& > :not(style)": { m: 1 }, textAlign: "center" }}></Box>
        </div>
      </div>
      <Popup
        onClose={() => {
          setOpenPaymentForm(false);
          setSelectedItem(null);
        }}
        fullWidth={true}
        maxWidth={"sm"}
        open={openPaymentForm}
        title={t("payments:payment_form.title")}
      >
        <NMIBehavior
          currency={selectedItem?.currency?.iso_code || null}
          setIsFormLoading={setIsFormLoading}
          isFormLoading={isFormLoading}
          setFailureText={setFailureText}
          failureText={failureText}
          merchantConfiguration={user?.merchantConfiguration?.data || null}
          totalToPay={
            selectedItem
              ? selectedItem.actual_amount
                ? selectedItem.actual_amount
                : selectedItem.amount
              : 0
          }
          proccessTransaction={retryProductPayment}
        />
      </Popup>
      <Popup
        onClose={() => {
          setOpenSubmitPopup(false);
        }}
        fullWidth={true}
        maxWidth={"sm"}
        open={openSubmitPopup}
        title={t("payments:first_payments.form_title")}
      >
        <RetrySubmitPayment setOpenSubmitPopup={setOpenSubmitPopup} />
      </Popup>
    </>
  );
};

export default Page;
