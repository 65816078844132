import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PropertyList } from "src/components/property-list";
import { PropertyListItem } from "src/components/property-list-item";
import { useTranslation } from "react-i18next";
import CRMUtils from "src/utils";

export const OrderSummary = (props) => {
  const { order, ...other } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const align = mdUp ? "horizontal" : "vertical";

  return (
    <Card {...other}>
      <CardHeader title={t("orders_translation:details.basic_info")} />
      <Divider />
      <PropertyList>
        <PropertyListItem
          align={align}
          label={t("orders_translation:details.patient_name")}
        >
          <Typography variant="subtitle2">
            {order?.patient?.data?.fullname || ""}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {order?.patient?.data?.address || ""}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {order?.patient?.data?.city || ""}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {order?.patient?.data?.country || ""}
          </Typography>
        </PropertyListItem>
        <Divider />
        <PropertyListItem
          align={align}
          label={t("orders_translation:details.order")}
          value={order?.name || ""}
        />
        <Divider />
        <PropertyListItem
          align={align}
          label={t("orders_translation:details.date")}
          value={
            order && order.created_at
              ? CRMUtils.convertDateToUserTimezone(order.created_at)
              : ""
          }
        />
        <Divider />
        <PropertyListItem
          align={align}
          label={t("orders_translation:details.total_amount")}
          value={
            <div style={{ marginLeft: 5 }}>
              {order
                ? order.total_price_by_currency.map((currency) => {
                    return (
                      <Typography style={{ fontSize: 14 }}>
                        {`${currency.symbol}${currency.price} (${currency.currency})`}
                      </Typography>
                    );
                  })
                : ""}
            </div>
          }
        />
        <Divider />
      </PropertyList>
    </Card>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.object.isRequired,
};
