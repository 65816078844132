import {
  Alert,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/use-auth";
import Api from "src/libs/api";
import Controls from "src/sections/components/controls";
import CRMUtils from "src/utils";
import toast from "react-hot-toast";
import { KitSampleSelector } from "./KitSampleSelector";

export const AddProductsToReferralLinkForm = (props) => {
  const { t } = useTranslation();
  const { onSaveProduct, onClose, selectedProducts = [] } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { user } = useAuth();
  const [fields, setFields] = useState({
    product_id: null,
    price: null,
    user_doctor_id: user && user.doctor ? user.doctor.id : null,
    collection_tube_code: null,
  });
  const [alertMessage, setAlertMessage] = useState(null);
  const [doctors, setDoctors] = useState([]);

  const getProducts = async () => {
    const { data } = await Api.getProducts();

    setProducts([...data.data]);
  };

  const getDoctors = async () => {
    const { data } = await Api.getDoctors(
      "?organization_id=" + user?.organization_id
    );

    setDoctors([...data.data]);
  };

  const productOptions = () => {
    return products.map((item) => {
      return {
        id: item.id,
        name: CRMUtils.getFieldTranslation(item?.name || ""),
      };
    });
  };

  const doctorsOptions = () => {
    if (user && user.doctor) {
      return doctors
        .filter((d) => d.id == user.id)
        .map((doctor) => {
          return {
            id: doctor.id,
            name: doctor.fullname,
          };
        });
    } else {
      return doctors.map((doctor) => {
        return {
          id: doctor.id,
          name: doctor.fullname,
        };
      });
    }
  };

  const saveProduct = async (close = true) => {
    try {
      if (fields.collection_tube_code) {
        fields.collection_tube_code = fields.collection_tube_code
          .trimStart()
          .trimEnd();
      }
      setAlertMessage(null);
      setIsSubmitting(true);
      if (onSaveProduct) {
        if (fields.collection_tube_code) {
          if (
            selectedProducts &&
            selectedProducts.find(
              (i) => i.collection_tube_code == fields.collection_tube_code
            )
          ) {
            setAlertMessage(
              t(
                "users:refer.form.referral_token_selected_product_duplicity_message"
              )
            );
            return null;
          } else {
            const { data } = await Api.checkKitCodeDuplicity(
              user.organization_id,
              fields.collection_tube_code
            );

            if (data.sample) {
              setAlertMessage(t("users:refer.form.sample_duplicity_message"));
              return null;
            } else {
              if (data.referral_token) {
                setAlertMessage(
                  t("users:refer.form.referral_token_duplicity_message")
                );
                return null;
              }
            }
          }
        }

        onSaveProduct({
          ...fields,
          ...{
            name: selectedProduct?.name || "",
            doctor_name: fields.user_doctor_id
              ? doctors?.find((d) => d.id == fields.user_doctor_id)?.fullname
              : "",
          },
        });
        if (close) {
          onClose();
        } else {
          const customEvent = new CustomEvent("reset_barcode_scanner");
          document.dispatchEvent(customEvent);

          setFields({
            product_id: "",
            price: "",
            user_doctor_id: user && user.doctor ? user.doctor.id : "",
            collection_tube_code: "",
          });
          setSelectedProduct(null);
        }
      }
    } catch (err) {
      toast.error(t("common:general.error_message"), {
        style: { backgroundColor: "rgb(211 39 39 / 77%)" },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getProducts();
    getDoctors();
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={12} md={7}>
            <Controls.Select
              onChange={(e) => {
                const product = e.target.value
                  ? products.find((p) => p.id == e.target.value)
                  : null;
                setSelectedProduct({ ...product });

                if (
                  !(
                    product &&
                    product.type &&
                    product.type.data &&
                    product.type.data.sample_required
                  )
                ) {
                  setFields({
                    ...fields,
                    ...{
                      collection_tube_code: null,
                      product_id: e.target.value,
                    },
                  });
                } else {
                  setFields({ ...fields, ...{ product_id: e.target.value } });
                }
              }}
              value={fields.product_id}
              options={productOptions()}
              required
              label={t("users:refer.form.product") + "*"}
              fullWidth={true}
            />
          </Grid>
          <Grid xs={12} md={5}>
            <TextField
              fullWidth
              required
              label={t("users:refer.form.price")}
              onChange={(e) => {
                setFields({ ...fields, ...{ price: e.target.value } });
              }}
              value={fields.price}
              type="number"
            />
          </Grid>
          <Grid xs={12} md={12}>
            <Controls.Select
              onChange={(e) => {
                setFields({ ...fields, ...{ user_doctor_id: e.target.value } });
              }}
              value={fields.user_doctor_id}
              options={doctorsOptions()}
              label={t("users:refer.form.doctor")}
              fullWidth={true}
            />
          </Grid>
        </Grid>

        {selectedProduct &&
        selectedProduct.type &&
        selectedProduct.type.data &&
        selectedProduct.type.data.sample_required ? (
          <>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                marginTop: 10,
                marginLeft: 4,
                marginBottom: 2,
              }}
              color="text.secondary"
            >
              {t("users:refer.form.kit_code")}
            </Typography>
            <div
              style={{
                border: "1px solid #8080803b",
                borderRadius: 10,
                padding: "5px 8px",
              }}
            >
              <KitSampleSelector
                setCode={(code) => {
                  setFields({ ...fields, ...{ collection_tube_code: code } });
                }}
                defaultResult={fields.collection_tube_code}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {alertMessage ? (
          <Alert
            style={{
              marginTop: 10,
              backgroundColor: "rgb(255 146 146 / 30%)",
            }}
            severity="error"
          >
            <div>{alertMessage}</div>
          </Alert>
        ) : (
          ""
        )}
        <div
          style={{
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Button style={{ marginRight: 5 }}>
            {t("common:general.Cancel")}
          </Button>
          <Button
            onClick={saveProduct}
            variant="contained"
            disabled={!fields.product_id || !fields.price || isSubmitting}
            style={{ marginRight: 5 }}
          >
            {t("common:general.Save")}
          </Button>
          <Button
            onClick={() => {
              saveProduct(false);
            }}
            disabled={!fields.product_id || !fields.price || isSubmitting}
            variant="contained"
          >
            {t("users:refer.form.save_and_add_another")}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
