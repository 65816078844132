import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Box, ButtonBase, SvgIcon, useTheme } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { AccountPopover } from './account-popover';
import { useAuth } from 'src/hooks/use-auth';
import { Icon } from '@iconify/react';
import { useEffect } from 'react';


export const AccountButton = () => {
  const {user} = useAuth();
  const popover = usePopover();
  const theme = useTheme();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
          src={user?.avatar || null}
        >
          <SvgIcon>
            <Icon style={{color:theme.palette.primary.dark}} icon ={user && user.gender?  user.gender == "F"? "mdi:face-female": "mdi:person-tie": "ooui:user-avatar"} />
          </SvgIcon>
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
