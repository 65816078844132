import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  SvgIcon,
  Chip,
  Collapse,
  ListItem,
  ListItemText,
  List,
  LinearProgress,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { Icon } from "@iconify/react";
import { useState } from "react";
import Api from "src/libs/api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CRMUtils from "src/utils";

export const Payments = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const { order_id } = props;
  const [open, setOpen] = useState(false);

  const getPayments = async () => {
    setIsLoading(true);
    const { data } = await Api.getOrderPayments(order_id);

    setPayments([...data.data]);
    setIsLoading(false);
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <Scrollbar>
      <Box sx={{ minWidth: 700 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("payments:columns.date")}</TableCell>
              <TableCell>{t("payments:columns.amount")}</TableCell>
              <TableCell>{t("payments:columns.status")}</TableCell>
              <TableCell>{t("payments:columns.receipt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => {
              return (
                <>
                  {" "}
                  <TableRow key={payment.id}>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          if (open == payment.id) {
                            setOpen(null);
                          } else {
                            setOpen(payment.id);
                          }
                        }}
                      >
                        {open == payment.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {payment && payment.created_at
                        ? CRMUtils.convertDateToUserTimezone(payment.created_at)
                        : ""}
                    </TableCell>
                    <TableCell>{`$${payment.amount}`}</TableCell>
                    <TableCell>
                      <Chip
                        style={{
                          fontWeight: 600,
                          color:
                            payment.status_id == 3
                              ? "#30b730"
                              : payment.status_id == 4
                              ? "#d14141"
                              : "#d7805d",
                          backgroundColor:
                            payment.status_id == 3
                              ? "rgb(58 191 58 / 24%)"
                              : payment.status_id == 4
                              ? "rgb(231 143 143 / 21%)"
                              : "rgb(215 189 93 / 20%)",
                        }}
                        label={CRMUtils.getFieldTranslation(payment.status)}
                      />
                    </TableCell>
                    <TableCell>
                      {payment.receipt ? (
                        <a href={payment.receipt} target="_blank">
                          <SvgIcon>
                            <Icon
                              width={25}
                              height={25}
                              icon="fluent:receipt-24-regular"
                            />
                          </SvgIcon>
                        </a>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        style={{ padding: 8 }}
                        in={open == payment.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: 5,
                          }}
                        >
                          <SvgIcon>
                            <Icon icon="fluent:payment-28-filled" />
                          </SvgIcon>

                          <Typography style={{ marginLeft: 5 }}>
                            {" "}
                            {t("payments:details")}
                          </Typography>
                        </div>

                        <List
                          style={{
                            backgroundColor: "#9f9f9f1f",
                            borderRadius: 18,
                          }}
                        >
                          {payment.paymentDetails.data.map((detail) => {
                            return (
                              <ListItem>
                                <ListItemText primary={detail.item_name} />
                                {`${t("payments:columns.amount")}: $${
                                  detail.amount
                                }`}
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
        {isLoading ? (
          <LinearProgress style={{ marginTop: 5 }} />
        ) : payments.length == 0 ? (
          <div style={{ padding: 10, textAlign: "center" }}>
            {t("payments:empty")}
          </div>
        ) : (
          ""
        )}
      </Box>
    </Scrollbar>
  );
};
