import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import {
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { AccountButton } from "../account-button";
import { LanguageSwitch } from "../language-switch";
import { NotificationsButton } from "../notifications-button";
import { Icon } from "@iconify/react";
import { useAuth } from "src/hooks/use-auth";
import { toast } from "react-hot-toast";
import { ReferButton } from "src/components/ReferButton";
import CRMUtils from "src/utils";
import { useTranslation } from "react-i18next";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { SwitchBetweenOrganizationsButton } from "src/components/SwitchBetweenOrganizationsButton";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

export const TopNav = (props) => {
  const { t } = useTranslation();
  const { onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { user } = useAuth();
  const userCanReferWithlabel = CRMUtils.userHasPermission(user, [
    "user_referwithlabel",
  ]);
  const userCanRefer = CRMUtils.userHasPermission(user, ["user_refer"]);
  const userCanSwitchBetweenOrganizations = CRMUtils.userHasPermission(user, [
    "organization_switch",
  ]);

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
          flexWrap: "wrap",
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={onMobileNavOpen}>
                <SvgIcon>
                  <Menu01Icon />
                </SvgIcon>
              </IconButton>
              <Box
                component={RouterLink}
                href={paths.index}
                sx={{
                  display: "flex",
                  width: 90,
                  p: "4px",
                  height: "60px",
                }}
              >
                <img
                  style={{ maxWidth: 150 }}
                  src="/assets/logo_ytomorrow.png"
                />
              </Box>
            </div>
          )}
          {userCanSwitchBetweenOrganizations ? (
            <SwitchBetweenOrganizationsButton />
          ) : (
            ""
          )}
    
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          style={{ flexWrap: "wrap" }}
        >
          {userCanRefer ? (
            userCanReferWithlabel ? (
              <ReferButton />
            ) : (
              <Tooltip title={t("users:refer.copy")}>
                <IconButton
                  onClick={() => {
                    const indexRoute =
                      window.location.origin + paths.auth.jwt.register_options;

                    const linkToRefer =
                      CRMUtils.formatUrlAccordingToOrganizationToken(
                        user.organization_token,
                        indexRoute
                      ) +
                      "?rt=" +
                      user.token_to_refer;

                    navigator.clipboard.writeText(linkToRefer);
                    toast.success(t("common:general.copied_to_clipboard"));
                  }}
                >
                  <Icon icon="octicon:link-24" />
                </IconButton>
              </Tooltip>
            )
          ) : (
            ""
          )}

          <LanguageSwitch />
          <NotificationsButton />
          {/* <ContactsButton /> */}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
