import { Icon } from "@iconify/react";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/use-auth";
import { Popup } from "src/sections/components/controls/Popup";
import { ProductsToReferWithLabel } from "./ProductsToReferWithLabel";
import { paths } from "src/paths";
import CRMUtils from "src/utils";

export const ReferButton = (props) => {
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useAuth();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseProducts = () => {
    setIsProductsOpen(false);
  };

  return (
    <>
      <Tooltip title={t("users:refer.tooltip")}>
        <IconButton onClick={handleClick}>
          <Icon
            style={{ color: theme.palette.text.primary, width: 25, height: 25 }}
            icon="uim:user-arrows"
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            const indexRoute =
              window.location.origin + paths.auth.jwt.register_options;

            const linkToRefer =
              CRMUtils.formatUrlAccordingToOrganizationToken(
                user.organization_token,
                indexRoute
              ) +
              "?rt=" +
              user.token_to_refer;

            navigator.clipboard.writeText(linkToRefer);
            toast.success("Invitation link copied!");
            handleClose();
          }}
        >
          <Icon
            style={{ marginRight: 8 }}
            fontSize={20}
            icon="octicon:link-24"
          />
          {t("users:refer.copy")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsProductsOpen(true);
            handleClose();
          }}
        >
          <Icon
            style={{ marginRight: 8 }}
            fontSize={24}
            icon="fluent:link-settings-24-regular"
          />
          {t("users:refer.refer_with_label")}
        </MenuItem>
      </Menu>

      <Popup
        onClose={onCloseProducts}
        fullWidth={true}
        maxWidth="md"
        open={isProductsOpen}
        title={t("users:refer.refer_with_label")}
      >
        <ProductsToReferWithLabel onClose={onCloseProducts} />
      </Popup>
    </>
  );
};
