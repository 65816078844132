import {
  Button,
  Card,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import Api from "src/libs/api";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "src/hooks/use-auth";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { Popup } from "src/sections/components/controls/Popup";
import CRMUtils from "src/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Tip } from "./tip";
import { Controller, useForm } from "react-hook-form";
import Controls from "src/sections/components/controls";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { AddProductsToReferralLinkForm } from "./forms/AddProductsToReferralLinkForm";
import toast from "react-hot-toast";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";

export const ProductsToReferWithLabel = (props) => {
  const { onClose } = props;
  const { user } = useAuth();
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isProductsPopupOpen, setIsProductsPopupOpen] = useState(false);
  const [referralLink, setReferralLink] = useState(null);
  const NewUserSchema = Yup.object().shape({
    currency_id: Yup.string().required(t("common:general.field_required")),
  });
  const defaultValues = useMemo(
    () => ({
      email: null,
      prepaid: false,
      organization_id: user?.organization_id || null,
      currency_id: user?.organization?.data?.currency_id || null,
      submit: null,
    }),

    [user]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (tokenData) => {
    tokenData["organization_id"] = user?.organization_id || null;
    var payload = {
      tokenData: tokenData,
      tokenDetails: selectedProducts,
    };

    try {
      const { data } = await Api.generateUserUniqueReferralToken(
        user.id,
        payload
      );

      setReferralLink(data);
    } catch (err) {
      toast.error(t("common:general.error_message"), {
        style: { backgroundColor: "rgb(211 39 39 / 77%)" },
      });
    }
  };

  const getCurrencies = async () => {
    const { data } = await Api.getCurrencies();

    setCurrencies([...data.data]);
  };

  const currenciesOptions = () => {
    return currencies.map((currency) => {
      return {
        id: currency.id,
        name: currency.name + ` (${currency.iso_code})`,
      };
    });
  };

  const openProductsPopup = () => {
    setIsProductsPopupOpen(true);
  };

  const onCloseProductsPopup = () => {
    setIsProductsPopupOpen(false);
  };

  const onSaveProduct = (data) => {
    data["temp_id"] =
      (selectedProducts.length == 0
        ? 0
        : selectedProducts.reduce((maxItem, currentItem) => {
            return currentItem.temp_id > maxItem.temp_id
              ? currentItem
              : maxItem;
          }, selectedProducts[0])["temp_id"]) + 1;
    setSelectedProducts([...selectedProducts, data]);
  };

  const onCloseReferralLink = () => {
    setReferralLink(null);
    onClose();
  };

  const removeSelectedProduct = (tempID) => {
    setSelectedProducts([
      ...selectedProducts.filter((p) => p.temp_id != tempID),
    ]);
  };

  const renderProducts = () => {
    return (
      <Scrollbar>
        <div style={{ padding: 2 }}>
          <Typography
            variant="h6"
            style={{
              padding: "8px 2px",
              marginTop: "5px",
              fontSize: 15,
            }}
          >
            Products
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("products_translation:columns.name")}</TableCell>
                <TableCell>{t("products_translation:columns.price")}</TableCell>
                <TableCell>
                  {t("products_translation:columns.doctor")}
                </TableCell>
                <TableCell>
                  {t("products_translation:columns.kit_code")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {CRMUtils.renderElementTranslatable(item?.name || "")}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="subtitle2">
                        {item?.price || ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {item?.doctor_name || ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {item?.collection_tube_code || ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={t("common:general.Remove")}>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            removeSelectedProduct(item.temp_id);
                          }}
                        >
                          <SvgIcon>
                            <Trash02Icon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Button
            variant="text"
            startIcon={<Icon icon={"ic:round-plus"} />}
            fullWidth
            onClick={openProductsPopup}
          >
            {t("users:refer.form.add_products")}
          </Button>
        </div>
      </Scrollbar>
    );
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <>
      <form method={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Card
            style={{
              width: "-webkit-fill-available",
              borderRadius: 10,
              padding: 15,
            }}
          >
            <Tip
              title={`${t("common:general.notice")}: `}
              message={`* ${t("common:general.required_tip")}`}
            />
            &nbsp;
            <Grid container spacing={1}>
              <Grid xs={12} md={12}>
                <Controller
                  name={"email"}
                  control={control}
                  key={"email"}
                  render={({ field }) => {
                    return (
                      <TextField
                        //error={error}
                        // helperText={error ? error.message : ""}

                        fullWidth
                        label={t("users:refer.form.email")}
                        placeholder={t("users:refer.form.email_placeholder")}
                        type="email"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    );
                  }}
                  // rules={element.required != null ? { required: element.required } : null}
                />
              </Grid>
              <Grid xs={12} md={12}>
                <Controller
                  name={"currency_id"}
                  control={control}
                  key={"currency_id"}
                  render={({ field }) => (
                    <Controls.Autocomplete
                      fullWidth
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      options={currenciesOptions()}
                      label={t("users:refer.form.currency") + "*"}
                      placeholder={t("users:refer.form.currency_placeholder")}
                    />
                  )}
                />
                {!!errors && errors.currency_id && (
                  <FormHelperText error sx={{ ml: 1 }}>
                    {errors.currency_id.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            {renderProducts()}
            <Stack
              divider={<Divider />}
              spacing={3}
              sx={{ m: 1, mt: 3, mb: 3 }}
            >
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={3}
              >
                <Stack spacing={0}>
                  <Typography gutterBottom variant="subtitle1">
                    {t("users:refer.form.prepaid")}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {t("users:refer.form.prepaid_message")}
                  </Typography>
                </Stack>

                <Controller
                  name={"prepaid"}
                  control={control}
                  key={"prepaid"}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      color="primary"
                      edge="start"
                      onChange={(value) => field.onChange(value)}
                      value={field.value}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <Button onClick={onClose} disabled={isSubmitting}>
                {t("common:general.Cancel")}
              </Button>
              <Button
                disabled={isSubmitting || selectedProducts.length == 0}
                startIcon={
                  isSubmitting ? (
                    <Icon icon="eos-icons:three-dots-loading" />
                  ) : (
                    ""
                  )
                }
                variant="contained"
                type="submit"
              >
                {t("users:refer.form.get_link")}
              </Button>
            </div>
          </Card>
        </Stack>
      </form>

      <Popup
        onClose={onCloseProductsPopup}
        fullWidth={true}
        maxWidth="sm"
        open={isProductsPopupOpen}
        title={t("users:refer.form.select_product")}
      >
        <AddProductsToReferralLinkForm
          selectedProducts={selectedProducts}
          onClose={onCloseProductsPopup}
          onSaveProduct={onSaveProduct}
        />
      </Popup>

      <Popup
        onClose={onCloseReferralLink}
        fullWidth={true}
        maxWidth="sm"
        open={referralLink || false}
        // title={t("users:refer.form.select_product")}
      >
        <div style={{ padding: 10, paddingTop: 0 }}>
          <Typography
            style={{ fontWeight: 500, marginBottom: 5, marginLeft: 4 }}
          >
            {t("users:refer.form.link_title")}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: 10,
              border: "1px solid #bababa99",
              padding: "0px 8px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ fontSize: 14, maxWidth: "100%", wordWrap: "break-word" }}
            >
              {referralLink || ""}
            </Typography>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(referralLink);
                toast.success(t("common:general.copied_to_clipboard"));
              }}
            >
              <Icon icon="heroicons-outline:clipboard-copy" />
            </IconButton>
          </div>
        </div>
      </Popup>
    </>
  );
};
