export const paths = {
  index: "/dashboard",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
   
    jwt: {
      login: "/auth/login",
      register_options: "/auth/registeroptions",
      register_payment: "/auth/register/finish-registration",
      register: "/auth/register",
      forgotPassword: "/auth/forgot-password",
      createNewPassword: "/auth/create-new-password"
    },
   
  },
  retry_first_payment: "/retryfirstpayment",
  authDemo: {
    forgotPassword: {
      classic: "/auth-demo/forgot-password/classic",
      modern: "/auth-demo/forgot-password/modern",
    },
    login: {
      classic: "/auth-demo/login/classic",
      modern: "/auth-demo/login/modern",
    },
    register: {
      classic: "/auth-demo/register/classic",
      modern: "/auth-demo/register/modern",
    },
    resetPassword: {
      classic: "/auth-demo/reset-password/classic",
      modern: "/auth-demo/reset-password/modern",
    },
    verifyCode: {
      classic: "/auth-demo/verify-code/classic",
      modern: "/auth-demo/verify-code/modern",
    },
  },
  dashboard: {
    index: "/dashboard",
    my_orders: "/dashboard/myorders",
    my_family: "/dashboard/my_family",
    my_referrals: "/dashboard/my_guests",
    available_offers: "/dashboard/available_offers",
    academy: {
      index: "/dashboard/academy",
      courseDetails: "/dashboard/academy/courses/:courseId",
    },
    account: "/dashboard/account",
    analytics: "/dashboard/analytics",
    blank: "/dashboard/blank",
    blog: {
      index: "/dashboard/blog",
      postDetails: "/dashboard/blog/:postId",
      postCreate: "/dashboard/blog/create",
    },
    calendar: "/dashboard/calendar",
    chat: "/dashboard/chat",
    crypto: "/dashboard/crypto",
    customers: {
      index: "/dashboard/customers",
      details: "/dashboard/customers/:customerId",
      edit: "/dashboard/customers/:customerId/edit",
    },
    ecommerce: "/dashboard/ecommerce",
    fileManager: "/dashboard/file-manager",
    invoices: {
      index: "/dashboard/invoices",
      details: "/dashboard/invoices/:orderId",
    },
    jobs: {
      index: "/dashboard/jobs",
      create: "/dashboard/jobs/create",
      companies: {
        details: "/dashboard/jobs/companies/:companyId",
      },
    },
    kanban: "/dashboard/kanban",
    logistics: {
      index: "/dashboard/logistics",
      fleet: "/dashboard/logistics/fleet",
    },
    mail: "/dashboard/mail",
    orders: {
      index: "/dashboard/orders",
      indexold: "/dashboard/ordersold",
      details: "/dashboard/orders/:orderId",
    },
    products_list: {
      index: "/dashboard/products_list",
    },
    product_types: {
      index: "/dashboard/product_types",
    },
    order_items: {
      pending_for_sample: "/dashboard/medical_studies",
    },
    samples: {
      samples_report: "/dashboard/samples_report",
    },
    products: {
      index: "/dashboard/products",
      create: "/dashboard/products/create",
    },
    social: {
      index: "/dashboard/social",
      profile: "/dashboard/social/profile",
      feed: "/dashboard/social/feed",
    },
    users: {
      index: "/dashboard/users",
    },
    roles: {
      index: "/dashboard/roles",
    },
    settings: {
      index: "/dashboard/settings",
    },
    patients: {
      index: "/dashboard/patients",
    },
    logs: {
      index: "/dashboard/logs",
    },
    organizations: {
      index: "/dashboard/organizations",
    },
    departments: {
      index: "/dashboard/departments",
    },
    kits: {
      index: "/dashboard/kits",
    },
    shipping: {
      dashboard: "/dashboard/shipping_dashboard",
      index: "/dashboard/shipping",
      senders: "/dashboard/senders",
      shippers: "/dashboard/shippers",
      laboratories: "/dashboard/laboratories",
      recipientmodels: "/dashboard/recipient_models",
      pickups: "/dashboard/pickups",
    },
    campaigns: {
      index: "/dashboard/campaigns",
      templates: "/dashboard/templates",
      template_categories: "/dashboard/template_categories",
    },
    offers: {
      index: "/dashboard/offers",
      types: "/dashboard/offers_types",
    },
  },
  components: {
    index: "/components",
    dataDisplay: {
      detailLists: "/components/data-display/detail-lists",
      tables: "/components/data-display/tables",
      quickStats: "/components/data-display/quick-stats",
    },
    lists: {
      groupedLists: "/components/lists/grouped-lists",
      gridLists: "/components/lists/grid-lists",
    },
    forms: "/components/forms",
    modals: "/components/modals",
    charts: "/components/charts",
    buttons: "/components/buttons",
    typography: "/components/typography",
    colors: "/components/colors",
    inputs: "/components/inputs",
  },
  docs: "https://material-kit-pro-react-docs.devias.io",
  401: "/401",
  404: "/404",
  500: "/500",
};
