import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import CRMUtils from "src/utils";
import Api from "src/libs/api";

const loginPaths = {
  [Issuer.JWT]: paths.auth.jwt.login,
};

export const AuthGuard = (props) => {
  const { children } = props; 
  const router = useRouter();
  const { user, updateState, signOut } = useAuth();
  const { isAuthenticated, issuer } = useAuth();
  const [checked, setChecked] = useState(false);
  const userRef = useRef(user);

  //window.location.href = "http://localhost:3000/233232232oi/auth/login?returnTo=http%3A%2F%2Flocalhost%3A3000%2F233232232oi%2Fdashboard"

  const check = useCallback(async () => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.href,
      }).toString();
      const href = loginPaths[issuer] + `?${searchParams}`;
      router.replace(href);
    } else {
      const currentURL = window.location.origin + window.location.pathname;
      const currentToken = CRMUtils.getURLOrganizationToken(currentURL);

      if (currentToken != user.organization_token) {
        try {
          const { data } = await Api.getOrganizationByToken(currentToken, true);

          if (data.data) {
            try {
              const response = await Api.switchUserOrganization(
                user.id,
                data.data.id
              );
              userRef.current = { ...user, ...response.data.data };
              await updateState({ user: { ...user, ...response.data.data } });
            } catch (err) {
              await signOut();
            }
          }
        } catch (err) {
          await signOut();
        }
      }

      if (
        isAuthenticated &&
        userRef.current &&
        userRef.current.first_payment_completed == false
      ) {
        const indexRoute = window.location.origin + paths.retry_first_payment;

        window.location.href = CRMUtils.formatUrlAccordingToOrganizationToken(
          userRef.current.organization_token,
          indexRoute
        );
      } else {
        const indexRoute = window.location.origin + window.location.pathname;
        const newURL = CRMUtils.formatUrlAccordingToOrganizationToken(
          userRef.current.organization_token,
          indexRoute
        );

        if (indexRoute != newURL) {
          window.location.href = newURL;
        }
      }
      setChecked(true);
    }
  }, [isAuthenticated, issuer, router, userRef, updateState]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
