import { useCallback, useState } from "react";
import {
  Box,
  Card,
  Stack,
  TextField,
  Unstable_Grid2 as Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  Popover,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Controller } from "react-hook-form";
import Controls from "src/sections/components/controls";
import { Tip } from "../tip";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { debounce, isEmpty } from "lodash";
import DatePicker from "src/sections/components/controls/DatePicker";

export const NewPatientForm = (props) => {
  const { control, setValue, defaultValues, me = false } = props;
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [placePredictionsLocal, setPlacePredictionsLocal] = useState([]);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const setSearch = (search) => {
    getPlacePredictions({ input: search });
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1000),
    []
  );

  const genderOptions = () => {
    return [
      { id: "F", name: "F" },
      { id: "M", name: "M" },
    ];
  };

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length) {
      setPlacePredictionsLocal(placePredictions);
    }
  }, [placePredictions]);

  const setAddressValues = (item) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => {
        setSelectedAddress(placeDetails);
      }
    );
  };

  useEffect(() => {
    try {
      if (selectedAddress && typeof setValue == "function") {
        for (const component of selectedAddress.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "postal_code": {
              setValue("zip_code"+(me ? "_me" : ""), component.long_name, {
                shouldValidate: true,
              });
              break;
            }
            case "locality":
              setValue("city"+(me ? "_me" : ""), component.long_name, {
                shouldValidate: true,
              });
              break;
            case "administrative_area_level_1": {
              setValue("state"+(me ? "_me" : ""), component.short_name, {
                shouldValidate: true,
              });

              break;
            }
            case "country":
              setValue("country"+(me ? "_me" : ""), component.long_name, {
                shouldValidate: true,
              });

              break;
          }
        }
        setValue("address"+(me ? "_me" : ""), selectedAddress.name, {
          shouldValidate: true,
        });

        setPlacePredictionsLocal([]);
      }
    } catch {
      console.error("selectedAddress error");
    }
  }, [selectedAddress]);

  return (
    <>
      <Stack spacing={4}>
        <Card
          style={{
            width: "-webkit-fill-available",
            borderRadius: 10,
            padding: '15px 0px',
          }}
        >
          <Tip
            title={`${t("common:general.notice")}: `}
            message={`* ${t("common:general.required_tip")}`}
          />
          <Grid container spacing={2}>
            <Grid xs={12} md={12}>
              <Grid container spacing={1}>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"fname" + (me ? "_me" : "")}
                    control={control}
                    key={"fname" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.fname || null}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth
                        label="First Name"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"lname" + (me ? "_me" : "")}
                    control={control}
                    key={"lname" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.lname || null}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth
                        label="Last Name"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"phone" + (me ? "_me" : "")}
                    control={control}
                    key={"phone" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.phone || null}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Phone"
                        type="number"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"email" + (me ? "_me" : "")}
                    control={control}
                    key={"email" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.email || null}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={11}>
                  <Controller
                    name={"address" + (me ? "_me" : "")}
                    control={control}
                    key={"address" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.address || null}
                    render={({ field }) => (
                      <TextField
                        InputProps={{
                          endAdornment: isPlacePredictionsLoading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            ""
                          ),
                        }}
                        fullWidth
                        onFocus={(e) => {
                          if (selectedAddress.name != e.target.value) {
                            debouncedSearch(e.target.value);
                          }
                        }}
                        required
                        label={t("patients_translations:form.address")}
                        name="address"
                        onChange={(data) => {
                          debouncedSearch(data.target.value);
                          field.onChange(data.target.value);
                        }}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>

                <Grid xs={12} md={1}>
                  <IconButton
                    aria-describedby={idPopover}
                    onClick={handleInfoClick}
                  >
                    <Icon
                      style={{ width: 28, height: 28, color: "#5e95ff" }}
                      icon={"fluent:info-24-filled"}
                    />
                  </IconButton>

                  <Popover
                    PaperProps={{
                      style: {
                        padding: 10,
                        background: "rgb(99 124 179)",
                        color: "white",
                      },
                    }}
                    id={idPopover}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleInfoClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ maxWidth: 250 }}>
                      {t("common:general.address_message")}
                    </div>
                  </Popover>
                </Grid>
              </Grid>
              {
                <Grid style={{ paddingTop: 0 }} item xs={12}>
                  {!isPlacePredictionsLoading &&
                  !isEmpty(placePredictionsLocal) ? (
                    <Card
                      elevation={10}
                      style={{ backgroundColor: "#ffffff0d" }}
                    >
                      <Box>
                        <List>
                          <Divider component="li" />
                          {placePredictionsLocal.map((item, index) => {
                            return (
                              <>
                                {" "}
                                <ListItem
                                  role={undefined}
                                  dense
                                  key={item.id}
                                  button
                                  onClick={() => {
                                    setAddressValues(item);
                                  }}
                                >
                                  <ListItemText
                                    id={item.index}
                                    primary={item.description}
                                  />
                                </ListItem>
                                <Divider component="li" />
                              </>
                            );
                          })}
                        </List>
                      </Box>
                    </Card>
                  ) : (
                    ""
                  )}
                </Grid>
              }
              <Grid container spacing={1}>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"country" + (me ? "_me" : "")}
                    control={control}
                    key={"country" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.country || null}
                    render={({ field }) => (
                      <TextField
                        disabled
                        fullWidth
                        required
                        label="Country"
                        name="country"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"state" + (me ? "_me" : "")}
                    control={control}
                    key={"state" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.state || null}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        disabled
                        required
                        label="State"
                        name="state"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"city" + (me ? "_me" : "")}
                    control={control}
                    defaultValue={defaultValues?.city || null}
                    key={"city" + (me ? "_me" : "")}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="City"
                        name="city"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"zip_code" + (me ? "_me" : "")}
                    control={control}
                    key={"zip_code" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.zip_code || null}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        disabled
                        required
                        label="Zip code"
                        name="zip_code"
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"gender" + (me ? "_me" : "")}
                    control={control}
                    key={"gender" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.gender || null}
                    render={({ field }) => (
                      <Controls.Select
                        onChange={(value) => field.onChange(value)}
                        value={field.value}
                        allowEmpty={true}
                        emptyLabel={"-"}
                        options={genderOptions()}
                        label={"Gender"}
                        fullWidth={true}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Controller
                    name={"dob" + (me ? "_me" : "")}
                    control={control}
                    key={"dob" + (me ? "_me" : "")}
                    defaultValue={defaultValues?.dob || null}
                    render={({ field }) => (
                      <DatePicker
                        fullWidth
                        label={t("patients_translations:form.dob")}
                        name="dob"
                        disableFuture={true}
                        onChange={(date) =>
                          field.onChange(date ? new Date(date) : null)
                        }
                        value={field.value}
                      />
                    )}
                    // rules={element.required != null ? { required: element.required } : null}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};
