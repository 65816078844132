import { useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Controls from "src/sections/components/controls";

function BarcodeScanner(props) {
  const {
    showScannerDefault = true,
    onSave = null,
    setCode = null,
    defaultResult = null,
  } = props;
  const videoRef = useRef(null);
  const { t } = useTranslation();
  const [result, setResult] = useState(defaultResult);
  const [showScanner, setShowScanner] = useState(showScannerDefault);
  const [message, setMessage] = useState(null);
  const [disabledCamera, setDisableCamera] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const codeReader = new BrowserMultiFormatReader();
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    if (!showScanner) {
      return;
    }
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length) {
          setMessage(null);
          setDisableCamera(false);
          setVideoDevices(
            videoInputDevices.map((dev) => {
              return {
                id: dev.deviceId,
                name: dev.label,
              };
            })
          );
          console.log("videoInputDevices: ", videoInputDevices);
        } else {
          console.warn("No video input devices found");
          setMessage(
            t("orders_translation:order_items.barcode.no_camera_error")
          );
          setShowScanner(false);
          setDisableCamera(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(error);
        setShowScanner(false);
        setDisableCamera(true);
      });

    return () => {
      codeReader.reset();
    };
  }, [showScanner]);



  useEffect(() => {
    if (selectedDevice && showScanner && videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: { exact: selectedDevice } } })
        .then((stream) => {
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error al acceder a la cámara:", error);
          setMessage(error.message);
          setShowScanner(false);
          setDisableCamera(true);
          setIsSubmitting(false);
        });
    }
  }, [selectedDevice, showScanner]);

  useEffect(() => {
    const listener = (event) => {
      setResult('');
      setCode(null);
    };
    document.addEventListener("reset_barcode_scanner", listener);

    return () => {
      document.removeEventListener("reset_barcode_scanner", listener);
    };
  }, []);

  return (
    <div>
      <div style={{ justifyContent: "center", display: "grid", margin: 10 }}>
        {videoDevices.length > 0 && showScanner ? (
          <Controls.Select
            onChange={(e) => {
              setSelectedDevice(e.target.value);
            }}
            value={selectedDevice}
            options={videoDevices}
            label={"Select a device"}
            fullWidth={true}
          />
        ) : (
          ""
        )}
        {message ? <Typography>{message}</Typography> : ""}
        <Button
          disabled={disabledCamera}
          startIcon={
            <Icon
              icon={
                showScanner
                  ? "ph:video-camera-slash-fill"
                  : "ph:video-camera-fill"
              }
            />
          }
          onClick={() => {
            setShowScanner(!showScanner);
          }}
        >
          {showScanner
            ? t("orders_translation:order_items.barcode.turn_off_the_camera")
            : t("orders_translation:order_items.barcode.turn_on_the_camera")}
        </Button>
        <video
          ref={videoRef}
          hidden={!showScanner}
          style={{ borderRadius: 15, maxWidth: "100%" }}
          id="video"
          autoPlay
          playsInline
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "5px 14px",
          marginBottom: "15px",
        }}
      >
        <TextField
          fullWidth
          defaultValue={result}
          value={result}
          placeholder={t("orders_translation:order_items.barcode.input_label")}
          onChange={(e) => {
            setResult(e.target.value);
            setCode(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <Tooltip
                placement="top"
                //arrow
              >
                <IconButton
                  disabled={result == null || result == ""}
                  color="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(result);
                    toast.success(t("common:general.copied_to_clipboard"));
                  }}
                >
                  <Icon icon="fluent:copy-24-regular" />
                </IconButton>
              </Tooltip>
            ),
          }}
        />

        {onSave ? (
          <Button
            style={{ marginLeft: 6 }}
            variant="contained"
            disabled={result == null || result == "" || isSubmitting}
            onClick={async () => {
              setIsSubmitting(true);
              await onSave(result);
              setIsSubmitting(false);
            }}
            startIcon={
              isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
            }
          >
            {t("common:genericForm.actions.save")}
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default BarcodeScanner;
