import { Button, Typography } from "@mui/material";
import { Countdown } from "./Countdown";
import { useState } from "react";
import { Popup } from "src/sections/components/controls/Popup";
import { useTranslation } from "react-i18next";

export const PurchaseExpirationCountdown = (props) => {
  const { maxMinutes, onOk } = props;
  const [expiredPopupOpened, setExpiredPopupOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {!maxMinutes ? (
        ""
      ) : (
        <>
          <Countdown
            maxMinutes={maxMinutes}
            onReachMax={() => {
              setExpiredPopupOpen(true);
            }}
          />{" "}
          <Popup
            onClose={() => {
              if (onOk) {
                onOk();
              }
              setExpiredPopupOpen(false);
            }}
            fullWidth={true}
            maxWidth="xs"
            open={expiredPopupOpened}
            title={"Timeout"}
          >
            <div style={{ textAlign: "center" }}>
              <Typography variant="h6" textAlign={"center"}>
                {t("common:countdown.expired_message")}
              </Typography>
              <Typography textAlign={"center"} color="text.secondary">
                {t("common:countdown.open_it_again")}
              </Typography>
              <br />
              <Button
                onClick={() => {
                  if (onOk) {
                    onOk();
                  }
                  setExpiredPopupOpen(false);
                }}
              >
                {t("common:countdown.ok")}
              </Button>
            </div>
          </Popup>
        </>
      )}
    </>
  );
};
