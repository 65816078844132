import { Icon } from "@iconify/react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { findIndex } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/use-auth";
import Api from "src/libs/api";
import CRMUtils from "src/utils";

export const AvailableOffers = (props) => {
  const { t } = useTranslation();
  const { patient_id = null, products, setProducts, newUser = false } = props;
  const [availableOffers, setAvailableOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(-1);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [organization, setOrganization] = useState(null);
  const { user } = useAuth();

  const checkOrderProducts = async () => {
    if (organization) {
      var payload = {
        products: products,
        new_user: newUser,
        organization_id: organization?.id || null,
      };

      if (patient_id) {
        payload["patient_id"] = patient_id;
      }
      const { data } = await Api.checkOrderProducts(payload);

      if (selectedOffer && selectedOffer != -1) {
        if (!data.data.find((a) => a.id == selectedOffer)) {
          setSelectedOffer(null);
          setSelectedDetail(null);
        } else {
          if (
            selectedDetail &&
            !data.data
              .find((a) => a.id == selectedOffer)
              .offerdetails.data.find((a) => a.id == selectedDetail)
          ) {
            setSelectedOffer(null);
            setSelectedDetail(null);
          }
        }
      }

      setAvailableOffers([...data.data]);
    }
  };

  useEffect(() => {
    checkOrderProducts();
  }, [products, organization]);

  const applyOffer = (offer_id, detail_id) => {
    var productsWithoutDiscount = products.map((p) => {
      return {
        ...p,
        ...{
          offer_id: null,
          base_price: null,
          standard_price:
            !p.express && p.base_price ? p.base_price : p.standard_price,
          express_price:
            p.express && p.base_price ? p.base_price : p.express_price,
          price:
            p.express && p.base_price
              ? p.base_price
              : !p.express && p.base_price
              ? p.base_price
              : p.standard_price,
        },
      };
    });
    if (offer_id != null && offer_id != -1) {
      var offer = availableOffers.find((o) => o.id == offer_id);

      if (offer) {
        if (!offer.general) {
          offer.offerdetails.data
            .filter(
              (a) =>
                ((a.percent_discount != null && a.percent_discount != 0) ||
                  (a.money_discount != null && a.money_discount != 0)) &&
                a.quantity_for_discount != 0
            )
            .forEach((detail) => {
              if (detail.product_id) {
                var quantity_for_discount =
                  detail.quantity_for_discount == null
                    ? 1
                    : detail.quantity_for_discount;

                var tempProducts = [...productsWithoutDiscount]
                  .filter((a) => a.id == detail.product_id)
                  .filter((p) => p.can_be_in_offers)
                  .slice(0, quantity_for_discount);

                var newProducts = productsWithoutDiscount;

                for (const p of tempProducts) {
                  const index = findIndex(productsWithoutDiscount, [
                    "unique_id",
                    p.unique_id,
                  ]);
                  const updatedEvent = {
                    ...productsWithoutDiscount[index],
                    ...{
                      offer_id: offer.id,
                      base_price: productsWithoutDiscount[index].base_price
                        ? productsWithoutDiscount[index].base_price
                        : productsWithoutDiscount[index].express
                        ? productsWithoutDiscount[index].express_price
                        : productsWithoutDiscount[index].standard_price,
                      standard_price: !productsWithoutDiscount[index].express
                        ? detail.money_discount != null
                          ? parseFloat(
                              productsWithoutDiscount[index].base_price
                                ? productsWithoutDiscount[index].base_price
                                : productsWithoutDiscount[index].standard_price
                            ) -
                              parseFloat(detail.money_discount) >=
                            0
                            ? parseFloat(
                                productsWithoutDiscount[index].base_price
                                  ? productsWithoutDiscount[index].base_price
                                  : productsWithoutDiscount[index]
                                      .standard_price
                              ) - parseFloat(detail.money_discount)
                            : 0
                          : parseFloat(
                              productsWithoutDiscount[index].base_price
                                ? productsWithoutDiscount[index].base_price
                                : productsWithoutDiscount[index].standard_price
                            ) -
                            parseFloat(detail.percent_discount) *
                              0.01 *
                              parseFloat(
                                productsWithoutDiscount[index].base_price
                                  ? productsWithoutDiscount[index].base_price
                                  : productsWithoutDiscount[index]
                                      .standard_price
                              )
                        : productsWithoutDiscount[index].standard_price,
                      express_price: productsWithoutDiscount[index].express
                        ? detail.money_discount != null
                          ? parseFloat(
                              productsWithoutDiscount[index].base_price
                                ? productsWithoutDiscount[index].base_price
                                : productsWithoutDiscount[index].express_price
                            ) -
                              parseFloat(detail.money_discount) >=
                            0
                            ? parseFloat(
                                productsWithoutDiscount[index].base_price
                                  ? productsWithoutDiscount[index].base_price
                                  : productsWithoutDiscount[index].express_price
                              ) - parseFloat(detail.money_discount)
                            : 0
                          : parseFloat(
                              productsWithoutDiscount[index].base_price
                                ? productsWithoutDiscount[index].base_price
                                : productsWithoutDiscount[index].express_price
                            ) -
                            parseFloat(detail.percent_discount) *
                              0.01 *
                              parseFloat(
                                productsWithoutDiscount[index].base_price
                                  ? productsWithoutDiscount[index].express_price
                                  : productsWithoutDiscount[index]
                                      .standard_price
                              )
                        : productsWithoutDiscount[index].express_price,
                    },
                  };

                  newProducts[index] = updatedEvent;
                }
                setProducts([...newProducts]);
              }
            });
        } else {
          var detail = offer.offerdetails.data.find(
            (a) => a.id == selectedDetail
          );

          if (!detail.quantity) {
            var newProducts = productsWithoutDiscount;
            var elegibleProducts = [...productsWithoutDiscount].filter(
              (p) => p.can_be_in_offers
            );

            for (const p of elegibleProducts) {
              const index = findIndex(productsWithoutDiscount, [
                "unique_id",
                p.unique_id,
              ]);
              const updatedEvent = {
                ...productsWithoutDiscount[index],
                ...{
                  offer_id: offer.id,
                  base_price: productsWithoutDiscount[index].base_price
                    ? productsWithoutDiscount[index].base_price
                    : productsWithoutDiscount[index].express
                    ? productsWithoutDiscount[index].express_price
                    : productsWithoutDiscount[index].standard_price,
                  standard_price: !productsWithoutDiscount[index].express
                    ? detail.money_discount != null
                      ? parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].standard_price
                        ) -
                          parseFloat(detail.money_discount) >=
                        0
                        ? parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].standard_price
                          ) - parseFloat(detail.money_discount)
                        : 0
                      : parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].standard_price
                        ) -
                        parseFloat(detail.percent_discount) *
                          0.01 *
                          parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].standard_price
                          )
                    : productsWithoutDiscount[index].standard_price,
                  express_price: productsWithoutDiscount[index].express
                    ? detail.money_discount != null
                      ? parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].express_price
                        ) -
                          parseFloat(detail.money_discount) >=
                        0
                        ? parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].express_price
                          ) - parseFloat(detail.money_discount)
                        : 0
                      : parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].express_price
                        ) -
                        parseFloat(detail.percent_discount) *
                          0.01 *
                          parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].express_price
                          )
                    : productsWithoutDiscount[index].express_price,
                },
              };
              newProducts[index] = updatedEvent;
            }
            setProducts([...newProducts]);
          } else {
            var quantity_for_discount =
              detail.quantity_for_discount == null
                ? 1
                : detail.quantity_for_discount;

            var tempProducts = [...productsWithoutDiscount]
              .filter((p) => p.can_be_in_offers)
              .sort((a, b) => {
                if (a.price < b.price) return -1;
                if (a.price > b.price) return 1;
                return 0;
              })
              .slice(0, quantity_for_discount);

            var newProducts = productsWithoutDiscount;

            for (const p of tempProducts) {
              const index = findIndex(productsWithoutDiscount, [
                "unique_id",
                p.unique_id,
              ]);
              const updatedEvent = {
                ...productsWithoutDiscount[index],
                ...{
                  offer_id: offer.id,
                  base_price: productsWithoutDiscount[index].base_price
                    ? productsWithoutDiscount[index].base_price
                    : productsWithoutDiscount[index].express
                    ? productsWithoutDiscount[index].express_price
                    : productsWithoutDiscount[index].standard_price,
                  standard_price: !productsWithoutDiscount[index].express
                    ? detail.money_discount != null
                      ? parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].standard_price
                        ) -
                          parseFloat(detail.money_discount) >=
                        0
                        ? parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].standard_price
                          ) - parseFloat(detail.money_discount)
                        : 0
                      : parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].standard_price
                        ) -
                        parseFloat(detail.percent_discount) *
                          0.01 *
                          parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].standard_price
                          )
                    : productsWithoutDiscount[index].standard_price,
                  express_price: productsWithoutDiscount[index].express
                    ? detail.money_discount != null
                      ? parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].express_price
                        ) -
                          parseFloat(detail.money_discount) >
                        0
                        ? parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].express_price
                          ) - parseFloat(detail.money_discount)
                        : 0
                      : parseFloat(
                          productsWithoutDiscount[index].base_price
                            ? productsWithoutDiscount[index].base_price
                            : productsWithoutDiscount[index].express_price
                        ) -
                        parseFloat(detail.percent_discount) *
                          0.01 *
                          parseFloat(
                            productsWithoutDiscount[index].base_price
                              ? productsWithoutDiscount[index].base_price
                              : productsWithoutDiscount[index].express_price
                          )
                    : productsWithoutDiscount[index].express_price,
                },
              };

              newProducts[index] = updatedEvent;
            }
            setProducts([...newProducts]);
          }
        }
      }
    } else {
      setProducts([...productsWithoutDiscount]);
    }
  };

  const getOrganization = async () => {
    const currentToken = CRMUtils.getURLOrganizationToken(window.location.href);

    if (currentToken) {
      try {
        const { data } = await Api.getOrganizationByToken(
          currentToken,
          user ? true : false
        );
        setOrganization({ ...data.data });
      } catch (err) {}
    }
  };

  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    applyOffer(selectedOffer, selectedDetail);
  }, [selectedDetail, selectedOffer, products.length]);

  return (
    <>
      {availableOffers.length > 0 ? (
        <div>
          <Typography variant="h6" mb={1}>
            {" "}
            {t("login:registration.available_offers")}
          </Typography>
          <FormControl style={{ width: "100%" }}>
            <FormLabel id="demo-controlled-radio-buttons-group">
              {t("offers:offers.orders_form.select_an_offer")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedOffer || null}
              onChange={(e) => {
                setSelectedOffer(e.target.value);

                var firstDetail = availableOffers.find(
                  (a) => a.id == e.target.value
                )
                  ? availableOffers.find((a) => a.id == e.target.value)
                      .offerdetails.data[0]
                  : null;

                if (firstDetail) {
                  setSelectedDetail(firstDetail.id);
                } else {
                  setSelectedDetail(null);
                }
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: "6px 10px",
                  background: "#a3a3a314",
                  borderRadius: 10,
                  margin: 4,
                }}
              >
                <FormControlLabel
                  value={-1}
                  control={<Radio />}
                  label={t("offers:offers.orders_form.none")}
                />
              </div>

              {availableOffers.map((offer) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      padding: "6px 10px",
                      background: "#a3a3a314",
                      borderRadius: 10,
                      margin: 4,
                    }}
                  >
                    {" "}
                    <FormControlLabel
                      value={offer.id}
                      control={<Radio />}
                      label={CRMUtils.getFieldTranslation(offer.name)}
                    />
                    <div style={{ padding: "0px 10px" }}>
                      {!offer.general ? (
                        <>
                          {" "}
                          <Typography
                            style={{
                              color: "#A0AEC0",
                              fontSize: "15px",
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            {t("offers:offers.orders_form.includes")}
                          </Typography>
                          {offer.offerdetails.data
                            .filter(
                              (a) =>
                                ((a.percent_discount != null &&
                                  a.percent_discount != 0) ||
                                  (a.money_discount != null &&
                                    a.money_discount != 0)) &&
                                a.quantity_for_discount != 0
                            )
                            .map((detail) => {
                              var detailMessage = "";
                              if (detail.product_id) {
                                detailMessage =
                                  (detail.quantity_for_discount == null
                                    ? 1
                                    : detail.quantity_for_discount) +
                                  " " +
                                  CRMUtils.getFieldTranslation(
                                    detail?.product?.data?.name
                                  ) +
                                  " " +
                                  (detail.percent_discount != null &&
                                  detail.percent_discount == 100
                                    ? t("offers:offers.free") + "!"
                                    : t(
                                        "offers:offers.with_discount_message"
                                      ).replaceAll(
                                        "{{discount}}",
                                        detail.percent_discount
                                          ? detail.percent_discount + "%"
                                          : "$" + detail.money_discount
                                      ));
                              }
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    icon="tabler:point-filled"
                                    style={{ marginRight: 5 }}
                                  />{" "}
                                  <Typography>{detailMessage}</Typography>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <FormControl style={{ width: "100%" }}>
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            {t("offers:offers.orders_form.select_a_promotion")}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={selectedDetail || null}
                            onChange={(e) => {
                              setSelectedOffer(offer.id);
                              setSelectedDetail(e.target.value);
                            }}
                          >
                            {offer.offerdetails.data
                              .filter((a) => a.quantity_for_discount != 0)
                              .map((detail) => {
                                var detailMessage = "";

                                if (detail.quantity != null) {
                                  var quantity_for_discount =
                                    detail.quantity_for_discount == null
                                      ? 1
                                      : detail.quantity_for_discount;
                                  detailMessage =
                                    quantity_for_discount +
                                    " " +
                                    (quantity_for_discount == 1
                                      ? t("offers:offers.orders_form.product")
                                      : t(
                                          "offers:offers.orders_form.products"
                                        )) +
                                    " " +
                                    (detail.percent_discount != null &&
                                    detail.percent_discount == 100
                                      ? t("offers:offers.free") + "!"
                                      : t(
                                          "offers:offers.with_discount_message"
                                        ).replaceAll(
                                          "{{discount}}",
                                          detail.percent_discount
                                            ? detail.percent_discount + "%"
                                            : "$" + detail.money_discount
                                        ));
                                } else {
                                  detailMessage =
                                    t(
                                      "offers:offers.orders_form.your_purchase"
                                    ) +
                                    " " +
                                    (detail.percent_discount != null &&
                                    detail.percent_discount == 100
                                      ? t("offers:offers.free") + "!"
                                      : t(
                                          "offers:offers.with_discount_message"
                                        ).replaceAll(
                                          "{{discount}}",
                                          detail.percent_discount
                                            ? detail.percent_discount + "%"
                                            : "$" + detail.money_discount
                                        ));
                                }

                                return (
                                  <FormControlLabel
                                    required={selectedOffer == detail.offer_id}
                                    value={detail.id}
                                    control={
                                      <Radio
                                        required={
                                          selectedOffer == detail.offer_id
                                        }
                                      />
                                    }
                                    label={detailMessage}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    </div>
                  </div>
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
