import CalendarIcon from "@untitled-ui/icons-react/build/esm/Calendar";
import { Button, Container, Stack, SvgIcon, Typography } from "@mui/material";
import { OrderItems } from "src/components/OrderItems";
import { OrderSummary } from "src/components/OrderSummary";
import moment from "moment/moment";
import { findIndex } from "lodash";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Popup } from "src/sections/components/controls/Popup";
import { Payments } from "src/components/Payments";
import { Icon } from "@iconify/react";
import { useAuth } from "src/hooks/use-auth";
import CRMUtils from "src/utils";

function OrderDetails(props) {
  const { user } = useAuth();
  const { order, setOrder = null } = props;
  const { t } = useTranslation();
  const [openPaymentsPopup, setOpenPaymentsPopup] = useState(false);
  const userCanSeePayments = CRMUtils.userHasPermission(user, [
    "order_seeorderpayments",
  ]);

  const updateOrder = (item) => {
    const index = findIndex(order.items.data, ["id", item.data.id]);
    const updatedEvent = {
      ...order.items.data[index],
      ...item.data,
    };
    let newitems = [...order.items.data];
    newitems[index] = updatedEvent;
    setOrder({ ...order, ...{ items: { data: newitems } } });
  };

  const onClosePayments = () => {
    setOpenPaymentsPopup(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={4}>
          <div>
            <Stack
              style={{ flexWrap: "wrap" }}
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography variant="h5">{order?.name || ""}</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography color="text.secondary" variant="body2">
                    {t("orders_translation:details.date")}
                  </Typography>
                  <SvgIcon color="action">
                    <CalendarIcon />
                  </SvgIcon>
                  <Typography variant="body2">
                    {order && order.created_at
                      ? CRMUtils.convertDateToUserTimezone(order.created_at)
                      : ""}
                  </Typography>
                </Stack>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography color="text.secondary" variant="body2">
                    {t("orders_translation:details.placed_by")}
                  </Typography>
                  <SvgIcon color="action">
                    <Icon icon="solar:user-linear" />
                  </SvgIcon>
                  <Typography variant="body2">
                    {order?.createdBy?.data?.fullname || "Unkown user"}
                  </Typography>
                </Stack>
              </Stack>
              {(order && order.created_by == user.id) || userCanSeePayments ? (
                <Button
                  style={{ flexWrap: "wrap" }}
                  startIcon={
                    <Icon icon="streamline:money-cash-dollar-coin-accounting-billing-payment-cash-coin-currency-money-finance" />
                  }
                  onClick={() => {
                    setOpenPaymentsPopup(true);
                  }}
                  variant="contained"
                >
                  {t("payments:title")}
                </Button>
              ) : (
                ""
              )}
            </Stack>
          </div>
          <OrderSummary order={order} />
          <OrderItems
            setOrder={setOrder}
            order={order}
            updateOrder={updateOrder}
            items={order?.items?.data || []}
          />
        </Stack>
      </Container>

      <Popup
        onClose={onClosePayments}
        fullWidth={true}
        maxWidth="md"
        open={openPaymentsPopup}
        title={t("payments:title")}
      >
        <Payments order_id={order?.id || null} />
      </Popup>
    </>
  );
}

export default OrderDetails;
