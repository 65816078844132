import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import CRMUtils from "src/utils";
import { ProductsToReferWithLabel } from "src/components/ProductsToReferWithLabel";
import { Popup } from "src/sections/components/controls/Popup";

export const AccountPopover = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const auth = useAuth();
  const userCanReferWithlabel = CRMUtils.userHasPermission(user, [
    "user_referwithlabel",
  ]);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const userCanRefer = CRMUtils.userHasPermission(user, ["user_refer"]);
  // const user = useMockedUser();

  const handleLogout = useCallback(async () => {
    try {
      onClose?.();

      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.auth.jwt.login);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router, onClose]);

  const onCloseProducts = () => {
    setIsProductsOpen(false);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={onClose}
        open={!!open}
        PaperProps={{ sx: { width: 280 } }}
        {...other}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">
            {user ? user.fname + " " + user.lname : ""}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {user?.email || ""}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 1 }}>
          {userCanRefer ? (
            <ListItemButton
              onClick={() => {
                const indexRoute =
                  window.location.origin + paths.auth.jwt.register_options;

                const linkToRefer =
                  CRMUtils.formatUrlAccordingToOrganizationToken(
                    user.organization_token,
                    indexRoute
                  ) +
                  "?rt=" +
                  user.token_to_refer;

                navigator.clipboard.writeText(linkToRefer);
                toast.success(t("common:general.copied_to_clipboard"));
                onClose();
              }}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">
                  <Icon icon="octicon:link-24" />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t("users:refer.copy")}
                  </Typography>
                }
              />
            </ListItemButton>
          ) : (
            ""
          )}

          {userCanReferWithlabel && userCanRefer ? (
            <ListItemButton
              onClick={() => {
                setIsProductsOpen(true);
                onClose();
              }}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">
                  <Icon fontSize={25} icon="fluent:link-settings-24-regular" />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t("users:refer.refer_with_label")}
                  </Typography>
                }
              />
            </ListItemButton>
          ) : (
            ""
          )}

          <ListItemButton
            component={RouterLink}
            href={paths.dashboard.account}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <Settings04Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">
                  {t("common:general.Settings")}
                </Typography>
              }
            />
          </ListItemButton>
        </Box>
        <Divider sx={{ my: "0 !important" }} />
        <Box
          sx={{
            display: "flex",
            p: 1,
            justifyContent: "center",
          }}
        >
          <Button
            startIcon={<Icon icon="material-symbols:logout-rounded" />}
            color="inherit"
            onClick={handleLogout}
            size="small"
          >
            {t("common:general.logout")}
          </Button>
        </Box>
      </Popover>
      <Popup
        onClose={onCloseProducts}
        fullWidth={true}
        maxWidth="md"
        open={isProductsOpen}
        title={t("users:refer.refer_with_label")}
      >
        <ProductsToReferWithLabel onClose={onCloseProducts} />
      </Popup>
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
