import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { Scrollbar } from 'src/components/scrollbar';
import { Icon } from '@iconify/react';
import { Popup } from 'src/sections/components/controls/Popup';
import NotificationsPopup from './NotificationsPopup';
import { useState } from 'react';
import Api from 'src/libs/api';
import OrderDetails from 'src/pages/dashboard/orders/OrderDetail';
import { useTranslation } from 'react-i18next';
import CRMUtils from 'src/utils';

const renderContent = (notification, la, t) => {
  const createdAt = format(new Date(notification.created_at).getTime(), 'MMM dd, h:mm a');


  return (
    <>
      <ListItemAvatar sx={{ mt: 0.5 }}>
        <Avatar src={notification.avatar}>
          <SvgIcon>
            <Icon icon="basil:notification-solid" />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <Typography
              sx={{ mr: 0.5 }}
              variant="subtitle2"
            >
              {CRMUtils.getFieldTranslation(notification.text) }
            </Typography>

          </Box>
        )}
        secondary={(
          <div>
            <Typography
              color="text.secondary"
              variant="caption"
            >
              {createdAt}
            </Typography>
            {notification.view == 0 ? <Chip color='primary' style={{ marginLeft: 7, fontSize: 11, height: 22 }} label={t('notifications:new')} /> : ''}


          </div>

        )}
        sx={{ my: 0 }}
      />
    </>
  );
};

export const NotificationsPopover = (props) => {
  const {
    totalUnread,
    anchorEl,
    notifications,
    onClose,
    setTotalUnread,
    onMarkAllAsRead,
    markAsRead,
    open = false,
    ...other
  } = props;
  const [openAll, setOpenAll] = useState(false);
  const isEmpty = notifications.length === 0;
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openOrder, setOpenOrder] = useState(false);
  const { i18n, t } = useTranslation();

  const getOrder = async (id) => {
    const { data } = await Api.getOrder(id);

    setSelectedOrder(data.data);
  }

  const onClickNotification = (notification) => {
    if (notification.type_id == 1) {
      var data = JSON.parse(notification.data)[0];
      getOrder(data.order_id).then(() => {
        setOpenOrder(true);
      })
    }
  }

  const onCloseOrder = () => {
    setOpenOrder(false);
    setSelectedOrder(null);
  }

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        disableScrollLock
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 380 } }}
        {...other}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            px: 3,
            py: 2
          }}
        >
          <Typography
            color="inherit"
            variant="h6"
          >
            {t('notifications:title')}
          </Typography>
          <Tooltip title={t('notifications:mark_all')}>
            <span>
              <IconButton
                disabled={totalUnread == 0}
                onClick={onMarkAllAsRead}
                size="small"
                color="primary"
              >
                <SvgIcon>
                  <Icon icon="tabler:bell-check" />
                </SvgIcon>
              </IconButton>
            </span>

          </Tooltip>
        </Stack>
        {isEmpty
          ? (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="subtitle2">
                {t('notifications:empty')}
              </Typography>
            </Box>
          )
          : (
            <Scrollbar sx={{ maxHeight: 600 }}>
              <List disablePadding>
                {notifications.map((notification) => (
                  <ListItem
                    onClick={() => {
                      onClickNotification(notification)
                    }}
                    divider
                    key={notification.id}
                    sx={{
                      alignItems: 'flex-start',
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      },
                      '& .MuiListItemSecondaryAction-root': {
                        top: '24%'
                      }
                    }}
                    secondaryAction={(
                      notification.view == 0 ?
                        <Tooltip title={t('notifications:mark_as_read')}>
                          <IconButton
                            edge="end"
                            onClick={(e) => { e.stopPropagation(); markAsRead(notification.id) }}
                            size="small"
                          >
                            <SvgIcon color='primary'>
                              <Icon icon="solar:check-read-line-duotone" />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip> : ''
                    )}
                  >
                    {renderContent(notification, i18n.language, t)}

                  </ListItem>
                ))}
                <Divider />
                <Button style={{ width: '100%' }} onClick={() => {
                  setOpenAll(true);
                  onClose();
                }}>
                  {t('notifications:view_all')}
                </Button>
              </List>
            </Scrollbar>
          )}
      </Popover>

      <Popup
        onClose={() => setOpenAll(false)}
        fullWidth={true}
        open={openAll}
        title={t('notifications:title')}
      >
        <div>

          <NotificationsPopup
            totalUnread={totalUnread}
            setTotalUnread={setTotalUnread}
            renderContent={renderContent}
          />
        </div>
      </Popup>

      <Popup
        onClose={onCloseOrder}
        fullWidth={true}
        maxWidth="lg"
        open={openOrder}
        title={t('orders_translation:order')}
      >
        <OrderDetails order={selectedOrder} setOrder={setSelectedOrder} />
      </Popup>
    </>

  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onMarkAllAsRead: PropTypes.func,
  markAsRead: PropTypes.func,
  open: PropTypes.bool
};
