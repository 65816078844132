import { useCallback } from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { tokens } from "src/locales/tokens";
import { Icon } from "@iconify/react";
import Api from "src/libs/api";
import { useAuth } from "src/hooks/use-auth";

export const languageOptions = {
  en: {
    icon: "twemoji:flag-united-states",
    label: "English",
    key: "en",
  },

  es: {
    icon: "emojione-v1:flag-for-spain",
    label: "Spanish",
    key: "es",
  },
  fr: {
    icon: "twemoji:flag-france",
    label: "French",
    key: "fr",
  },
};

export const LanguagePopover = (props) => {
  const { anchorEl, onClose, open = false, ...other } = props;
  const { i18n, t } = useTranslation();
  const { user } = useAuth();

  const handleChange = useCallback(
    async (language) => {
      if (user) {
        await Api.updateUserLanguage(user.id, language);
      }

      onClose?.();

      await i18n.changeLanguage(language);
      const message = t("common:general.LanguageChanged");
      toast.success(message);
    },
    [onClose, i18n, t]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 220 } }}
      {...other}
    >
      {Object.keys(languageOptions).map((language) => {
        const option = languageOptions[language];

        return (
          <MenuItem onClick={() => handleChange(language)} key={language}>
            <ListItemIcon>
              <Box
                sx={{
                  width: 28,
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <SvgIcon>
                  <Icon icon={option.icon} />
                </SvgIcon>
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{option.label}</Typography>
              }
            />
          </MenuItem>
        );
      })}
    </Popover>
  );
};

LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
