import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

export default function DatePicker(props) {
  const {
    label,
    value,
    format,
    onChange,
    fullWidth = true,
    TextFieldStyle = {},
    required = false,
    readOnly=false,
    ...others
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={value}
        inputFormat={format ? format : "MM/dd/yyyy"}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            style={TextFieldStyle}
            required={required}
            {...params}
            fullWidth={fullWidth}
            inputProps={{ ...params.inputProps, readOnly: readOnly }}
          />
        )}
        {...others}
      />
    </LocalizationProvider>
  );
}
