import { Icon } from "@iconify/react";

import { useTranslation } from "react-i18next";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Typography, styled } from "@mui/material";
import { t } from "i18next";
import i18n from "src/locales/i18n";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const apiGetFiltersDefault = {
  include: [],
  limit: 0,
  page: 0,
  params: [],
  filters: [],
  search: null,
  sort: null,
};

const CRMUtils = {
  userHasPermission: function (user, routePermissions) {
    if (!routePermissions) return true;

    let hasPermission = false;

    const permissions = user.permissions.data.map((permission) => {
      return permission.name;
    });
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  },
  userHasRole: function (user, routeRoles) {
    if (!routeRoles) return true;

    let hasRole = false;
    const roles = user.roles.data.map((role) => {
      return role.name;
    });
    routeRoles.forEach((role) => {
      if (roles.includes(role)) {
        hasRole = true;
      }
    });

    return hasRole;
  },
  getFieldTranslation: function (field, la = i18n.language) {
    if (field) {
      var keys = [];

      Object.keys(field).forEach((key) => {
        if (field[key] && !isEmpty(field[key])) {
          keys.push(key);
        }
      });

      if (keys[0]) {
        return field[la]
          ? field[la]
          : field["en"]
          ? field["en"]
          : field[keys[0]];
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  renderElementTranslatable: function (
    field,
    la = i18n.language,
    title = t("common:general.missing_translation"),
    textStyle = {}
  ) {
    return field ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography style={textStyle}>
          {this.getFieldTranslation(field, la)}
        </Typography>
        {!field[la] ? (
          <HtmlTooltip
            style={{ margin: "0px 6px", marginTop: 2 }}
            title={title}
          >
            <span>
              <Icon
                style={{ width: 20, height: 20, color: "rgb(96 179 205)" }}
                icon="ion:alert-circle"
              />
            </span>
          </HtmlTooltip>
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  },
  getApiFilters(filtersCustom) {
    const filters = { ...apiGetFiltersDefault };
    if (filtersCustom != null) {
      filters.include =
        filtersCustom.include != null ? filtersCustom.include : filters.include;
      filters.page =
        filtersCustom.page != null ? filtersCustom.page : filters.page;
      filters.limit =
        filtersCustom.limit != null ? filtersCustom.limit : filters.limit;
      filters.params =
        filtersCustom.params != null ? filtersCustom.params : filters.params;
      filters.filters =
        filtersCustom.filters != null ? filtersCustom.filters : filters.filters;
      filters.search =
        filtersCustom.search != null ? filtersCustom.search : filters.search;
      filters.sort =
        filtersCustom.sort != null ? filtersCustom.sort : filters.sort;
    }
    return filters;
  },

  /**
   * Returns the valid URL for the current organization.
   * @param token The token of the current organization.
   * @param url URL to check and correct if necessary.
   * @param onlyPathname If true, only the pathname of the url will be returned.
   */
  formatUrlAccordingToOrganizationToken: function (
    token,
    url = "",
    onlyPathname = false
  ) {
    const urlObj = new URL(url);
    const currentToken = urlObj.pathname.split("/")[1];
    var urlToReturn = url;
    if (currentToken != token) {
      if (currentToken.startsWith("OTKN")) {
        urlToReturn = url.replace(currentToken, token);
      } else {
        urlToReturn = url.replace(
          urlObj.pathname,
          "/" + token + urlObj.pathname
        );
      }
    }

    if (onlyPathname) {
      return new URL(urlToReturn).pathname;
    } else {
      return urlToReturn;
    }
  },

  /**
   * Returns the organization token present in the URL.
   * @param url The URL where to search for the token.
   */
  getURLOrganizationToken: function (url = "") {
    const urlObj = new URL(url);
    const currentToken = urlObj.pathname.split("/")[1];

    if (currentToken && currentToken.startsWith("OTKN")) {
      return currentToken;
    }

    return null;
  },

  /**
   * Returns the date in the user's time zone.
   * @param utcDate The date to transform (must be in UTC).
   */
  convertDateToUserTimezone: function (utcDate) {
    const timezone = moment.tz.guess();

    // Convertir la fecha UTC a la zona horaria del usuario
    const localDateTime = moment.utc(utcDate).tz(timezone);

    // Obtener la configuración regional del navegador
    const locale = navigator.language;

    // Formatear la fecha según la configuración regional del navegador
    const formattedDate = new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(localDateTime.toDate());
    return formattedDate;
  },
};

export default CRMUtils;
