import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CRMUtils from "src/utils";

export const OfferRequirements = (props) => {
  const { offer } = props;
  const { t } = useTranslation();

  return (
    <>
      {offer && offer.general ? (
        offer.offerdetails.data.length > 0 ? (
          <>
            <Typography
              style={{ marginLeft: 16, fontSize: 18, fontWeight: 600 }}
            >
              {t("offers:offers.message_without_products")}
            </Typography>
            {offer.offerdetails.data.map((detail, index) => {
              var message = "";
              if (detail.quantity && detail.quantity > 1) {
                message = t("offers:offers.general_products_with_qty")
                  .replaceAll(
                    "{{discount}}",
                    detail.percent_discount
                      ? detail.percent_discount + "%"
                      : "$" + detail.money_discount
                  )
                  .replaceAll("{{quantity}}", detail.quantity)
                  .replaceAll(
                    "{{quantity_for_discount}}",
                    detail.quantity_for_discount
                  );
              } else {
                if (detail.quantity != null) {
                  var quantity_for_discount =
                    detail.quantity_for_discount == null
                      ? 1
                      : detail.quantity_for_discount;
                  message =
                    quantity_for_discount +
                    " " +
                    (quantity_for_discount == 1
                      ? t("offers:offers.orders_form.product")
                      : t("offers:offers.orders_form.products")) +
                    " " +
                    (detail.percent_discount != null &&
                    detail.percent_discount == 100
                      ? t("offers:offers.free") + "!"
                      : t("offers:offers.with_discount_message").replaceAll(
                          "{{discount}}",
                          detail.percent_discount
                            ? detail.percent_discount + "%"
                            : "$" + detail.money_discount
                        ));
                } else {
                  message =
                    t("offers:offers.orders_form.your_purchase") +
                    " " +
                    (detail.percent_discount != null &&
                    detail.percent_discount == 100
                      ? t("offers:offers.free") + "!"
                      : t("offers:offers.with_discount_message").replaceAll(
                          "{{discount}}",
                          detail.percent_discount
                            ? detail.percent_discount + "%"
                            : "$" + detail.money_discount
                        ));
                }
              }
              return (
                <>
                  <ListItem>
                    <ListItemIcon>
                      <Icon icon="tabler:point-filled" />
                    </ListItemIcon>
                    <ListItemText primary={message} />
                  </ListItem>
                </>
              );
            })}
          </>
        ) : (
          ""
        )
      ) : offer.offerdetails.data.length > 0 ? (
        <>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                {t("offers:offers.if_you_buy")}
              </Typography>
            </div>

            <List
              style={{
                border: "1px solid #8080806b",
                background: "#f0f8ff08",
                padding: "6px 15px",
                margin: "7px",
                borderRadius: "10px",
              }}
            >
              {offer.offerdetails.data.map((detail, index) => {
                return (
                  <Typography
                    style={{ fontSize: 15, margin: 5, fontWeight: 500 }}
                  >
                    {"- " +
                      detail.quantity +
                      " " +
                      CRMUtils.getFieldTranslation(
                        detail?.product?.data?.name || null
                      )}
                  </Typography>
                );
              })}
            </List>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon
                style={{
                  width: 24,
                  height: 24,
                  color: "#50aeff",
                  marginRight: 2,
                }}
                icon="foundation:arrow-right"
              />
              <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                {t("offers:offers.purchase_benefits")}
              </Typography>
            </div>

            <List>
              {offer && offer.offerdetails && offer.offerdetails.data
                ? offer.offerdetails.data
                    .filter(
                      (a) =>
                        ((a.percent_discount != null &&
                          a.percent_discount != 0) ||
                          (a.money_discount != null &&
                            a.money_discount != 0)) &&
                        a.quantity_for_discount != 0
                    )
                    .map((detail) => {
                      var message = "";
                      if (detail.quantity) {
                        message =
                          detail.quantity_for_discount +
                          " " +
                          CRMUtils.getFieldTranslation(
                            detail?.product?.data?.name || null
                          ) +
                          " " +
                          t("offers:offers.with_discount_message").replaceAll(
                            "{{discount}}",
                            detail.percent_discount
                              ? detail.percent_discount + "%"
                              : "$" + detail.money_discount
                          );
                      } else {
                        message =
                          CRMUtils.getFieldTranslation(
                            detail?.product?.data?.name || null
                          ) +
                          " " +
                          t("offers:offers.with_discount_message").replaceAll(
                            "{{discount}}",
                            detail.percent_discount
                              ? detail.percent_discount + "%"
                              : "$" + detail.money_discount
                          );
                      }
                      return (
                        <ListItem>
                          <ListItemIcon>
                            <Icon icon="tabler:point-filled" />
                          </ListItemIcon>
                          <ListItemText primary={message} />
                        </ListItem>
                      );
                    })
                : ""}
            </List>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
