import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  Button,
  Card,
  Stack,
  Divider,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import Controls from "src/sections/components/controls";
import { useTranslation } from "react-i18next";
import Api from "src/libs/api";

export const ChangeOrderItemDoctor = (props) => {
  const { t } = useTranslation();
  const {
    onChangeDr,
    setIsSubmitting,
    isSubmitting,
    onClose,
    orderItem,
    orderOrgId,
  } = props;
  const theme = useTheme();
  const NewUserSchema = Yup.object().shape({});
  const [doctors, setDoctors] = useState([]);

  const defaultValues = useMemo(
    () => ({
      user_doctor_id: orderItem?.user_doctor_id || null,
      submit: null,
    }),

    [orderItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      setIsSubmitting(true);
      const { data } = await Api.changeOrderItemDoctor(
        payload,
        orderItem?.id || null
      );

      if (typeof onChangeDr == "function") {
        await onChangeDr({ ...data.data });
      }
    } catch (err) {
      toast.error(t("common:general.error_message"), {
        style: { backgroundColor: "rgb(211 39 39 / 77%)" },
      });
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getDoctors = async () => {
    const { data } = await Api.getDoctors("?organization_id=" + orderOrgId);

    setDoctors([...data.data]);
  };

  const doctorsOptions = () => {
    return doctors.map((doctor) => {
      return {
        id: doctor.id,
        name: doctor.fullname,
      };
    });
  };

  useEffect(() => {
    if (orderOrgId) {
      getDoctors();
    }
  }, []);

  return (
    <form method={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Card
          style={{
            width: "-webkit-fill-available",
            borderRadius: 10,
            padding: 15,
          }}
        >
          <Stack divider={<Divider />} spacing={3} sx={{ m: 3 }}>
            <Controller
              name={"user_doctor_id"}
              control={control}
              key={"user_doctor_id"}
              render={({ field }) => (
                <Controls.Select
                  label={t("patients_translations:form.doctor")}
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  allowEmpty={true}
                  options={doctorsOptions()}
                  fullWidth={true}
                />
              )}
            />
          </Stack>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Button onClick={onClose} disabled={isSubmitting}>
              {t("common:general.Cancel")}
            </Button>
            <Button
              disabled={isSubmitting}
              startIcon={
                isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
              }
              variant="contained"
              type="submit"
            >
              {t("common:general.Save")}
            </Button>
          </div>
        </Card>
      </Stack>
    </form>
  );
};
