import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Countdown = (props) => {
  const { maxMinutes = 0.3, onReachMax } = props;
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(maxMinutes * 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000); // Update countdown every second

    // Clear the timer and close the component after 10 minutes (600 seconds)
    setTimeout(() => {
      clearInterval(timer);

      if (onReachMax) {
        onReachMax();
      }
    }, maxMinutes * 60000); // 10 minutes in milliseconds

    // Clean up the interval when the component unmounts or when isOpen changes
    return () => clearInterval(timer);
  }, []);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    <div
      style={{
        display: "flex",
        padding: "4px 10px",
        alignItems: "center",
        background: "rgb(176 196 228 / 31%)",
        margin: 2,
        borderRadius: 8,
        justifyContent: "center",
        marginTop: 12,
      }}
    >
      {" "}
      <Typography style={{ fontWeight: 500 }}>
        {t("common:countdown.title")}
      </Typography>
      <Typography style={{ marginLeft: 6 }}>{`${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${t(
        "common:countdown.min"
      )}`}</Typography>
    </div>
  );
};
