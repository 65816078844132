import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Card,
    Typography,
    TextField,
    Tooltip,
    Pagination,
    CardHeader,
    LinearProgress,
    IconButton,
    SvgIcon,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useCallback } from "react";
import { debounce, findIndex } from "lodash";
import moment from "moment";
import { Icon } from "@iconify/react";
import Api from "src/libs/api";
import { Scrollbar } from "src/components/scrollbar";
import { useAuth } from "src/hooks/use-auth";
import { useTranslation } from "react-i18next";


export default function NotificationsPopup({ renderContent, setTotalUnread, totalUnread }) {
    const [notifications, setNotifications] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [date, setDate] = useState({
        start: null,
        end: null,
    });
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginator, setPaginator] = useState([]);
    const { i18n, t } = useTranslation();

    const getNotifications = async (pagevar = null) => {
        const params = new URLSearchParams([
            ["page", pagevar ? pagevar : page],
            ["limit", limit],
            ["search", searchValue ? searchValue : ""],
            ["start", filters && filters.start ? filters.start : ""],
            ["end", filters && filters.end ? filters.end : ""],
        ]);
        setIsLoading(true);
        const { data } = await Api.getAllNotificationsByUser(user.id, `${params}`);

        setNotifications([...data.data]);
        setPaginator(data.meta.pagination);
        setIsLoading(false);
    };

    const onSearchChange = (value) => {
        setSearchValue(value);
    };

    const debouncedSearch = useCallback(
        debounce((newValue) => onSearchChange(newValue), 800),
        []
    );

    useEffect(() => {
        getNotifications(1);
        setPage(1);
    }, [filters, searchValue]);

    useEffect(() => {
        getNotifications();
    }, [page]);


    const markAsRead = (id) => {
        Api.markNotificationAsRead(id);

        const index = findIndex(notifications, ["id", id]);
        const updatedEvent = {
            ...notifications[index],
            ...{ view: 1 },
        };

        let newNotifications = [...notifications];
        newNotifications[index] = updatedEvent;
        setNotifications([...newNotifications]);

        setTotalUnread(totalUnread - 1)
    }

    return (
        <div style={{ padding: "0px 20px 0px 20px", marginBottom: 18 }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "fit-content",
                    padding: 14,
                    boxShadow: "0 0 14px 0 rgb(53 64 82 / 6%)",
                    borderRadius: "13px",
                    marginInlineEnd: "auto",
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}
            >
                <TextField
                    variant="standard"
                    style={{ width: "280px", maxWidth: 'max-content', marginRight: 5 }}
                    onChange={(value) => {
                        !isLoading
                            ? debouncedSearch(value.target.value)
                            : onSearchChange(value.target.value);
                    }}
                    InputProps={{
                        endAdornment: <SvgIcon color="primary"> <Icon icon="clarity:search-line" /></SvgIcon>,
                    }}
                    label={t('notifications:search')+"..."}
                />
                <div
                    style={{ display: "flex", justifyContent: "left", flexWrap: 'wrap' }}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t('notifications:from')}
                            onChange={(d) =>
                                setDate({
                                    ...date,
                                    start:
                                        d == null ? null : moment(d).utc().format("YYYY-MM-DD 00:00:00"),
                                })
                            }
                            value={date.start}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t('notifications:to')}
                            onChange={(d) => {
                                setDate({
                                    ...date,
                                    end:
                                        d == null ? null : moment(d).utc().format("YYYY-MM-DD 23:59:59"),
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            value={date.end}
                        />
                    </LocalizationProvider>
                    <Tooltip title={t('notifications:apply_filters')}>
                        <span>
                            <IconButton
                                style={{ marginLeft: 5 }}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setFilters({ ...filters, ...date });
                                }}
                            >
                                <Icon icon="ion:filter" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <br></br>
            <div style={{ padding: 5, justifyContent: "center" }}>
                <Pagination
                    style={{ width: "fit-content", margin: "0 auto" }}
                    count={paginator && paginator.total_pages ? paginator.total_pages : 0}
                    page={page}
                    onChange={(e, p) => {
                        setPage(p);
                    }}
                />
            </div>

            {isLoading ? (
                <div style={{ textAlign: "center", marginBottom: 30, marginTop: 15 }}>
                    <LinearProgress />
                </div>
            ) : (
                <Scrollbar style={{ maxHeight: 610 }}>
                    <div>
                        {notifications.length > 0 ? (
                            notifications.map((notification) => {
                                return (
                                    <div style={{ display: 'flex', margin: 5 }}>
                                        {renderContent(notification, i18n.language, t)}

                                        {notification.view == 0 ? <Tooltip title={t('notifications:mark_as_read')}>
                                            <IconButton
                                                edge="end"
                                                onClick={(e) => {e.stopPropagation(); markAsRead(notification.id)}}
                                                size="small"
                                            >
                                                <SvgIcon color='primary'>
                                                    <Icon icon="solar:check-read-line-duotone" />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip> : ''}

                                    </div>
                                );
                            })
                        ) : (
                            <>
                                <Card style={{ boxShadow: "none", marginBottom: 25 }}>
                                    <CardHeader
                                        avatar={
                                            <Icon
                                                icon={"ci:notification-active"}
                                                style={{ color: "#A09E9E" }}
                                                width={25}
                                                height={25}
                                            />
                                        }
                                        title={
                                            <Typography id="innerTitle" style={{ fontSize: 16 }}>
                                                {t('notifications:empty')}
                                            </Typography>
                                        }
                                      
                                    />
                                </Card>
                            </>
                        )}
                    </div>
                </Scrollbar>
            )}
        </div>
    );
}
