import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import { useTheme } from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function ConfirmDialog(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = {
    dialog: {
      padding: theme.spacing(2),
      position: "absolute",
      top: theme.spacing(3),
    },
    dialogTitle: {
      textAlign: "center",
      margin: theme.spacing(2),
    },
    dialogContent: {
      textAlign: "center",
    },
    dialogAction: {
      justifyContent: "center",
      margin: theme.spacing(3),
    },
    titleIcon: {
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "default",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "8rem",
      },
    },
  };

  const {
    confirmDialog,
    setConfirmDialog,
    confirmLoading = false,
    onClose = null,
  } = props;

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle sx={classes.dialogTitle}>
        <IconButton disableRipple sx={classes.titleIcon}>
          <Icon style={{ fontSize: 80 }} icon="solar:question-circle-broken" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions sx={classes.dialogAction}>
        <Button
          disabled={confirmLoading}
          type="button"
          onClick={() => {
            setConfirmDialog({ isOpen: false });
            if (typeof onClose === "function") {
              onClose();
            }
          }}
        >
          {t("common:general.No")}
        </Button>

        <Button
          type="button"
          variant="contained"
          onClick={() => {
            confirmDialog.onConfirm();
          }}
          startIcon={
            confirmLoading ? <Icon icon="eos-icons:three-dots-loading" /> : ""
          }
          disabled={confirmLoading}
        >
          {t("common:general.Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
