import { useCallback, useState } from "react";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Tip } from "src/components/tip";
import { wait } from "src/utils/wait";

export const Popup = (props) => {
  const {
    children,
    onClose,
    fullWidth = true,
    maxWidth = "md",
    open = false,
    title = "",
    ...other
  } = props;
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayArticles, setDisplayArticles] = useState(false);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setDisplayArticles(false);
    setIsLoading(true);
    // Do search here
    await wait(1500);
    setIsLoading(false);
    setDisplayArticles(true);
  }, []);

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      // onClose={onClose}
      open={open}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent style={{padding: 10}}>{children}</DialogContent>
    </Dialog>
  );
};
