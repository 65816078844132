import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { App } from "./app";
import { paths } from "./paths";
import { useAuth } from "./hooks/use-auth";

function DynamicRouter({ children }) {
  const loginPath = paths.auth.jwt.login;
  const forgotPwdPath = paths.auth.jwt.forgotPassword;
  const createNewPwdPath = paths.auth.jwt.createNewPassword;

  if (
    window.location.pathname == loginPath ||
    window.location.pathname == forgotPwdPath ||
    window.location.pathname == createNewPwdPath
  ) {
    return <BrowserRouter>{children}</BrowserRouter>;
  } else {
    const token = window.location.pathname.split("/")[1];

    return <BrowserRouter basename={token}>{children}</BrowserRouter>;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <DynamicRouter>
      <Suspense>
        <App />
      </Suspense>
    </DynamicRouter>
  </HelmetProvider>
);
