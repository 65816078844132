import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Api from "src/libs/api";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY?process.env.REACT_APP_FIREBASE_APIKEY:"AIzaSyDc8FHGKrLH-0flfKPxToAuSW4YGwsMS4Q",
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN?process.env.REACT_APP_FIREBASE_AUTHDOMAIN:"ytomorrow-notifications.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID?process.env.REACT_APP_FIREBASE_PROJECTID:"ytomorrow-notifications",
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET?process.env.REACT_APP_FIREBASE_BUCKET:"ytomorrow-notifications.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID?process.env.REACT_APP_FIREBASE_SENDERID:"782460187429",
  appId: process.env.REACT_APP_FIREBASE_APPID?process.env.REACT_APP_FIREBASE_APPID:"1:782460187429:web:cb8e4c2b3b1e070f9c20cc",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID?process.env.REACT_APP_FIREBASE_MEASUREMENTID:"G-XDED0Y5RD",
};

initializeApp(firebaseConfig);

const publicKey =
process.env.REACT_APP_FIREBASE_CERTIFICATE?process.env.REACT_APP_FIREBASE_CERTIFICATE:"BI3hjvHpKlv2GELmwZZYKkEQbNZJ2Wx60M5HxJJZ_q1wrz2KHCdgALM5XJzZ5wa4wg5a0NWr6_6m4VWti3K8J10";


export const getBrowserToken = (userId) => {
    const messaging = getMessaging();
    getToken(messaging, {
      vapidKey: publicKey,
    })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("deviceToken", currentToken);
          const payload = {
            token: currentToken,
            os: "web",
          };
          Api.registerToken(userId, payload);
          return currentToken;
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

export const onMessageListener = () => {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {

    return payload;
  });
};
