import { Checkbox, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/use-auth";
import Api from "src/libs/api";

export const ReferenceCreditAplicator = (props) => {
  const { setProducts, products = [] } = props;
  const { user } = useAuth();
  const { t } = useTranslation();
  const [userReferralCredit, setUserReferralCredit] = useState(0);
  const [creditToApply, setCreditToApply] = useState(0);
  const [applyCreditChecked, setApplyCreditChecked] = useState(false);

  const getUserReferralCredit = async () => {
    try {
      const { data } = await Api.getAvailableUserReferralCredits(
        user.organization_id,
        user.id
      );

      setUserReferralCredit(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserReferralCredit();
  }, []);

  const onApplyCredit = () => {
    if (applyCreditChecked) {
      onChangeCreditAmount(0);
    }
    setApplyCreditChecked(!applyCreditChecked);
  };

  const getMaxCreditToApply = () => {
    if (products.length == 0) {
      return 0;
    } else {
      const maxPrice = products
        .filter((p) => p.accept_referral_credit)
        .reduce((sum, product) => {
          return (
            sum +
            (product.express
              ? (parseFloat(product.express_price) +
                  (product.applied_credit || 0)) *
                (product.max_referral_credit_percent / 100)
              : (parseFloat(product.standard_price) +
                  (product.applied_credit || 0)) *
                (product.max_referral_credit_percent / 100))
          );
        }, 0);
      return maxPrice - userReferralCredit >= 0 ? userReferralCredit : maxPrice;
    }
  };

  const onChangeCreditAmount = (amount) => {
    setCreditToApply(amount);
    var amountToDistribute = amount ? parseFloat(amount) : 0;
    setProducts([
      ...products.map((p) => {
        if (p.accept_referral_credit) {
          const basePrice = p.express
            ? parseFloat(p.express_price) + (p.applied_credit || 0)
            : parseFloat(p.standard_price) + (p.applied_credit || 0);
          const creditAmount = amountToDistribute
            ? basePrice * (p.max_referral_credit_percent / 100) -
                amountToDistribute >
              0
              ? amountToDistribute
              : basePrice * (p.max_referral_credit_percent / 100)
            : 0;
          amountToDistribute =
            amountToDistribute - creditAmount > 0
              ? amountToDistribute - creditAmount
              : 0;

          return {
            ...p,
            ...{
              standard_price: basePrice - creditAmount,
              applied_credit: creditAmount,
            },
          };
        } else {
          return {
            ...p,
          };
        }
      }),
    ]);
  };

  if (userReferralCredit == 0 || getMaxCreditToApply() == 0) {
    return "";
  }

  return (
    <div style={{ marginTop: 5, marginBottom: 12 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Checkbox
          style={{ paddingLeft: 0 }}
          checked={applyCreditChecked}
          onChange={() => {
            onApplyCredit();
          }}
        />
        <div>
          <Typography>{t("orders_translation:apply_credit")}</Typography>
          <Typography style={{ fontSize: 14 }} color="text.secondary">{`${t(
            "orders_translation:your_total_credit"
          )}: ${userReferralCredit}`}</Typography>
        </div>

        <TextField
          required
          disabled={!applyCreditChecked}
          style={{ marginLeft: 20 }}
          type="input"
          onChange={(e) => {
            if (
              !e.target.value ||
              parseFloat(e.target.value) <= getMaxCreditToApply()
            ) {
              onChangeCreditAmount(e.target.value);
            }
          }}
          value={creditToApply}
          label={t("orders_translation:amount_to_apply")}
          InputProps={{
            endAdornment: (
              <div style={{ textAlign: "center" }}>
                <Typography style={{ fontSize: 12, minWidth: "fit-content" }}>
                  Max
                </Typography>
                <Typography style={{ fontSize: 13 }}>
                  {getMaxCreditToApply()}
                </Typography>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};
