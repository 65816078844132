import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Fab,
  FormHelperText,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stepper,
  Switch,
  TextField,
  Typography,
  useTheme,
  SvgIcon,
} from "@mui/material";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { useAuth } from "src/hooks/use-auth";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useSearchParams } from "src/hooks/use-search-params";
import { paths } from "src/paths";
import { AuthIssuer } from "src/sections/auth/auth-issuer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "src/components/GoogleLoginButton";
import { Icon } from "@iconify/react";
import GoogleVerificationButton from "src/components/GoogleVerificationButton";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Api from "src/libs/api";
import { findIndex } from "lodash";
import { display, fontWeight } from "@mui/system";
import { Scrollbar } from "src/components/scrollbar";
import { toast } from "react-hot-toast";
import { Popup } from "src/sections/components/controls/Popup";
import StripeRetryPaymentFormWrapper from "src/components/forms/StripeRetryPaymentForm";
import { useTranslation } from "react-i18next";
import CRMUtils from "src/utils";
import { AvailableOffers } from "src/components/AvailableOffers";
import NMIBehavior from "src/components/NMIBehavior";
import { PurchaseExpirationCountdown } from "src/components/PurchaseExpirationCountdown";
import TermsAndConditions from "src/components/TermsAndConditions";
import { YtomorrowTermsAndConditions } from "src/components/YtomorrowTermsAndConditions";

const Page = (props) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { setOpenSubmitPopup } = props;
  const { t } = useTranslation();
  const [productsLoading, setProductsLoading] = useState(false);
  const isMounted = useMounted();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalToPay, setTotalToPay] = useState(0);
  const theme = useTheme();
  const { user } = useAuth();
  const [failureText, setFailureText] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [openStripePopup, setOpenStripePopup] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false);

  const stripePopuphandleClick = () => {
    setFailureText(null);
    setIsFormLoading(false);
    setOpenStripePopup(true);
  };

  usePageView();

  const getProducts = async () => {
    if (organization) {
      setProductsLoading(true);
      const { data } = await Api.getRequiredProductsToSubscribe({
        organization_id: organization.id,
      });
      var productsList = data.data.map((product) => ({
        ...product,
        ...{ express: 0, expanded: false },
      }));
      setProducts([...productsList]);
      setProductsLoading(false);
    }
  };

  const changeProduct = (id, fields) => {
    const index = findIndex(products, ["id", id]);
    const updatedEvent = {
      ...products[index],
      ...fields,
    };

    let newProducts = [...products];
    newProducts[index] = updatedEvent;
    setProducts([...newProducts]);
  };

  const initData = {
    userdata: user,
    total_to_pay: totalToPay,
    products: selectedProducts,
    currency_id:
      selectedProducts.length > 0
        ? selectedProducts[0]?.currency_id || null
        : null,
  };

  const renderPriceOptions = (product, onClickParam, price) => {
    return price.value != null ? (
      <Button
        fullWidth
        onClick={() => {
          changeProduct(product.id, { express: onClickParam });
        }}
        button={product.express_price != null}
        sx={{
          minWidth: "-webkit-fill-available",
          color: price.selected
            ? theme.palette.primary.dark
            : theme.palette.text.primary,
          display: "grid",
          border: price.selected ? "1px solid" : "none",
          borderRadius: 1,
          justifyContent: "left",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontWeight: 600, fontSize: 15, marginRight: 5 }}>
            {price.label}
          </Typography>
          {` $${price.value}`}
        </div>
        {price.details != null ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon
              style={{ minWidth: 22, minHeight: 22, marginRight: 8 }}
              icon={"solar:info-circle-bold-duotone"}
            />
            <Typography
              sx={{
                color: price.selected ? theme.palette.text.primary : "none",
                fontSize: 14.3,
              }}
            >
              {price.details}
            </Typography>
          </div>
        ) : (
          ""
        )}
      </Button>
    ) : (
      ""
    );
  };

  const addProduct = (item) => {
    var tempProduct = { ...item };
    tempProduct["unique_id"] =
      selectedProducts.filter((a) => a.unique_id < 0).length > 0
        ? selectedProducts
            .filter((a) => a.unique_id < 0)
            .sort((a, b) => a.unique_id - b.unique_id)[0].unique_id - 1
        : -1;
    setSelectedProducts([...selectedProducts, tempProduct]);
  };

  const purchaseAnotherProduct = async (payload) => {
    try {
      const { data } = await Api.retrySubmitFirstPayment({
        ...payload,
        ...initData,
      });

      if (
        data.transaction_response &&
        data.transaction_response.status == "approved"
      ) {
        if (isMounted()) {
          toast.success(
            t("payments:payment_form.payment_messages.processing_payment")
          );
          setTimeout(() => {
            const indexRoute = window.location.origin + paths.dashboard.index;

            window.location.href =
              CRMUtils.formatUrlAccordingToOrganizationToken(
                user.organization_token,
                indexRoute
              );
          }, 1000);
        }
      } else if (
        data.transaction_response &&
        data.transaction_response.status == "declined"
      ) {
        setFailureText(
          t("payments:payment_form.payment_messages.transaction_declined")
        );
      } else if (
        data.transaction_response &&
        data.transaction_response.status == "error"
      ) {
        setFailureText(data.transaction_response.message);
      } else {
        setFailureText(
          t("payments:payment_form.payment_messages.general_error")
        );
      }
      setIsFormLoading(false);
    } catch (err) {
      setFailureText(t("payments:payment_form.payment_messages.general_error"));
      setIsFormLoading(false);
    }
  };

  const renderProducts = () => {
    return (
      <Card
        style={{
          width: "-webkit-fill-available",
          borderRadius: 10,
          margin: 8,
          padding: 5,
        }}
      >
        <Typography variant="h6">
          {t("login:registration.will_pay_for")}
        </Typography>

        {products.map((product) => {
          return (
            <>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {product.description &&
                      CRMUtils.getFieldTranslation(product.description) ? (
                        <Accordion
                          style={{ boxShadow: "none" }}
                          expanded={product.expanded}
                          onChange={() => {
                            changeProduct(product.id, {
                              expanded: !product.expanded,
                            });
                          }}
                        >
                          <AccordionSummary
                            style={{ flexWrap: "wrap", padding: "0px 2px" }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <Typography
                                  style={{ fontWeight: 500, fontSize: 15 }}
                                >{`${CRMUtils.getFieldTranslation(
                                  product.name
                                )} `}</Typography>

                                <Typography
                                  style={{ marginLeft: 2 }}
                                  color="primary"
                                >{` (${t(
                                  "login:registration.see_details"
                                )}...) `}</Typography>
                              </div>

                              <Button
                                disabled={
                                  product.max_qty == 0 ||
                                  selectedProducts.filter(
                                    (a) => a.id == product.id
                                  ).length == product.max_qty
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addProduct(product);
                                }}
                                style={{
                                  backgroundColor: "#95959529",
                                  padding: 4,
                                }}
                              >
                                <Icon icon="ic:round-add" />
                                {t("common:general.Add")}
                              </Button>
                            </div>
                          </AccordionSummary>
                          {/* <Typography>{`Available points to use: ${user.points}`}</Typography> */}
                          <AccordionDetails
                            style={{
                              borderLeft: "2px solid",
                              borderLeftColor: theme.palette.primary.main,
                              marginBottom: 8,
                            }}
                          >
                            <div
                              style={{ color: theme.palette.text.primary }}
                              dangerouslySetInnerHTML={{
                                __html: CRMUtils.getFieldTranslation(
                                  product.description
                                ),
                              }}
                            ></div>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <div
                          style={{
                            margin: "8px 0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>
                            {CRMUtils.getFieldTranslation(product.name)}
                          </Typography>
                          <Button
                            disabled={
                              product.max_qty == 0 ||
                              selectedProducts.filter((a) => a.id == product.id)
                                .length == product.max_qty
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              addProduct(product);
                            }}
                            style={{
                              backgroundColor: "#95959529",
                              padding: 4,
                            }}
                          >
                            <Icon icon="ic:round-add" />
                            {t("common:general.Add")}
                          </Button>
                        </div>
                      )}
                    </div>
                  }
                  secondary={
                    <div>
                      {renderPriceOptions(product, 0, {
                        label: t("login:registration.standard_price"),
                        value: product.standard_price,
                        details: CRMUtils.getFieldTranslation(
                          product.standard_price_details
                        ),
                        selected: !product.express,
                      })}
                      {renderPriceOptions(product, 1, {
                        label: t("login:registration.express_price"),
                        value: product.express_price,
                        details: CRMUtils.getFieldTranslation(
                          product.express_price_details
                        ),
                        selected: product.express,
                      })}
                    </div>
                  }
                />
              </ListItem>
              <Divider style={{ borderColor: "rgb(171 171 171 / 50%)" }} />
            </>
          );
        })}
      </Card>
    );
  };
  const renderSelectedProducts = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t("orders_translation:new_order.product_columns.name")}
            </TableCell>
            <TableCell>
              {t("orders_translation:new_order.product_columns.express")}
            </TableCell>
            <TableCell>
              {t("orders_translation:new_order.product_columns.price")}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedProducts.map((item) => {
            return (
              <TableRow key={item.unique_id}>
                <TableCell>
                  <Typography variant="subtitle2">
                    {CRMUtils.getFieldTranslation(item.name)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {item.express ? (
                    <Icon
                      fontSize={25}
                      icon="material-symbols:check-small-rounded"
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.base_price &&
                    item.base_price !=
                      (item.express
                        ? item.express_price
                        : item.standard_price) ? (
                      <Typography
                        style={{
                          textDecoration: "2px line-through red",
                          marginRight: 6,
                        }}
                      >
                        {" "}
                        ${item.base_price}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography
                      style={{
                        fontWeight:
                          item.base_price &&
                          item.base_price !=
                            (item.express
                              ? item.express_price
                              : item.standard_price)
                            ? 600
                            : 400,
                        fontSize:
                          item.base_price &&
                          item.base_price !=
                            (item.express
                              ? item.express_price
                              : item.standard_price)
                            ? 17
                            : 15,
                      }}
                    >
                      {" "}
                      ${item.express ? item.express_price : item.standard_price}
                    </Typography>
                  </div>
                  {item.base_price &&
                  item.base_price !=
                    (item.express
                      ? item.express_price
                      : item.standard_price) ? (
                    <Typography
                      style={{
                        color: "rgb(151 151 151)",
                        fontWeight: 500,
                        fontSize: 13,
                      }}
                    >
                      {`${t("offers:offers.orders_form.saved")} $${(
                        parseFloat(item.base_price) -
                        parseFloat(
                          item.express
                            ? item.express_price
                            : item.standard_price
                        )
                      ).toFixed(2)}`}
                    </Typography>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      removeProduct(item);
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <SvgIcon>
                      <Trash02Icon />
                    </SvgIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const removeProduct = (product) => {
    setSelectedProducts(
      selectedProducts.filter((a) => a.unique_id != product.unique_id)
    );
  };
  const updateTotalToPay = () => {
    var total = selectedProducts.reduce((sum, product) => {
      return (
        sum +
        (product.express
          ? parseFloat(product.express_price)
          : parseFloat(product.standard_price))
      );
    }, 0);

    setTotalToPay(total);
  };

  const getOrganization = async () => {
    const currentToken = CRMUtils.getURLOrganizationToken(window.location.href);

    if (currentToken) {
      try {
        const { data } = await Api.getOrganizationByToken(currentToken, true);
        setOrganization({ ...data.data });
      } catch (err) {}
    }
  };

  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    getProducts();
  }, [organization]);

  useEffect(() => {
    updateTotalToPay();
  }, [selectedProducts]);

  return (
    <>
      <Seo title="Make a payment" />
      <PurchaseExpirationCountdown
        maxMinutes={user?.organization?.data?.price_access_duration || null}
        onOk={() => {
          setOpenStripePopup(false);
          setOpenSubmitPopup(false);
        }}
      />
      <div>
        <Scrollbar style={{ maxHeight: 700 }}>
          <Card>
            <div>
              {renderProducts()}

              <div style={{ padding: "15px 15px" }}>
                <br />
                <Typography variant="h6" mb={1}>
                  {" "}
                  {t("orders_translation:new_order.order_products")}
                </Typography>
                {renderSelectedProducts()}
              </div>
              {!productsLoading ? (
                <div
                  style={{
                    display: "flex",
                    margin: "8px 8px",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "right",
                      fontWeight: 600,
                      margin: "0px 15px",

                      fontSize: 15,
                    }}
                  >
                    {`${t("orders_translation:new_order.total_to_pay")}: `}
                  </Typography>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {selectedProducts.find((a) => a.offer_id) ? (
                        <Typography
                          style={{
                            padding: "0px 10px",
                            textDecoration: "2px line-through red",
                            textAlign: "right",
                            fontWeight: 600,

                            fontSize: 15,
                          }}
                        >
                          {`$${selectedProducts
                            .reduce((sum, product) => {
                              return (
                                sum +
                                (product.base_price
                                  ? product.base_price
                                  : product.express
                                  ? parseFloat(product.express_price)
                                  : parseFloat(product.standard_price))
                              );
                            }, 0)
                            .toFixed(2)}`}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography
                        style={{
                          textAlign: "right",
                          fontWeight: 600,
                          marginRight: 6,

                          fontSize: selectedProducts.find((a) => a.base_price)
                            ? 17
                            : 15,
                        }}
                      >
                        {`$${totalToPay}`}
                      </Typography>
                    </div>
                    {selectedProducts.find((a) => a.offer_id) ? (
                      <Typography
                        style={{
                          color: "rgb(151 151 151)",
                          fontWeight: 500,
                          fontSize: 13,
                          marginLeft: 10,
                        }}
                      >
                        {`${t("offers:offers.orders_form.saved")} $${(
                          selectedProducts.reduce((sum, product) => {
                            return (
                              sum +
                              (product.base_price
                                ? product.base_price
                                : product.express
                                ? parseFloat(product.express_price)
                                : parseFloat(product.standard_price))
                            );
                          }, 0) -
                          selectedProducts.reduce((sum, product) => {
                            return (
                              sum +
                              (product.express
                                ? parseFloat(product.express_price)
                                : parseFloat(product.standard_price))
                            );
                          }, 0)
                        ).toFixed(2)}`}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div style={{ padding: "8px 15px" }}>
                {" "}
                <AvailableOffers
                  newUser={true}
                  setProducts={setSelectedProducts}
                  patient_id={null}
                  products={selectedProducts}
                />
              </div>

              <div style={{ marginBottom: 5 }}>
                <TermsAndConditions
                  value={termsAndConditionsChecked}
                  setValue={setTermsAndConditionsChecked}
                  termsAndConditions={<YtomorrowTermsAndConditions />}
                />
              </div>
              <Button
                disabled={
                  !termsAndConditionsChecked || selectedProducts.length == 0
                }
                onClick={stripePopuphandleClick}
                style={{ width: "100%" }}
                variant="contained"
              >
                {t("login:registration.proceed_to_pay")}
              </Button>
            </div>
          </Card>
        </Scrollbar>
        <Popup
          onClose={() => setOpenStripePopup(false)}
          fullWidth={true}
          maxWidth={"sm"}
          open={openStripePopup}
          title={t("payments:payment_form.title")}
        >
          <NMIBehavior
            currency={
              selectedProducts.length > 0
                ? selectedProducts[0]?.currency?.iso_code || null
                : null
            }
            setIsFormLoading={setIsFormLoading}
            isFormLoading={isFormLoading}
            setFailureText={setFailureText}
            failureText={failureText}
            merchantConfiguration={user?.merchantConfiguration?.data || null}
            totalToPay={initData?.total_to_pay}
            proccessTransaction={purchaseAnotherProduct}
          />
          {/* <StripeRetryPaymentFormWrapper
            setOpenSubmitPopup={setOpenSubmitPopup}
            setOpenStripePopup={setOpenStripePopup}
            init_data={initData}
          /> */}
        </Popup>
      </div>
    </>
  );
};

export default Page;
