import { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Api from "src/libs/api";
import { findIndex } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useAuth } from "src/hooks/use-auth";
import { useTranslation } from "react-i18next";
import CRMUtils from "src/utils";

function ProgressSkeleton() {
  return (
    <Stack spacing={1}>
      {" "}
      <Skeleton variant="rounded" sx={{ fontSize: "1rem" }} height={20} />
      <Skeleton variant="rounded" height={40} />
      <Skeleton variant="rounded" height={40} />
    </Stack>
  );
}

export const Products = (props) => {
  const {
    actions = [],
    updateProducts,
    defaultProducts,
    patient_id = null,
  } = props;
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const theme = useTheme();
  const { user } = useAuth();
  const { t } = useTranslation();

  const getProducts = async () => {
    setProductsLoading(true);
    const params = new URLSearchParams([["patient_id", patient_id]]);
    const { data } = await Api.getAvailableProductsToBuy(
      `?1=1&${params.toString()}`
    );

    if (defaultProducts && defaultProducts.length > 0) {
      var productsList = [...data.data];
      defaultProducts.forEach((element) => {
        const index = findIndex(productsList, ["id", element.id]);
        if (index != -1) {
          const updatedEvent = {
            ...productsList[index],
            ...element,
          };

          let newProducts = [...productsList];
          newProducts[index] = updatedEvent;
          productsList = [...newProducts];
        }
      });
      setProducts([...productsList]);
    } else {
      setProducts([...data.data]);
    }
    setProductsLoading(false);
  };

  const changeProduct = (id, fields) => {
    const index = findIndex(products, ["id", id]);
    const updatedEvent = {
      ...products[index],
      ...fields,
    };

    let newProducts = [...products];
    newProducts[index] = updatedEvent;
    setProducts([...newProducts]);

   
  };

  const renderPriceOptions = (product, onClickParam, price) => {
    return price.value != null ? (
      <Button
        fullWidth
        onClick={() => {
          changeProduct(product.id, { express: onClickParam });
        }}
        button={product.express_price != null}
        sx={{
          minWidth: "-webkit-fill-available",
          color: price.selected
            ? theme.palette.primary.dark
            : theme.palette.text.primary,
          display: "grid",
          border: price.selected ? "1px solid" : "none",
          justifyContent: "left",
          borderRadius: 1,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontWeight: 600, fontSize: 15, marginRight: 5 }}>
            {price.label}
          </Typography>
          {` ${product?.currency?.symbol || ''}${price.value} ${product?.currency?.iso_code || ''}`}
        </div>
        {price.details != null ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon
              style={{ minWidth: 22, minHeight: 22, marginRight: 8 }}
              icon={"solar:info-circle-bold-duotone"}
            />
            <Typography
              sx={{
                color: price.selected ? theme.palette.text.primary : "none",
                fontSize: 14.3,
              }}
            >
              {price.details}
            </Typography>
          </div>
        ) : (
          ""
        )}
      </Button>
    ) : (
      ""
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Card
      style={{
        padding: "5px 5px",
        width: "-webkit-fill-available",
        borderRadius: 10,
      }}
    >
      {productsLoading ? (
        <ProgressSkeleton />
      ) : products.length == 0 ? (
        <div
          style={{
            padding: 10,
            justifyContent: "center",
            borderBottom: "1px solid #8080806e",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            style={{ width: 23, height: 23, marginRight: 5 }}
            icon="tabler:message-report"
          />
          <Typography
            style={{
              fontSize: 17,
              textAlign: "center",
            }}
          >
            {t("products_translation:no_available_products")}
          </Typography>
        </div>
      ) : (
        <>
          <div style={{ width: "100%", display: "flex", padding: "0px 10px" }}>
            <Typography variant="h6" style={{ textAlign: "left" }}>
              {`${t("products_translation:add_products_form.products")}`}
            </Typography>
          </div>
          {products.map((product) => {
            return (
              <div
                style={{
                  backgroundColor: "#83838314",
                  borderRadius: 12,
                  marginTop: 12,
                }}
              >
                <div
                  style={{
                    marginBottom: -18,
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  {actions.map((action) => {
                    return <> {action.action(product)}</>;
                  })}
                </div>

                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      product.description &&
                      CRMUtils.getFieldTranslation(product.description) ? (
                        <Accordion
                          style={{ boxShadow: "none", background: "none" }}
                          expanded={product.expanded}
                          onChange={() => {
                            changeProduct(product.id, {
                              expanded: !product.expanded,
                            });
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography
                              style={{ fontWeight: 500 }}
                            >{`${CRMUtils.getFieldTranslation(
                              product.name
                            )} `}</Typography>
                            &nbsp;
                            <Typography color="primary">{` (${t(
                              "products_translation:add_products_form.see_details"
                            )}...) `}</Typography>
                          </AccordionSummary>

                          <AccordionDetails
                            style={{
                              borderLeft: "2px solid",
                              borderLeftColor: theme.palette.primary.main,
                              marginBottom: 8,
                            }}
                          >
                            <div
                              style={{ color: theme.palette.text.primary }}
                              dangerouslySetInnerHTML={{
                                __html: CRMUtils.getFieldTranslation(
                                  product.description
                                ),
                              }}
                            ></div>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        CRMUtils.getFieldTranslation(product.name)
                      )
                    }
                    secondary={
                      <div>
                        <div>
                          {renderPriceOptions(product, 0, {
                            label: t(
                              "products_translation:add_products_form.standard_price"
                            )+": ",
                            value: product.standard_price,
                            details: CRMUtils.getFieldTranslation(
                              product.standard_price_details
                            ),
                            selected: !product.express,
                          })}
                          {renderPriceOptions(product, 1, {
                            label: t(
                              "products_translation:add_products_form.express_price"
                            )+ ": ",
                            value: product.express_price,
                            details: CRMUtils.getFieldTranslation(
                              product.express_price_details
                            ),
                            selected: product.express,
                          })}
                        </div>
                        <div></div>
                      </div>
                    }
                  />
                </ListItem>
              </div>
            );
          })}
        </>
      )}
    </Card>
  );
};

Products.propTypes = {
  order: PropTypes.object.isRequired,
};
