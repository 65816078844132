import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Tip } from "./tip";
import { Controller, useForm } from "react-hook-form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #81808045;
    border-radius: 8px;
    margin: 0px 4px;
  
  
  
    input {
      padding: 14px 8px;
      border: none;
      outline: none;
      flex: 1;
      font-size: 16px;
  
      backgroundColor: "none" 
      border-radius: 8px;
      max-height: 40px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  
    .icon {
      margin-right: 8px;
    }
  `;

const NMIBehavior = (props) => {
  // props
  const {
    currency = null,
    merchantConfiguration = null,
    onPaymentSubmitted = null,
    merchant_variant = "inline",
    totalToPay = 0,
    orderId = null,
    productsToPay = [],
    patientName = "",
    firstPayment = false,
    proccessTransaction,
    setFailureText,
    setIsFormLoading,
    isFormLoading,
    failureText,
  } = props;
  const theme = useTheme();
  const classes = {
    containerTitle: {
      borderLeft: "2px solid #25ace0",
      margin: "5px",
      padding: "6px",
    },
    error: {
      fontSize: "13px",
      fontWeight: 500,
      color: "#cc3131",
      marginLeft: "8px",
      marginBottom: "8px",
    },
  };
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [placePredictionsLocal, setPlacePredictionsLocal] = useState([]);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const NewUserSchema = Yup.object().shape({});
  const [ccnumberError, setCcnumberError] = useState(null);
  const [cvvError, setCvvError] = useState(null);
  const [ccexpError, setCcexpError] = useState(null);
  const [isCcnumberValid, setIsCcnumberValid] = useState(false);
  const [isCvvValid, setIsCvvValid] = useState(false);
  const [isCcexpValid, setIsCcexpValid] = useState(false);
  const dataToSubmitRef = useRef(null);

  const defaultValues = useMemo(
    () => ({}),

    []
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const setSearch = (search) => {
    getPlacePredictions({ input: search });
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1000),
    []
  );
  // Initialize variables
  const merchantName = useRef(null);
  let threeDSecureInterface = null;

  // Function to a script
  const addScript = ({ src, id, onLoad, attributes = [] }) => {
    return new Promise((resolve, reject) => {
      const existing = document.getElementById(id);
      if (existing) {
        resolve(existing); // Script already exists
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.id = id;

      attributes.forEach(({ key, value }) => {
        script.setAttribute(key, value);
      });

      script.async = true;
      script.onload = () => {
        if (onLoad) {
          onLoad();
        }
        resolve(script);
      };
      script.onerror = (error) => {
        reject(error);
      };

      document.body.appendChild(script);
    });
  };

  const sendPaymentRequest = async (token, secure_response = null) => {
    await proccessTransaction({
      orderId: orderId,
      payment_details: productsToPay,
      amount: totalToPay,
      patientName: patientName,
      first_payment: firstPayment,
      currency: currency,
      merchantData: {
        secureResponse: secure_response,
        paymentToken: token,
        billingData: dataToSubmitRef.current,
      },
    });
  };

  // Start Collectjs Configuration
  const startCollectjs = async () => {
    window.CollectJS.configure({
      variant: merchant_variant,
      styleSniffer: true,
      callback: async (e) => {
        setFailureText(null);
        if (merchantConfiguration && merchantConfiguration.is_3ds_active) {
          const options = {
            paymentToken: e.token,
            currency: currency,
            country: dataToSubmitRef.current.country,
            amount: totalToPay,
            email: dataToSubmitRef.current.email,
            phone: dataToSubmitRef.current.phone,
            city: dataToSubmitRef.current.city,
            state: dataToSubmitRef.current.state,
            address1: dataToSubmitRef.current.address,
            firstName: dataToSubmitRef.current.firstName,
            lastName: dataToSubmitRef.current.lastName,
            postalCode: dataToSubmitRef.current.zip_code,
          };

          const gateway = window.Gateway.create(
            merchantConfiguration.public_api_key
          );

          const threeDS = gateway.get3DSecure();

          threeDSecureInterface = threeDS.createUI(options); // Create a new 3DS instance
          threeDSecureInterface.start("#threeDSecureContainer");

          threeDSecureInterface.on("challenge", function (e) {
            setFailureText(null);
            setIsFormLoading(true);
          });

          threeDSecureInterface.on("error", function (e) {
            setIsFormLoading(false);
            setFailureText(null);
            threeDSecureInterface.unmount();
          });

          threeDSecureInterface.on("complete", function (secureResponse) {
            setIsFormLoading(true);
            sendPaymentRequest(e.token, secureResponse);
            threeDSecureInterface.unmount();
          });

          threeDSecureInterface.on("failure", function (e) {
            setIsFormLoading(false);
            console.error("3DS Failure:", e);
            if (setFailureText) {
              setFailureText(e.message);
            }
            threeDSecureInterface.unmount();
          });
        } else {
          sendPaymentRequest(e.token);
        }
      },
      placeholderCss: {
        color: "#989898E3",
        "font-size": "13px",
        "font-weight": "bold",
      },
      invalidCss: {
        color: "#b90505",
      },
      validCss: {
        color: "#00AA45",
      },
      customCss: {
        "border-radious": 10,
      },
      fields: {
        ccnumber: {
          placeholder: t("payments:payment_form.payment_fields.ccnumber") + "*",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: t("payments:payment_form.payment_fields.ccexp") + "*",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: t("payments:payment_form.payment_fields.cvv") + "*",
          selector: "#cvv",
        },
      },
      validationCallback: function (field, status) {
        var error = !status
          ? t("payments:payment_form.payment_field_validation_message")
          : null;

        if (field == "ccnumber") {
          setCcnumberError(error);
          setIsCcnumberValid(error ? false : true);
        } else {
          if (field == "ccexp") {
            setCcexpError(error);
            setIsCcexpValid(error ? false : true);
          } else {
            if (field == "cvv") {
              setCvvError(error);
              setIsCvvValid(error ? false : true);
            }
          }
        }
      },
    });
  };

  //   // Restart the Gateway
  const restartGateway = async () => {
    try {
      // Delete existing scripts
      delete window.Gateway;
      const collectjs_script = document.getElementById("collectjs-script");
      const gatewayjs_script = document.getElementById("gateway-script");

      collectjs_script?.parentNode?.removeChild(collectjs_script);
      gatewayjs_script?.parentNode?.removeChild(gatewayjs_script);

      if (merchantConfiguration) {
        const collectjsAttributes = [
          {
            key: "data-tokenization-key",
            value: merchantConfiguration.data_collection_token,
          },
        ];

        // Load CollectJS script
        await addScript({
          src: merchantConfiguration.merchant.data.data_collection_library_url,
          id: "collectjs-script",
          attributes: collectjsAttributes,
        });

        // Load Gateway script if 3DS active
        if (merchantConfiguration.is_3ds_active) {
          const gatewayEndpoint =
            merchantConfiguration.merchant.data.gatewayjs_endpoint;
          await addScript({
            src: gatewayEndpoint,
            id: "gateway-script",
          });
        }

        // Start CollectJS after scripts have loaded
        await startCollectjs();
      }
    } catch (error) {
      console.log("Error" + error);
      if (setFailureText) {
        setFailureText(
          t("payments:payment_form.payment_messages.general_error")
        );
      }
    }
  };

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length) {
      setPlacePredictionsLocal(placePredictions);
    }
  }, [placePredictions]);

  const setAddressValues = (item) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => {
        setSelectedAddress(placeDetails);
      }
    );
  };

  useEffect(() => {
    try {
      if (selectedAddress && typeof setValue == "function") {
        for (const component of selectedAddress.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "postal_code": {
              setValue("zip_code", component.long_name, {
                shouldValidate: true,
              });
              break;
            }
            case "locality":
              setValue("city", component.long_name, {
                shouldValidate: true,
              });
              break;
            case "administrative_area_level_1": {
              setValue("state", component.short_name, {
                shouldValidate: true,
              });

              break;
            }
            case "country":
              setValue("country", component.short_name, {
                shouldValidate: true,
              });

              break;
          }
        }
        setValue("address", selectedAddress.name, {
          shouldValidate: true,
        });

        setPlacePredictionsLocal([]);
      }
    } catch {
      console.error("selectedAddress error");
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (merchantConfiguration) {
      restartGateway();
    }
  }, [merchantConfiguration]);

  useEffect(() => {
    merchantName.current =
      merchantConfiguration?.merchant?.data?.name || "Unknown merchant";
  }, []);

  const onSubmit = (data) => {
    if (
      ccnumberError ||
      ccexpError ||
      cvvError ||
      !isCcexpValid ||
      !isCcnumberValid ||
      !isCvvValid
    ) {
      return null;
    } else {
      setIsFormLoading(true);
      dataToSubmitRef.current = data;
      window.CollectJS?.startPaymentRequest();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Card
            style={{
              width: "-webkit-fill-available",
              borderRadius: 10,
              padding: "20px 15px",
            }}
          >
            <Tip
              title={`${t("common:general.notice")}: `}
              message={`* ${t("common:general.required_tip")}`}
            />
            <br />
            <Typography sx={classes.containerTitle} variant="h6">
              {t("payments:payment_form.billing_information")}
            </Typography>
            <Grid container spacing={1}>
              <Grid xs={12} md={12}>
                <Grid container spacing={1}>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"firstName"}
                      control={control}
                      key={"fname"}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth
                          label={t(
                            "payments:payment_form.billing_information_fields.first_name"
                          )}
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"lastName"}
                      control={control}
                      key={"lname"}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth
                          label={t(
                            "payments:payment_form.billing_information_fields.last_name"
                          )}
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"phone"}
                      control={control}
                      key={"phone"}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label={t(
                            "payments:payment_form.billing_information_fields.phone"
                          )}
                          type="number"
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"email"}
                      control={control}
                      key={"email"}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label={t(
                            "payments:payment_form.billing_information_fields.email"
                          )}
                          type="email"
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={11}>
                    <Controller
                      name={"address"}
                      control={control}
                      key={"address"}
                      render={({ field }) => (
                        <TextField
                          InputProps={{
                            endAdornment: isPlacePredictionsLoading ? (
                              <CircularProgress
                                style={{ width: 25, height: 25 }}
                              />
                            ) : (
                              ""
                            ),
                          }}
                          fullWidth
                          onFocus={(e) => {
                            if (selectedAddress.name != e.target.value) {
                              debouncedSearch(e.target.value);
                            }
                          }}
                          required
                          label={t(
                            "payments:payment_form.billing_information_fields.address"
                          )}
                          name="address"
                          onChange={(data) => {
                            debouncedSearch(data.target.value);
                            field.onChange(data.target.value);
                          }}
                          value={field.value}
                        />
                      )}
                      // rules={element.required != null ? { required: element.required } : null}
                    />
                  </Grid>

                  <Grid xs={12} md={1}>
                    <IconButton
                      aria-describedby={idPopover}
                      onClick={handleInfoClick}
                    >
                      <Icon
                        style={{ width: 28, height: 28, color: "#5e95ff" }}
                        icon={"fluent:info-24-filled"}
                      />
                    </IconButton>

                    <Popover
                      PaperProps={{
                        style: {
                          padding: 10,
                          background: "rgb(99 124 179)",
                          color: "white",
                        },
                      }}
                      id={idPopover}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleInfoClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div style={{ maxWidth: 250 }}>
                        {t("common:general.address_message")}
                      </div>
                    </Popover>
                  </Grid>
                </Grid>
                {
                  <Grid style={{ paddingTop: 0 }} item xs={12}>
                    {!isPlacePredictionsLoading &&
                    !isEmpty(placePredictionsLocal) ? (
                      <Card
                        elevation={10}
                        style={{ backgroundColor: "#ffffff0d" }}
                      >
                        <Box>
                          <List>
                            <Divider component="li" />
                            {placePredictionsLocal.map((item, index) => {
                              return (
                                <>
                                  {" "}
                                  <ListItem
                                    role={undefined}
                                    dense
                                    key={item.id}
                                    button
                                    onClick={() => {
                                      setAddressValues(item);
                                    }}
                                  >
                                    <ListItemText
                                      id={item.index}
                                      primary={item.description}
                                    />
                                  </ListItem>
                                  <Divider component="li" />
                                </>
                              );
                            })}
                          </List>
                        </Box>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                }
                <Grid container spacing={1}>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"country"}
                      control={control}
                      defaultValue={""}
                      key={"country"}
                      render={({ field }) => (
                        <TextField
                          disabled
                          fullWidth
                          required
                          label={t(
                            "payments:payment_form.billing_information_fields.country"
                          )}
                          name="country"
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"state"}
                      defaultValue={""}
                      control={control}
                      key={"state"}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          disabled
                          required
                          label={t(
                            "payments:payment_form.billing_information_fields.state"
                          )}
                          name="state"
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"city"}
                      control={control}
                      defaultValue={""}
                      key={"city"}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          disabled
                          label={t(
                            "payments:payment_form.billing_information_fields.city"
                          )}
                          name="city"
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Controller
                      name={"zip_code"}
                      control={control}
                      key={"zip_code"}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          disabled
                          required
                          label={t(
                            "payments:payment_form.billing_information_fields.zip_code"
                          )}
                          name="zip_code"
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Typography sx={classes.containerTitle} variant="h6">
              {t("payments:payment_form.payment_information")}
            </Typography>

            <Grid container spacing={1}>
              <Grid xs={12} md={0.7}>
                {" "}
                <Icon
                  style={{
                    fontSize: 25,
                    marginTop: 12,
                    marginLeft: 6,
                    color: "#888888",
                  }}
                  icon={"solar:card-bold"}
                />
              </Grid>
              <Grid xs={12} md={11.3}>
                {" "}
                <StyledDiv style={{ backgroundColor: "none" }} id="ccnumber" />
              </Grid>
              {ccnumberError ? (
                <Grid xs={12} md={12}>
                  <Typography sx={classes.error}>{ccnumberError}</Typography>
                </Grid>
              ) : (
                ""
              )}

              <Grid xs={12} md={6}>
                <StyledDiv id="ccexp" />
                {ccexpError ? (
                  <Typography sx={classes.error}>{ccexpError}</Typography>
                ) : (
                  ""
                )}
              </Grid>

              <Grid xs={12} md={6}>
                {" "}
                <StyledDiv id="cvv" />
                {cvvError ? (
                  <Typography sx={classes.error}>{cvvError}</Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {failureText ? (
              <Alert
                style={{
                  marginTop: 10,
                  backgroundColor: "rgb(255 146 146 / 30%)",
                }}
                severity="error"
              >
                <div>{failureText}</div>
              </Alert>
            ) : (
              ""
            )}
          </Card>
        </Stack>
        <div style={{ textAlign: "center" }} id={"threeDSecureContainer"}></div>
        <Button
          disabled={isFormLoading}
          startIcon={<Icon icon="material-symbols:credit-card" />}
          style={{ width: "100%", marginTop: "5px" }}
          variant="contained"
          type="submit"
        >
          {isFormLoading
            ? t("payments:payment_form.processing") + "..."
            : t("payments:payment_form.pay") + " $" + totalToPay}
        </Button>
      </form>
    </div>
  );
};

export default NMIBehavior;
