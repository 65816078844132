import { Navigate, Outlet } from "react-router-dom";
import { Layout as MarketingLayout } from "src/layouts/marketing";
import HomePage from "src/pages";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";

import RetryFirstPayment from "src/pages/retryFirstPayment";
import { authRoutes } from "./auth";


import { dashboardRoutes } from "./dashboard";
import { paths } from "src/paths";

export const routes = [
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
    ],
  },
  ...authRoutes,

  ...dashboardRoutes,

  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },  
  {
    path: paths.retry_first_payment,
    element: <RetryFirstPayment />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
