import { useCallback, useMemo, useState } from 'react';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import { Badge, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import { getMessaging, onMessage } from 'firebase/messaging';
import { useAuth } from 'src/hooks/use-auth';
import CRMUtils from 'src/utils';
import Api from 'src/libs/api';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';

const dataSliceNotifications = {
  dataUrl: { code: "GET_NOTIFICATIONS" },
  getFilters: {
    include: [],
    limit: 15,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  errorHandle: null,
};

export const NotificationsButton = () => {
  const popover = usePopover();
  const { user } = useAuth();

  const [notifications, setNotifications] = useState([]);

  const [totalUnread, setTotalUnread] = useState(0);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const getNotifications = async (dataSlice) => {
    if (dataSlice && dataSlice.dataUrl) {
      dataSlice.dataUrl.many = true;
      dataSlice.dataUrl.id = user.id;

      const getFilters = CRMUtils.getApiFilters(dataSlice.getFilters);

      const apiParams = { dataUrl: dataSlice.dataUrl, filters: getFilters };
      try {
        const payload = await Api.getEntities(apiParams);

        setNotifications(payload.data.data);
     
        setTotalUnread(payload.data.meta != null && payload.data.meta.pagination.total_unread ? payload.data.meta.pagination.total_unread : 0)
   

        if (dataSlice.dataList && dataSlice.dataList.successHandle) {
          dispatch(dataSlice.dataList.successHandle);
        }
      } catch (error) {
        console.log("get notifications error: ", error);
  
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    }
  };


  const handleMarkAllAsRead = () => {
    Api.markAllNotificationAsRead(user.id);

    setNotifications(notifications.map((notf) => { return { ...notf, ...{ view: 1 } } }))

    setTotalUnread(0);
  }

  const markAsRead = (id) => {
    Api.markNotificationAsRead(id);

    const index = findIndex(notifications, ["id", id]);
    const updatedEvent = {
      ...notifications[index],
      ...{ view: 1 },
    };

    let newNotifications = [...notifications];
    newNotifications[index] = updatedEvent;
    setNotifications([...newNotifications]);

    setTotalUnread(totalUnread - 1)
  }

  const messagingDashboard = getMessaging();

  onMessage(messagingDashboard, (payload) => {
    var body = JSON.parse(payload.notification.body);
    if (body.user_id == user.id && body.data) {
      setNotifications([body.data, ...notifications]);
      setTotalUnread(totalUnread + 1);
    }
  });

  useEffect(() => {
    getNotifications(dataSliceNotifications);
  }, [])

  return (
    <>
      <Tooltip title={t('notifications:title')}>
        <IconButton
          ref={popover.anchorRef}
          onClick={()=>{
            getNotifications(dataSliceNotifications);
            popover.handleOpen()}}
        >
          <Badge
            color="error"
            badgeContent={totalUnread}
          >
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        onClose={popover.handleClose}
        onMarkAllAsRead={handleMarkAllAsRead}
        markAsRead={markAsRead}
        open={popover.open}
        totalUnread={totalUnread}
        setTotalUnread={setTotalUnread}
      />
    </>
  );
};
