import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Select(props) {
  const {
    label,
    value,
    allowEmpty,
    fullWidth = false,
    emptyLabel,
    onChange,
    options,
    disabled,
    variant,
    onClickMenuItem,
    minWidth = 180,
    ...other
  } = props;
  const { t } = useTranslation("common");

  return (
    <FormControl
      sx={{ minWidth: minWidth }}
      variant={variant ? variant : "outlined"}
      fullWidth={fullWidth}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        fullWidth={fullWidth}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...other}
      >
        {allowEmpty && (
          <MenuItem value={null}>
            {emptyLabel ? emptyLabel : t("general.None")}
          </MenuItem>
        )}

        {options !== undefined &&
          options.map((item) =>
            item.title != null ? (
              <MenuItem
                onClick={
                  onClickMenuItem && typeof onClickMenuItem === "function"
                    ? () => {
                        onClickMenuItem(item);
                      }
                    : null
                }
                key={item.id}
                value={item.id}
              >
                {item.title}
              </MenuItem>
            ) : (
              <MenuItem
                onClick={
                  onClickMenuItem && typeof onClickMenuItem === "function"
                    ? () => {
                        onClickMenuItem(item);
                      }
                    : null
                }
                key={item.id}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            )
          )}
      </MuiSelect>
    </FormControl>
  );
}
