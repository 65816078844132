import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardHeader,
  Fab,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Tooltip,
  SvgIcon,
  Chip,
} from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { Icon } from "@iconify/react";
import { Popup } from "src/sections/components/controls/Popup";
import { useState } from "react";
import Api from "src/libs/api";
import CRMUtils from "src/utils";
import { useAuth } from "src/hooks/use-auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { Products } from "./Products";
import { findIndex } from "lodash";
import ConfirmDialog from "src/sections/components/controls/ConfirmDialog";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";
import { AvailableOffers } from "./AvailableOffers";
import { ResultsPopup } from "./ResultsPopup";
import NMIBehavior from "./NMIBehavior";
import { ChangeOrderItemDoctor } from "./forms/ChangeOrderItemDoctor";
import { ChangeSampleStatusForm } from "./forms/ChangeSampleStatusForm";
import { ReferenceCreditAplicator } from "./ReferenceCreditAplicator";
import { PurchaseExpirationCountdown } from "./PurchaseExpirationCountdown";
import { KitSampleSelector } from "./forms/KitSampleSelector";
import TermsAndConditions from "./TermsAndConditions";
import { YtomorrowTermsAndConditions } from "./YtomorrowTermsAndConditions";

function Iconify(props) {
  const { icon } = props;

  return <Icon icon={icon} fontSize={22} />;
}

export const OrderItems = (props) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [openPaymentPopupForRetry, setOpenPaymentPopupForRetry] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const { items, updateOrder = null, order, setOrder, ...other } = props;
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [scannerOpen, setScannerOpen] = useState(false);
  const { user } = useAuth();
  const userCanAssignLabel = CRMUtils.userHasPermission(user, [
    "sample_assingcollectiontube",
  ]);
  const userCanMarkAsSent = CRMUtils.userHasPermission(user, [
    "sample_markassent",
  ]);
  const userCanChangeDoctor = CRMUtils.userHasPermission(user, [
    "user_changepatientdoctor",
  ]);
  const userCanChangeSampleStatus = CRMUtils.userHasPermission(user, [
    "sample_changesamplestatus",
  ]);
  const [openStatusPopup, setOpenStatusPopup] = useState(null);
  const [failureText, setFailureText] = useState(null);
  const [openChangeDoctorPopup, setOpenChangeDoctorPopup] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { t } = useTranslation();
  const userCanSeeSampleResults = CRMUtils.userHasPermission(user, [
    "sample_seeresults",
  ]);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false);

  const onSaveLabel = async (label) => {
    try {
      var payload = {
        collection_tube_code: label,
      };

      const { data } = await Api.updateOrderItemSample(
        payload,
        selectedOrderItem.id
      );

      if (updateOrder) {
        updateOrder(data);
      }

      onClosePopup();
      toast.success(t("common:general.Updated_successfully"), {
        style: { backgroundColor: "rgb(53 166 80 / 77%)" },
      });
    } catch (err) {
      if (err.data && err.data.message) {
        toast.error(err.data.message, {
          duration: 5000,
          style: { backgroundColor: "rgb(211 39 39 / 77%)" },
        });
      } else {
        toast.error(t("common:general.error_message"), {
          style: { backgroundColor: "rgb(211 39 39 / 77%)" },
        });
      }
    }
  };

  const addProduct = (product) => {
    var tempProduct = { ...product };
    tempProduct["unique_id"] =
      selectedProducts.filter((a) => a.unique_id < 0).length > 0
        ? selectedProducts
            .filter((a) => a.unique_id < 0)
            .sort((a, b) => a.unique_id - b.unique_id)[0].unique_id - 1
        : -1;
    setSelectedProducts([tempProduct, ...selectedProducts]);

    toast.success(t("common:general.Product_added"), {
      style: { backgroundColor: "rgb(53 166 80 / 77%)" },
    });
  };

  const removeProduct = (product) => {
    setSelectedProducts(
      selectedProducts.filter((a) => a.unique_id != product.unique_id)
    );
  };

  const productActions = () => {
    return [
      {
        action: (product) => {
          return (
            <Button
              disabled={
                product.available_qty == 0 ||
                selectedProducts.filter((a) => a.id == product.id).length ==
                  product.available_qty
              }
              onClick={() => addProduct(product)}
              startIcon={<Icon icon="ic:round-plus" />}
            >
              {t("products_translation:add_products_form.add")}
            </Button>
          );
        },
      },
    ];
  };

  const onClosePopup = () => {
    setScannerOpen(false);
    setSelectedOrderItem(null);
  };

  const onCloseAddPopup = () => {
    setOpenAddPopup(false);
  };

  const updateProducts = (product) => {
    const index = findIndex(selectedProducts, ["id", product.id]);

    if (index != -1) {
      const updatedEvent = {
        ...selectedProducts[index],
        ...product,
      };

      let newProducts = [...selectedProducts];
      newProducts[index] = updatedEvent;
      setSelectedProducts([...newProducts]);
    }
  };

  const purchaseProducts = async (payload) => {
    try {
      const { data } = await Api.addOrderItems(
        {
          ...payload,
          ...{
            items: selectedProducts,
            organization_id: order?.organization_id || null,
            currency_id:
              selectedProducts.length > 0
                ? selectedProducts[0]?.currency_id || null
                : null,
          },
        },
        order.id
      );

      if (data.payment_error) {
        if (data.payment_error.status == "declined") {
          setFailureText(
            t("payments:payment_form.payment_messages.transaction_declined")
          );
        } else if (data.payment_error.status == "error") {
          setFailureText(data.payment_error.message);
        } else {
          setFailureText(
            t("payments:payment_form.payment_messages.general_error")
          );
        }
      } else {
        if (data.payment_response.status == "approved") {
          onClosePaymentPopup();
          toast.success(
            t("payments:payment_form.payment_messages.processing_payment"),
            {
              style: { backgroundColor: "rgb(53 166 80 / 77%)" },
            }
          );
          setOpenAddPopup(false);
        } else if (data.payment_response.status == "declined") {
          setFailureText(
            t("payments:payment_form.payment_messages.transaction_declined")
          );
        } else if (data.payment_response.status == "error") {
          setFailureText(data.payment_response.message);
        } else {
          setFailureText(
            t("payments:payment_form.payment_messages.general_error")
          );
        }

        if (data?.order?.data || false) {
          setOrder({ ...order, ...data.order.data });
        }
      }
    } catch (err) {
      setFailureText(t("payments:payment_form.payment_messages.general_error"));
    } finally {
      setIsFormLoading(false);
    }
  };

  const retryProductPayment = async (payload) => {
    try {
      const { data } = await Api.sendOrderPaymentRequest(payload);
      if (data.status == "approved") {
        const response = await Api.getOrder(order.id);
        setOrder({ ...response.data.data });
        onClosePaymentPopupForRetry();
        toast.success(
          t("payments:payment_form.payment_messages.processing_payment"),
          {
            style: { backgroundColor: "rgb(53 166 80 / 77%)" },
          }
        );
      } else if (data.status == "declined") {
        setFailureText(
          t("payments:payment_form.payment_messages.transaction_declined")
        );
      } else if (data.status == "error") {
        setFailureText(data.message);
      } else {
        setFailureText(
          t("payments:payment_form.payment_messages.general_error")
        );
      }
      setIsFormLoading(false);
    } catch (err) {
      setFailureText(t("common:general.error_message"));
    }
  };

  const getStatusBackgroundColor = (color, alpha) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const renderSelectedProducts = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t("orders_translation:new_order.product_columns.name")}
            </TableCell>
            <TableCell>
              {t("orders_translation:new_order.product_columns.express")}
            </TableCell>
            <TableCell>
              {t("orders_translation:new_order.product_columns.price")}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedProducts.map((item) => {
            return (
              <TableRow key={item.unique_id}>
                <TableCell>
                  <Typography variant="subtitle2">
                    {CRMUtils.getFieldTranslation(item.name)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {item.express ? (
                    <Icon
                      fontSize={25}
                      icon="material-symbols:check-small-rounded"
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.base_price &&
                    item.base_price !=
                      (item.express
                        ? item.express_price
                        : item.standard_price) ? (
                      <Typography
                        style={{
                          textDecoration: "2px line-through red",
                          marginRight: 6,
                        }}
                      >
                        {" "}
                        ${item.base_price}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography
                      style={{
                        fontWeight:
                          item.base_price &&
                          item.base_price !=
                            (item.express
                              ? item.express_price
                              : item.standard_price)
                            ? 600
                            : 400,
                        fontSize:
                          item.base_price &&
                          item.base_price !=
                            (item.express
                              ? item.express_price
                              : item.standard_price)
                            ? 17
                            : 15,
                      }}
                    >
                      {" "}
                      {`${item?.currency?.symbol || ""}${
                        item.express ? item.express_price : item.standard_price
                      } ${item?.currency?.iso_code || ""}`}
                    </Typography>
                  </div>
                  {item.base_price &&
                  item.base_price !=
                    (item.express
                      ? item.express_price
                      : item.standard_price) ? (
                    <Typography
                      style={{
                        color: "rgb(151 151 151)",
                        fontWeight: 500,
                        fontSize: 13,
                      }}
                    >
                      {`${t("offers:offers.orders_form.saved")} $${(
                        parseFloat(item.base_price) -
                        parseFloat(
                          item.express
                            ? item.express_price
                            : item.standard_price
                        )
                      ).toFixed(2)}`}
                    </Typography>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      removeProduct(item);
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <SvgIcon>
                      <Trash02Icon />
                    </SvgIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const onClosePaymentPopup = () => {
    setOpenPaymentPopup(false);
    setFailureText(null);
    setIsFormLoading(false);
  };

  const onClosePaymentPopupForRetry = () => {
    setOpenPaymentPopupForRetry(false);
    setOpenAddPopup(false);
    setFailureText(null);
    setIsFormLoading(false);
  };

  const onCloseChangeDoctor = () => {
    setOpenChangeDoctorPopup(false);
    setSelectedOrderItem(null);
    setIsSubmitting(false);
  };

  const onCloseStatusPopup = () => {
    setOpenStatusPopup(false);
    setSelectedOrderItem(null);
    setIsSubmitting(false);
  };

  const onSubmitDoctor = async () => {
    const { data } = await Api.getOrder(order.id);
    setOrder({ ...data.data });
    onCloseChangeDoctor();
    toast.success(t("common:general.Updated_successfully"), {
      style: { backgroundColor: "rgb(53 166 80 / 77%)" },
    });
  };

  const onChangeStatus = async () => {
    const { data } = await Api.getOrder(order.id);
    setOrder({ ...data.data });
    onCloseStatusPopup();
    toast.success(t("common:general.Updated_successfully"), {
      style: { backgroundColor: "rgb(53 166 80 / 77%)" },
    });
  };

  const openConfirmDialog = (
    setConfirmDialogObj,
    onConfirmHandle,
    onConfirmParam,
    title,
    subtitle = null
  ) => {
    setConfirmDialogObj({
      isOpen: true,
      title: title,
      subTitle:
        subtitle != null
          ? subtitle
          : t("common:messages.can_not_undo_operation"),
      onConfirm: () => onConfirmHandle(onConfirmParam),
    });
  };

  const onChangeResultsVisibility = async (item) => {
    try {
      setConfirmLoading(true);
      await Api.changeOrderItemVisibility(
        { hide_results_from_others: !item.hide_results_from_others },
        item.id
      );

      const { data } = await Api.getOrder(order.id);
      setOrder({ ...data.data });

      toast.success(t("common:general.Updated_successfully"), {
        style: { backgroundColor: "rgb(53 166 80 / 77%)" },
      });
      setConfirmLoading(false);
      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    } catch (err) {
      toast.error(t("common:general.error_message"), {
        style: { backgroundColor: "rgb(211 39 39 / 77%)" },
      });
      setConfirmLoading(false);
    }
  };

  return (
    <Card {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          paddingRight: 2,
        }}
      >
        <CardHeader title={t("orders_translation:order_items.title")} />
        {order && order.created_by == user.id ? (
          <Fab
            onClick={() => {
              setSelectedProducts([]);
              setOpenAddPopup(true);
            }}
            size="small"
            color="primary"
          >
            <Icon
              style={{ width: 25, height: 25 }}
              icon={"icon-park-outline:plus"}
            />
          </Fab>
        ) : (
          ""
        )}
      </Stack>

      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("orders_translation:order_items.columns.name")}
                </TableCell>
                <TableCell>
                  {t("orders_translation:order_items.columns.doctor")}
                </TableCell>
                <TableCell>
                  {t("orders_translation:order_items.columns.express")}
                </TableCell>
                <TableCell>
                  {t("orders_translation:order_items.columns.need_sample")}
                </TableCell>
                <TableCell>
                  {t("orders_translation:order_items.columns.has_sample")}
                </TableCell>
                <TableCell>
                  {t("orders_translation:order_items.columns.sample_status")}
                </TableCell>
                <TableCell>
                  {t("orders_translation:order_items.columns.price")}
                </TableCell>
                {userCanSeeSampleResults ||
                (order && order.created_by == user.id) ? (
                  <TableCell>
                    {t("orders_translation:order_items.columns.results")}
                  </TableCell>
                ) : (
                  ""
                )}

                <TableCell>
                  {t("orders_translation:order_items.columns.balance")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {CRMUtils.getFieldTranslation(item.item_name)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {(item?.doctor?.fname || "") +
                          " " +
                          (item?.doctor?.lname || "")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {item.is_express ? (
                        <Iconify icon="material-symbols:check-small-rounded" />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {item.sample_required ? (
                        <Iconify icon="simple-line-icons:check" />
                      ) : (
                        <Iconify icon="icons8:cancel" />
                      )}
                    </TableCell>
                    <TableCell>
                      {item.has_sample && item.collection_tube ? (
                        <div style={{ display: "flex" }}>
                          <Iconify icon="material-symbols:check-small-rounded" />{" "}
                          <Typography> {item.collection_tube}</Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {item.status_name ? (
                        <Chip
                          style={{
                            fontSize: "13px",
                            fontWeight: 600,
                            backgroundColor: getStatusBackgroundColor(
                              item.status_color,
                              "0.18"
                            ),
                            color: item.status_color,
                          }}
                          label={
                            CRMUtils.getFieldTranslation(item.status_name)
                              ? CRMUtils.getFieldTranslation(item.status_name)
                                  .replaceAll("_", " ")
                                  .toUpperCase()
                              : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {`${item?.currency?.symbol || "$"}${item.price} (${
                        item?.currency?.iso_code || "-"
                      })`}
                    </TableCell>

                    {userCanSeeSampleResults ||
                    (order && order.created_by == user.id) ? (
                      <TableCell>
                        {" "}
                        {item &&
                        item.results &&
                        (!item.hide_results_from_others ||
                          (order && order.created_by == user.id)) ? (
                          <ResultsPopup itemValue={item} />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    <TableCell
                      style={{
                        color: item.balance > 0 ? "red" : "",
                        fontWeight: item.balance > 0 ? 700 : "",
                      }}
                    >
                      {`$${item.balance}`}
                      {item.pending_payments_amount > 0 ? (
                        <Typography
                          style={{
                            fontSize: 11,
                            color: "rgb(132 132 132)",
                            fontWeight: 600,
                          }}
                        >
                          {`($${item.pending_payments_amount} ${t(
                            "orders_translation:order_items.balance_message"
                          )})`}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {item.balance > 0 &&
                      order &&
                      order.created_by == user.id ? (
                        <Tooltip
                          title={
                            item.balance <= item.pending_payments_amount
                              ? t(
                                  "orders_translation:order_items.pending_payment_message"
                                )
                              : t(
                                  "orders_translation:order_items.actions.proceed_to_pay"
                                )
                          }
                        >
                          <span>
                            {" "}
                            <IconButton
                              disabled={
                                item.balance <= item.pending_payments_amount
                              }
                              color="primary"
                              onClick={() => {
                                setSelectedOrderItem(item);
                                setOpenPaymentPopupForRetry(true);
                              }}
                            >
                              <Icon icon="fluent:payment-32-filled" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {item.sample_required &&
                      userCanAssignLabel &&
                      !item.results ? (
                        <Tooltip
                          title={t(
                            "orders_translation:order_items.actions.assign_label"
                          )}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setSelectedOrderItem(item);
                              setScannerOpen(true);
                            }}
                          >
                            <Icon icon="iconoir:scan-barcode" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {userCanChangeDoctor ? (
                        <Tooltip
                          title={t(
                            "orders_translation:order_items.actions.change_doctor"
                          )}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setSelectedOrderItem(item);
                              setOpenChangeDoctorPopup(true);
                            }}
                          >
                            <Icon icon="fa6-solid:user-doctor" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {item.has_sample &&
                      item.collection_tube &&
                      userCanChangeSampleStatus ? (
                        <Tooltip
                          title={t(
                            "orders_translation:order_items.actions.change_status"
                          )}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setSelectedOrderItem(item);
                              setOpenStatusPopup(true);
                            }}
                          >
                            <Icon icon="tabler:status-change" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}

                      {item.results && order.created_by == user.id ? (
                        <Tooltip
                          title={
                            item.hide_results_from_others
                              ? t(
                                  "orders_translation:order_items.actions.show_results"
                                )
                              : t(
                                  "orders_translation:order_items.actions.hide_results"
                                )
                          }
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              openConfirmDialog(
                                setConfirmDialog,
                                onChangeResultsVisibility,
                                item,
                                item.hide_results_from_others
                                  ? t(
                                      "orders_translation:order_items.actions.show_results_message"
                                    )
                                  : t(
                                      "orders_translation:order_items.actions.hide_results_message"
                                    ),
                                ""
                              );
                            }}
                          >
                            <Icon
                              icon={
                                item.hide_results_from_others
                                  ? "mdi:file-eye"
                                  : "tabler:notes-off"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>

      <Popup
        onClose={onClosePopup}
        fullWidth={true}
        maxWidth="sm"
        open={scannerOpen}
        title={t("orders_translation:order_items.barcode.add_label")}
      >
        <KitSampleSelector
          defaultResult={selectedOrderItem?.collection_tube || null}
          onSave={onSaveLabel}
        />
      </Popup>

      <Popup
        onClose={onCloseAddPopup}
        fullWidth={true}
        maxWidth="md"
        open={openAddPopup}
        title={t("orders_translation:order_items.title")}
      >
        <PurchaseExpirationCountdown
          maxMinutes={user?.organization?.data?.price_access_duration || null}
          onOk={() => {
            onCloseAddPopup();
            onClosePaymentPopup();
          }}
        />
        <Scrollbar style={{ maxHeight: 500 }}>
          <Products
            patient_id={order?.patient_id || null}
            defaultProducts={[]}
            actions={productActions()}
            updateProducts={updateProducts}
          />
        </Scrollbar>
        {selectedProducts.length > 0 ? (
          <div style={{ padding: "10px 10px" }}>{renderSelectedProducts()}</div>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            margin: "8px 8px",
            justifyContent: "end",
          }}
        >
          <Typography
            style={{
              textAlign: "right",
              fontWeight: 600,
              margin: "0px 15px",

              fontSize: 15,
            }}
          >
            {`${t("orders_translation:new_order.total_to_pay")}: `}
          </Typography>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {selectedProducts.find((a) => a.offer_id) ? (
                <Typography
                  style={{
                    padding: "0px 10px",
                    textDecoration: "2px line-through red",
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: 15,
                  }}
                >
                  {`${
                    selectedProducts[0]?.currency?.symbol || ""
                  }${selectedProducts
                    .reduce((sum, product) => {
                      return (
                        sum +
                        (product.base_price
                          ? product.base_price
                          : product.express
                          ? parseFloat(product.express_price)
                          : parseFloat(product.standard_price))
                      );
                    }, 0)
                    .toFixed(2)} ${
                    selectedProducts[0]?.currency?.iso_code || ""
                  }`}
                </Typography>
              ) : (
                ""
              )}
              <Typography
                style={{
                  textAlign: "right",
                  fontWeight: 600,
                  marginRight: 6,

                  fontSize: selectedProducts.find((a) => a.base_price)
                    ? 17
                    : 15,
                }}
              >
                {`${
                  selectedProducts[0]?.currency?.symbol || ""
                }${selectedProducts
                  .reduce((sum, product) => {
                    return (
                      sum +
                      (product.express
                        ? parseFloat(product.express_price)
                        : parseFloat(product.standard_price))
                    );
                  }, 0)
                  .toFixed(2)} ${
                  selectedProducts[0]?.currency?.iso_code || ""
                }`}
              </Typography>
            </div>
            {selectedProducts.find((a) => a.offer_id) ? (
              <Typography
                style={{
                  color: "rgb(151 151 151)",
                  fontWeight: 500,
                  fontSize: 13,
                  marginLeft: 10,
                }}
              >
                {`${t("offers:offers.orders_form.saved")} $${(
                  selectedProducts.reduce((sum, product) => {
                    return (
                      sum +
                      (product.base_price
                        ? product.base_price
                        : product.express
                        ? parseFloat(product.express_price)
                        : parseFloat(product.standard_price))
                    );
                  }, 0) -
                  selectedProducts.reduce((sum, product) => {
                    return (
                      sum +
                      (product.express
                        ? parseFloat(product.express_price)
                        : parseFloat(product.standard_price))
                    );
                  }, 0)
                ).toFixed(2)}`}
              </Typography>
            ) : (
              ""
            )}
          </div>
        </div>
        <ReferenceCreditAplicator
          setProducts={setSelectedProducts}
          products={selectedProducts}
        />
        <div style={{ padding: "4px 8px" }}>
          <AvailableOffers
            setProducts={setSelectedProducts}
            patient_id={order?.patient_id || null}
            products={selectedProducts}
          />
        </div>

        <div style={{ marginBottom: 5 }}>
          <TermsAndConditions
            value={termsAndConditionsChecked}
            setValue={setTermsAndConditionsChecked}
            termsAndConditions={<YtomorrowTermsAndConditions />}
          />
        </div>

        <div style={{ textAlign: "right", margin: 10 }}>
          <Button
            disabled={
              isSubmitting ||
              selectedProducts.length == 0 ||
              !termsAndConditionsChecked
            }
            startIcon={
              isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
            }
            onClick={() => {
              setOpenPaymentPopup(true);
            }}
            variant="contained"
          >
            {t("common:general.Proceed_to_pay")}
          </Button>
        </div>
      </Popup>

      <Popup
        onClose={onClosePaymentPopup}
        fullWidth={true}
        maxWidth="sm"
        open={openPaymentPopup}
        title={t("payments:payment_form.title")}
      >
        <NMIBehavior
          currency={
            selectedProducts.length > 0
              ? selectedProducts[0]?.currency?.iso_code || null
              : null
          }
          setIsFormLoading={setIsFormLoading}
          isFormLoading={isFormLoading}
          setFailureText={setFailureText}
          failureText={failureText}
          merchantConfiguration={order?.merchantConfiguration?.data || null}
          totalToPay={selectedProducts.reduce((sum, product) => {
            return (
              sum +
              (product.express
                ? parseFloat(product.express_price)
                : parseFloat(product.standard_price))
            );
          }, 0)}
          orderId={order?.id || null}
          patientName={order?.patient?.data?.fullname || null}
          proccessTransaction={purchaseProducts}
        />
      </Popup>

      <Popup
        onClose={onClosePaymentPopupForRetry}
        fullWidth={true}
        maxWidth="sm"
        open={openPaymentPopupForRetry}
        title={t("payments:payment_form.title")}
      >
        <NMIBehavior
          currency={selectedOrderItem?.currency?.iso_code || null}
          setIsFormLoading={setIsFormLoading}
          isFormLoading={isFormLoading}
          setFailureText={setFailureText}
          failureText={failureText}
          merchantConfiguration={order?.merchantConfiguration?.data || null}
          totalToPay={
            (selectedOrderItem?.balance || 0) -
            (selectedOrderItem?.pending_payments_amount || 0)
          }
          orderId={order?.id || null}
          productsToPay={[
            {
              amount:
                (selectedOrderItem?.balance || 0) -
                (selectedOrderItem?.pending_payments_amount || 0),
              item_id: selectedOrderItem?.id || null,
            },
          ]}
          patientName={order?.patient?.data?.fullname || null}
          proccessTransaction={retryProductPayment}
        />
      </Popup>
      <ConfirmDialog
        confirmLoading={confirmLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        onClose={onCloseChangeDoctor}
        fullWidth={true}
        maxWidth="sm"
        open={openChangeDoctorPopup}
        title={t("orders_translation:order_items.change_doctor")}
      >
        <ChangeOrderItemDoctor
          onClose={onCloseChangeDoctor}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          onChangeDr={onSubmitDoctor}
          orderOrgId={order?.organization_id || null}
          orderItem={selectedOrderItem}
        />
      </Popup>

      <Popup
        onClose={onCloseStatusPopup}
        fullWidth={true}
        maxWidth="sm"
        open={openStatusPopup}
        title={t("orders_translation:order_items.actions.change_status")}
      >
        <ChangeSampleStatusForm
          onClose={onCloseStatusPopup}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          onChangeStatus={onChangeStatus}
          sample={selectedOrderItem?.sample || null}
        />
      </Popup>
    </Card>
  );
};

OrderItems.propTypes = {
  items: PropTypes.array.isRequired,
};
