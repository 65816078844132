import {
  CardContent,
  IconButton,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Popup } from "src/sections/components/controls/Popup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PdfModal from "./PdfModal";
import CRMUtils from "src/utils";

export const ResultsPopup = (props) => {
  const { itemValue } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [item, setItem] = useState();
  const { t } = useTranslation();

  const onClosePopup = () => {
    setOpenPopup(false);
    setItem(null);
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          setItem(itemValue);
          setOpenPopup(true);
        }}
      >
        <SvgIcon fontSize="medium">
          <Icon icon="ph:link-bold" />
        </SvgIcon>
      </IconButton>

      <Popup
        onClose={onClosePopup}
        fullWidth={true}
        maxWidth={"lg"}
        open={openPopup}
        title={t("orders_translation:details.result")}
      >
        <CardContent
          style={{
            boxShadow: " 0px 1px 5px rgba(0, 0, 0, 0.08)",
            margin: 22,
            marginTop: 2,
            padding: "15px",
          }}
        >
          <div
            style={{
              marginBottom: 6,
            }}
          >
            <ListItemText
              style={{ margin: 0 }}
              primary={item?.patient_name || ""}
              secondary={
                item ? CRMUtils.getFieldTranslation(item.item_name) : ""
              }
            />
          </div>
          <PdfModal
            fileUrl={item?.results || null}
            type={item && item.media_type == "pdf" ? "application/pdf" : undefined}
          />
        </CardContent>
      </Popup>
    </div>
  );
};
