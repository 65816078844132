import { Typography } from "@mui/material";
import React from "react";

const PdfModal = ({ fileUrl = "", rest }) => {
  return (
    <div style={{ height: "calc(100vh - 200px)" }}>
        {/* <embed src={"https://cesarm72.sg-host.com/informefinal.html?id=22966"} style={styles.embed}/> */}
           <embed src={fileUrl} style={styles.embed} type="application/pdf" {...rest}/>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 200px)",
    width: "100%",
    padding: "10px",
    boxSizing: "border-box",
  },
  embed: {
    width: "100%",
    height: "100%",
  }
};



export default PdfModal;
