import PropTypes from "prop-types";
import { Box, ButtonBase, SvgIcon, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useAuth } from "src/hooks/use-auth";
import { useTranslation } from "react-i18next";

export const NewOrderButton = (props) => {
  const { user } = useAuth();
  const {t} = useTranslation();

  return user && user.first_payment_completed ? (
    <Tooltip title={t('orders_translation:new_order.new_order')}>
      <Box
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "50%",
          bottom: 0,
          boxShadow: 16,
          margin: (theme) => theme.spacing(4),
          position: "fixed",
          right: 0,
          zIndex: (theme) => theme.zIndex.speedDial,
        }}
        onClick={()=>{
          props.onButtonClicked(user)
        }}
        {...props}
      >
        <ButtonBase
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "50%",
            color: "primary.contrastText",
            p: "10px",
          }}
        >
          <SvgIcon>
            <Icon icon="mdi:text-box-plus-outline" />
          </SvgIcon>
        </ButtonBase>
      </Box>
    </Tooltip>
  ) : (
    ""
  );
};

NewOrderButton.propTypes = {
  onClick: PropTypes.func,
};
