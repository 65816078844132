import PropTypes from "prop-types";
import { Chip, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const OptionsContrast = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const options = [
    {
      label: t("app_settings:contrast.normal"),
      value: "normal",
    },
    {
      label: t("app_settings:contrast.high"),
      value: "high",
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography color="text.secondary" variant="overline">
        {t("app_settings:contrast.title")}
      </Typography>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        {options.map((option) => (
          <Chip
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: "transparent",
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: "primary.main",
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsContrast.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(["normal", "high"]),
};
