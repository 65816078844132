import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));

// Academy


// Blog

// Customers

// Invoice

// Jobs


// Logistics
const LogisticsDashboardPage = lazy(() =>
  import("src/pages/dashboard/logistics/dashboard")
);
const LogisticsFleetPage = lazy(() =>
  import("src/pages/dashboard/logistics/fleet")
);

// Orders
const OrderListPage = lazy(() => import("src/pages/dashboard/orders/list"));

const MyOrdersList = lazy(() => import("src/pages/dashboard/orders/myorders"));

// Products
const ProductsPage = lazy(() =>
  import("src/pages/dashboard/products/products")
);
const ProductListPage = lazy(() => import("src/pages/dashboard/products/list"));
const ProductCreatePage = lazy(() =>
  import("src/pages/dashboard/products/create")
);
const ProductTypesPage = lazy(() =>
  import("src/pages/dashboard/producttypes/list")
);
const DepartmentsPage = lazy(() =>
  import("src/pages/dashboard/departments/list")
);

const PendingForSamplePage = lazy(() =>
  import("src/pages/dashboard/orderitems/pendingforsample")
);
const SamplesReport = lazy(() =>
  import("src/pages/dashboard/samplesreport/index")
);

// Social

// Other
const AccountPage = lazy(() => import("src/pages/dashboard/account"));

const BlankPage = lazy(() => import("src/pages/dashboard/blank"));




const UsersList = lazy(() => import("src/pages/dashboard/users/list"));
const RolesList = lazy(() => import("src/pages/dashboard/roles/list"));
const PatientsList = lazy(() => import("src/pages/dashboard/patients/list"));
const MyPatientsList = lazy(() =>
  import("src/pages/dashboard/patients/mypatients")
);
const MyGuests = lazy(() => import("src/pages/dashboard/myreferrals/index"));
const Settings = lazy(() => import("src/pages/dashboard/settings/index"));
const LogsList = lazy(() => import("src/pages/dashboard/logs/index"));
const ShippersList = lazy(() =>
  import("src/pages/dashboard/shipping/shippers")
);
const SendersList = lazy(() => import("src/pages/dashboard/shipping/senders"));
const LaboratoriesList = lazy(() =>
  import("src/pages/dashboard/shipping/laboratories")
);
const RecipientModelsList = lazy(() =>
  import("src/pages/dashboard/shipping/recipientmodels")
);

const ShippingList = lazy(() => import("src/pages/dashboard/shipping/index"));

const ShippingDashboard = lazy(() =>
  import("src/pages/dashboard/shipping/dashboard")
);

const PickupsList = lazy(() => import("src/pages/dashboard/shipping/pickups"));

const TemplatesList = lazy(() => import("src/pages/dashboard/templates/index"));

const TemplateCategoriesList = lazy(() =>
  import("src/pages/dashboard/templatecategories/index")
);

const CampaignsList = lazy(() => import("src/pages/dashboard/campaigns/index"));

const OffersList = lazy(() => import("src/pages/dashboard/offers/index"));

const OfferTypesList = lazy(() =>
  import("src/pages/dashboard/offertypes/index")
);

const AvailableOffersList = lazy(() =>
  import("src/pages/dashboard/offers/available_offers")
);

const Organizations = lazy(() =>
  import("src/pages/dashboard/organizations/index")
);

const Kits = lazy(() => import("src/pages/dashboard/kits/index"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),

    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "myorders",
        element: <MyOrdersList />,
        patient: true,
      },
      {
        path: "my_family",
        element: <MyPatientsList />,
        patient: true,
      },
      {
        path: "my_guests",
        element: <MyGuests />,
        permissions: ["user_refer", "user_referwithlabel"],
      },
      {
        path: "available_offers",
        element: <AvailableOffersList />,
      },
      // {
      //   path: "academy",
      //   children: [
      //     {
      //       index: true,
      //       element: <AcademyDashboardPage />,
      //     },
      //     {
      //       path: "courses",
      //       children: [
      //         {
      //           path: ":courseId",
      //           element: <AcademyCoursePage />,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: "blog",
      //   children: [
      //     {
      //       index: true,
      //       element: <BlogPostListPage />,
      //     },
      //     {
      //       path: "create",
      //       element: <BlogPostCreatePage />,
      //     },
      //     {
      //       path: ":postId",
      //       element: <BlogPostDetailPage />,
      //     },
      //   ],
      // },
      // {
      //   path: "customers",
      //   children: [
      //     {
      //       index: true,
      //       element: <CustomerListPage />,
      //     },
      //     {
      //       path: ":customerId",
      //       element: <CustomerDetailPage />,
      //     },
      //     {
      //       path: ":customerId/edit",
      //       element: <CustomerEditPage />,
      //     },
      //   ],
      // },
      // {
      //   path: "invoices",
      //   children: [
      //     {
      //       index: true,
      //       element: <InvoiceListPage />,
      //     },
      //     {
      //       path: ":invoiceId",
      //       element: <InvoiceDetailPage />,
      //     },
      //   ],
      // },
      // {
      //   path: "jobs",
      //   children: [
      //     {
      //       index: true,
      //       element: <JobBrowsePage />,
      //     },
      //     {
      //       path: "create",
      //       element: <JobCreatePage />,
      //     },
      //     {
      //       path: "companies",
      //       children: [
      //         {
      //           path: ":companyId",
      //           element: <CompanyDetailPage />,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: "logistics",
      //   children: [
      //     {
      //       index: true,
      //       element: <LogisticsDashboardPage />,
      //     },
      //     {
      //       path: "fleet",
      //       element: <LogisticsFleetPage />,
      //     },
      //   ],
      // },
      {
        path: "orders",
        children: [
          {
            index: true,
            element: <OrderListPage />,
            permissions: ["menu_orders"],
          },
          // {
          //   path: ":orderId",
          //   element: <OrderDetailPage />,
          // },
        ],
      },
      // {
      //   path: "ordersold",
      //   children: [
      //     {
      //       index: true,
      //       element: <OrderListOldPage />,
      //     },
      //   ],
      // },
      // {
      //   path: "products",
      //   children: [
      //     {
      //       index: true,
      //       element: <ProductsPage />,
      //     },
      //     {
      //       path: "create",
      //       element: <ProductCreatePage />,
      //     },
      //   ],
      // },
      // {
      //   path: "social",
      //   children: [
      //     {
      //       path: "feed",
      //       element: <SocialFeedPage />,
      //     },
      //     {
      //       path: "profile",
      //       element: <SocialProfilePage />,
      //     },
      //   ],
      // },
      {
        path: "account",
        element: <AccountPage />,
      },
      // {
      //   path: "analytics",
      //   element: <AnalyticsPage />,
      // },
      // {
      //   path: "blank",
      //   element: <BlankPage />,
      // },
      // {
      //   path: "calendar",
      //   element: <CalendarPage />,
      // },
      // {
      //   path: "chat",
      //   element: <ChatPage />,
      // },
      // {
      //   path: "crypto",
      //   element: <CryptoPage />,
      // },
      // {
      //   path: "ecommerce",
      //   element: <EcommercePage />,
      // },
      // {
      //   path: "file-manager",
      //   element: <FileManagerPage />,
      // },
      // {
      //   path: "kanban",
      //   element: <KanbanPage />,
      // },
      // {
      //   path: "mail",
      //   element: <MailPage />,
      // },

      {
        path: "organizations",
        element: <Organizations />,
        permissions: ["menuadmin_organizations"],
      },
      {
        path: "users",
        element: <UsersList />,
        permissions: ["menuadmin_users"],
      },
      {
        path: "roles",
        element: <RolesList />,
        permissions: ["menuadmin_roles"],
      },
      {
        path: "logs",
        element: <LogsList />,
        permissions: ["menuadmin_logs"],
      },
      // {
      //   path: "settings",
      //   element: <Settings />,
      //   permissions: ["menuadmin_settings"],
      // },
      {
        path: "patients",
        element: <PatientsList />,
        permissions: ["menu_patients"],
      },
      {
        path: "products_list",
        element: <ProductListPage />,
        permissions: ["menuadmin_products"],
      },
      {
        path: "product_types",
        element: <ProductTypesPage />,
        permissions: ["menuadmin_producttypes"],
      },
      {
        path: "departments",
        element: <DepartmentsPage />,
        permissions: ["menuadmin_departments"],
      },
      {
        path: "medical_studies",
        element: <PendingForSamplePage />,
        permissions: ["menu_pendingforsample"],
      },
      {
        path: "kits",
        element: <Kits />,
        permissions: ["menu_kits"],
      },
      {
        path: "samples_report",
        element: <SamplesReport />,
        permissions: ["menu_samplesreport"],
      },
      {
        children: [
          {
            path: "shippers",
            element: <ShippersList />,
            permissions: ["menu_shippers"],
          },
          {
            path: "senders",
            element: <SendersList />,
            permissions: ["menu_senders"],
          },
          {
            path: "laboratories",
            element: <LaboratoriesList />,
            permissions: ["menu_laboratories"],
          },
          {
            path: "recipient_models",
            element: <RecipientModelsList />,
            permissions: ["menu_recipientmodels"],
          },
          {
            path: "shipping",
            element: <ShippingList />,
            permissions: ["menu_shipping"],
          },
          {
            path: "pickups",
            element: <PickupsList />,
            permissions: ["menu_pickups"],
          },
          {
            path: "shipping_dashboard",
            element: <ShippingDashboard />,
            permissions: ["menu_shippingdashboard"],
          },
        ],
      },
      {
        path: "templates",
        element: <TemplatesList />,
        permissions: ["menu_template"],
      },
      {
        path: "template_categories",
        element: <TemplateCategoriesList />,
        permissions: ["menu_templatecategory"],
      },
      {
        path: "campaigns",
        element: <CampaignsList />,
        permissions: ["menu_campaigns"],
      },
      {
        path: "offers",
        element: <OffersList />,
        permissions: ["menu_templatecategory"],
      },
      {
        path: "offers_types",
        element: <OfferTypesList />,
        permissions: ["menu_campaigns"],
      },
    ],
  },
];
