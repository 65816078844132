import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Card,
  FormHelperText,
  Stack,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import Controls from "src/sections/components/controls";
import { Tip } from "../tip";
import { useTranslation } from "react-i18next";
import CRMUtils from "src/utils";
import { useAuth } from "src/hooks/use-auth";
import BarcodeScanner from "../BarcodeScanner";
import Api from "src/libs/api";

export const KitForm = (props) => {
  const { t } = useTranslation();
  const { kitForEdit, addOrEdit, onClose, users } = props;
  const NewUserSchema = Yup.object().shape({
    code: Yup.string().required(t("common:general.field_required")),
  });
  const { user } = useAuth();
  const userCanCreateKitsSuper =
    CRMUtils.userHasPermission(user, ["kit_create"]) &&
    CRMUtils.userHasPermission(user, ["kit_managesuper"]);
  const userCanCreateDepartmentKits =
    CRMUtils.userHasPermission(user, ["kit_create"]) &&
    CRMUtils.userHasPermission(user, ["kit_managedepartment"]);

  const [departments, setDepartments] = useState([]);

  const defaultValues = useMemo(
    () => ({
      code: kitForEdit?.code || "",
      department_id: kitForEdit?.department_id || null,
      user_id: kitForEdit?.user_id || null,
      submit: null,
    }),

    [kitForEdit]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting ,errors},
  } = methods;

  const departmentIDField = watch("department_id");
  const onSubmit = async (data) => {
    try {
      await addOrEdit(data, kitForEdit?.id || null);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const getDepartments = async () => {
    const { data } = await Api.getDepartments();
    setDepartments([...data.data]);
  };

  const setCode = (value) => {
    setValue("code", value);
  };

  const departmentOptions = () => {
    return departments.filter((d) =>
      !userCanCreateKitsSuper ? user.departments.includes(d.id) : true
    );
  };

  const userOptions = () => {
    return users.filter(
      (u) =>
        (!userCanCreateKitsSuper && !userCanCreateDepartmentKits
          ? u.id == user.id
          : true) && u.departments.includes(departmentIDField)
    );
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <form method={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Card
          style={{
            width: "-webkit-fill-available",
            borderRadius: 10,
            padding: 15,
          }}
        >
          <Tip
            title={`${t("common:general.notice")}: `}
            message={`* ${t("common:general.required_tip")}`}
          />
          <br />
          <BarcodeScanner
            defaultResult={kitForEdit?.code || null}
            setCode={setCode}
          />

          {!!errors && errors.code && (
            <FormHelperText sx={{mb: 4}} error>{errors.code.message}</FormHelperText>
          )}

          <div style={{ marginTop: 8 }}>
            <Grid container spacing={1}>
              <Grid xs={12} md={6}>
                {" "}
                <Controller
                  name={"department_id"}
                  control={control}
                  key={"department_id"}
                  render={({ field }) => (
                    <Controls.Select
                      onChange={(value) => {
                        field.onChange(value);
                        setValue("user_id", null);
                      }}
                      label={t("kits:form.department") + "*"}
                      required
                      value={field.value}
                      allowEmpty={false}
                      options={departmentOptions()}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={6}>
                {" "}
                <Controller
                  name={"user_id"}
                  control={control}
                  key={"user_id"}
                  render={({ field }) => (
                    <Controls.Select
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      label={t("kits:form.user")}
                      value={field.value}
                      allowEmpty={false}
                      options={userOptions()}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
      </Stack>

      <div
        style={{
          padding: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Button onClick={onClose} disabled={isSubmitting}>
          {t("common:general.Cancel")}
        </Button>
        <Button
          disabled={isSubmitting}
          startIcon={
            isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
          }
          variant="contained"
          type="submit"
        >
          {t("common:general.Save")}
        </Button>
      </div>
    </form>
  );
};
