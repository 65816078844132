import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  TextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Autocomplete(props) {
  const {
    label,
    value,
    allowEmpty,
    fullWidth = false,
    emptyLabel,
    onChange,
    options,
    disabled,
    variant,
    onClickMenuItem,
    minWidth = 180,
    onInputCompChange,
    ...other
  } = props;
  const { t } = useTranslation("common");
  const [valueObj, setValueObj] = useState(null);

  useEffect(() => {
    setValueObj(options?.find((o) => o.id == value) || null);
  }, [options, value]);

  return (
    <FormControl
      sx={{ minWidth: minWidth }}
      variant={variant ? variant : "outlined"}
      fullWidth={fullWidth}
    >
      <MuiAutocomplete
        value={valueObj}
        defaultValue={valueObj}
        onChange={(event, newValue) => {
          onChange(newValue?.id || null);
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        id="combo-box-demo"
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props?.placeholder || ""}
            label={label}
            onChange={onInputCompChange ? onInputCompChange : null}
          />
        )}
        {...other}
      />
    </FormControl>
  );
}
