import { Breadcrumbs, Link, Stack, Typography } from "@mui/material";

import { BreadcrumbsSeparator } from "src/components/breadcrumbs-separator";
import { RouterLink } from "src/components/router-link";

export default function PageHeader(props) {
  const { title, menus=[] } = props;
  return (
    <Stack direction="row" justifyContent="space-between" spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h4">{title}</Typography>
        <Breadcrumbs separator={<BreadcrumbsSeparator />}>
          {menus.map(( menu) => {
            return menu.href ? (
              <Link
                color="text.primary"
                component={RouterLink}
                href={menu.href}
                variant="subtitle2"
              >
                {menu.title}
              </Link>
            ) : (
              <Typography color="text.secondary" variant="subtitle2">
                {menu.title}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Stack>
    </Stack>
  );
}
