import PropTypes from "prop-types";
import { Box, ButtonBase, SvgIcon, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useAuth } from "src/hooks/use-auth";
import { useRoutes } from "react-router";
import { routes } from ".";
import { useEffect } from "react";
import { filter, isEmpty } from "lodash";
import CRMUtils from "src/utils";
import { useState } from "react";
import { getBrowserToken } from "src/utils/firebase";

export const RenderRoutesElement = (props) => {
  const { user } = useAuth();
  const [pRoutes, setPRoutes] = useState([]);
  const element = useRoutes(pRoutes);

  useEffect(() => {
    if (!isEmpty(user)) getBrowserToken(user.id);
  }, [user]);

  const getRoutes = () => {
    let allowedRoutes = routes;

    if (user && user.permissions && user.permissions.data) {
      const allowedTempRoutes = filter(routes, function (item) {
        return item && !isEmpty(item.permissions)
          ? CRMUtils.userHasPermission(user, item.permissions) &&
              (item.patient ? user.is_or_has_patients : true)
          : item.patient
          ? user.is_or_has_patients
          : true;
      });

      allowedRoutes = allowedTempRoutes.map((item, index) => {
        return {
          ...item,
          children: item.children
            ? item.children.filter((item2) => {
                return !isEmpty(item2.permissions)
                  ? CRMUtils.userHasPermission(user, item2.permissions) &&
                      (item2.patient ? user.is_or_has_patients : true)
                  : item2.patient
                  ? user.is_or_has_patients
                  : true;
              })
            : null,
        };
      });

      allowedRoutes = allowedRoutes.map((item, index) => {
        return {
          ...item,
          children: item.children
            ? item.children.map((item2) => {
                return {
                  ...item2,
                  children: item2.children
                    ? item2.children.filter((item3) => {
                        return !isEmpty(item3.permissions)
                          ? CRMUtils.userHasPermission(
                              user,
                              item3.permissions
                            ) &&
                              (item3.patient ? user.is_or_has_patients : true)
                          : item3.patient
                          ? user.is_or_has_patients
                          : true;
                      })
                    : null,
                };
              })
            : null,
        };
      });
    }

    setPRoutes(allowedRoutes);
  };

  useEffect(() => {
    getRoutes();
  }, [user, routes]);

  return element;
};
