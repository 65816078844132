import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Button, Card, Stack, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import Controls from "src/sections/components/controls";
import { useTranslation } from "react-i18next";
import Api from "src/libs/api";
import CRMUtils from "src/utils";

export const ChangeSampleStatusForm = (props) => {
  const { t } = useTranslation();
  const { onChangeStatus, setIsSubmitting, isSubmitting, onClose, sample } =
    props;
  const NewUserSchema = Yup.object().shape({});
  const [statuses, setStatuses] = useState([]);

  const defaultValues = useMemo(
    () => ({
      status_id: sample?.status_id || null,
      submit: null,
    }),

    [sample]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      setIsSubmitting(true);
      const { data } = await Api.updateSampleStatus(
        payload["status_id"],
        sample?.id || null
      );

      if (typeof onChangeStatus == "function") {
        await onChangeStatus({ ...data.data });
      }
    } catch (err) {
      toast.error(t("common:general.error_message"), {
        style: { backgroundColor: "rgb(211 39 39 / 77%)" },
      });
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStatuses = async () => {
    const { data } = await Api.getStatusByModel(
      "Sample",
      "?filter[restricted_for_automatic_use]=2"
    );

    setStatuses([...data.data]);
  };

  const statusesOptions = () => {
    return statuses.map((status) => {
      return {
        id: status.id,
        name: CRMUtils.getFieldTranslation(status.name)
          ? CRMUtils.getFieldTranslation(status.name)
              .replace(/^\w/, (c) => c.toUpperCase())
              .replaceAll("_", " ")
          : "",
      };
    });
  };

  useEffect(() => {
    getStatuses();
  }, []);

  return (
    <form method={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Card
          style={{
            width: "-webkit-fill-available",
            borderRadius: 10,
            padding: 15,
          }}
        >
          <Stack divider={<Divider />} spacing={3} sx={{ m: 3 }}>
            <Controller
              name={"status_id"}
              control={control}
              key={"status_id"}
              render={({ field }) => (
                <Controls.Select
                  label={t(
                    "orders_translation:order_items.columns.sample_status"
                  )}
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  allowEmpty={true}
                  options={statusesOptions()}
                  fullWidth={true}
                />
              )}
            />
          </Stack>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Button onClick={onClose} disabled={isSubmitting}>
              {t("common:general.Cancel")}
            </Button>
            <Button
              disabled={isSubmitting}
              startIcon={
                isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
              }
              variant="contained"
              type="submit"
            >
              {t("common:general.Save")}
            </Button>
          </div>
        </Card>
      </Stack>
    </form>
  );
};
