import PropTypes from "prop-types";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { blue, green, indigo, purple } from "src/theme/colors";
import { useTranslation } from "react-i18next";

export const OptionsColorPreset = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const options = [
    {
      label: t("app_settings:primary_color.green"),
      value: "green",
      color: green.main,
    },
    {
      label: t("app_settings:primary_color.blue"),
      value: "blue",
      color: blue.main,
    },
    {
      label: t("app_settings:primary_color.indigo"),
      value: "indigo",
      color: indigo.main,
    },
    {
      label: t("app_settings:primary_color.purple"),
      value: "purple",
      color: purple.main,
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography color="text.secondary" variant="overline">
        {t("app_settings:primary_color.title")}
      </Typography>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        {options.map((option) => (
          <Chip
            icon={
              <Box
                sx={{
                  backgroundColor: option.color,
                  borderRadius: "50%",
                  flexShrink: 0,
                  height: 24,
                  width: 24,
                }}
              />
            }
            key={option.value}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: "transparent",
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: "primary.main",
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsColorPreset.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(["blue", "green", "indigo", "purple"]),
};
