import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Fab, useTheme } from "@mui/material";
import { useAuth } from "src/hooks/use-auth";

import axios from "axios";
import { Icon } from "@iconify/react";
import { useMounted } from "src/hooks/use-mounted";
import { useSearchParams } from "src/hooks/use-search-params";

function GoogleLoginButton(props) {
  const { setErrorMessage, setSubmitting, onSucessfulLogin } = props;
  const { loginGoogle } = useAuth();
  const theme = useTheme();
  const searchParams = useSearchParams();
  const isMounted = useMounted();
  const returnTo = searchParams.get("returnTo");

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setErrorMessage(null);
        setSubmitting(true);
        await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then((res) => res.data);

        const userData = await loginGoogle(tokenResponse.access_token);
        onSucessfulLogin(userData);
      } catch (err) {
        if (isMounted()) {
          setSubmitting(false);
          setErrorMessage(err.message);
        }
      }
    },
    onError: (errorResponse) => console.log("ERROR:", errorResponse.error),
  });

  return (
    <Fab
      onClick={() => googleLogin()}
      style={{ background: "#ffffff17" }}
      aria-label="add"
    >
      <Icon style={{ fontSize: 25 }} icon="logos:google-icon" />
    </Fab>
  );
}

export default GoogleLoginButton;
