export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'http://localhost:8000/api';



export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY?process.env.REACT_APP_FIREBASE_APIKEY:"AIzaSyDc8FHGKrLH-0flfKPxToAuSW4YGwsMS4Q",
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN?process.env.REACT_APP_FIREBASE_AUTHDOMAIN:"ytomorrow-notifications.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID?process.env.REACT_APP_FIREBASE_PROJECTID:"ytomorrow-notifications",
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET?process.env.REACT_APP_FIREBASE_BUCKET:"ytomorrow-notifications.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID?process.env.REACT_APP_FIREBASE_SENDERID:"782460187429",
  appId: process.env.REACT_APP_FIREBASE_APPID?process.env.REACT_APP_FIREBASE_APPID:"1:782460187429:web:cb8e4c2b3b1e070f9c20cc",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID?process.env.REACT_APP_FIREBASE_MEASUREMENTID:"G-XDED0Y5RD",
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const mapboxConfig = {
  apiKey: process.env.REACT_APP_MAPBOX_API_KEY
};
