import { Icon } from "@iconify/react";
import { Avatar, Stack, SvgIcon, Typography, useTheme } from "@mui/material";

export const EntityMainInfo = (props) => {
  const { entity,  avatar } = props;
  const theme = useTheme();

  return (
    <Stack style={{padding: '0px 15px'}} alignItems="center" direction="row" spacing={1}>
      {avatar}

      <div>
        <Typography color="inherit" variant="subtitle2">
          {entity.title}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {entity.subtitle}
        </Typography>
      </div>
    </Stack>
  );
};
