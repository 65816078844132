import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Issuer } from "src/utils/auth";

import axios from "../../utils/axios";
import { isValidToken, setSession } from "src/utils/jwt";
import CRMUtils from "src/utils";

const STORAGE_KEY = "accessToken";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["LOGINGOOGLE"] = "LOGINGOOGLE";
  ActionType["FACEBOOKLOGIN"] = "FACEBOOKLOGIN";
  ActionType["APPLELOGIN"] = "APPLELOGIN";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
  ActionType["UPDATE_STATE"] = "UPDATE_STATE";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGINGOOGLE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  FACEBOOKLOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  APPLELOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_UP: (state, action) => {
    const { user, transaction_response } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      transaction_response,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  UPDATE_STATE: (state, payload) => {
    return {
      ...state,
      ...payload.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  loginGoogle: () => Promise.resolve(),
  appleLogin: () => Promise.resolve(),
  facebookLogin: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  updateState: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      var headers = {};
      if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
        headers["PUBLIC-ACCESS-API-KEY"] =
          process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
      }
      const accessToken = localStorage.getItem(STORAGE_KEY);
      if (accessToken && isValidToken(accessToken)) {
        const { data } = await axios.get("/me", {
          headers: {
            ...{ Authorization: `Bearer ${accessToken}` },
            ...headers,
          },
        });

        var user = { ...data.data };

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (email, password) => {
      const currentURL = window.location.origin + window.location.pathname;
      const currentToken = CRMUtils.getURLOrganizationToken(currentURL);

      var headers = {};
      if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
        headers["PUBLIC-ACCESS-API-KEY"] =
          process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
      }

      const response = await axios.post(
        "/login",
        {
          ...{
            email,
            password,
          },
          ...{ organization_token: currentToken },
        },
        { headers: headers }
      );
      const { accessToken, user } = response.data;

      setSession(STORAGE_KEY, accessToken);

      const userData = user.data;

      dispatch({
        type: "SIGN-IN",
        payload: {
          user: userData,
        },
      });
      return userData;
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (payload) => {
      try {
        var headers = {};
        if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
          headers["PUBLIC-ACCESS-API-KEY"] =
            process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
        }
        const url = payload.url ? payload.url : "/register";
        const response = await axios.post(url, payload, { headers: headers });
        const { accessToken, user, transaction_response } = response.data;

        setSession(STORAGE_KEY, accessToken);

        dispatch({
          type: ActionType.SIGN_UP,
          payload: {
            user: user.data,
            transaction_response,
          },
        });
        return {
          user: user.data,
          transaction_response,
        };
      } catch (err) {
        return { signup_error: err };
      }
    },
    [dispatch]
  );

  const loginGoogle = useCallback(
    async (token) => {
      const currentURL = window.location.origin + window.location.pathname;
      const currentToken = CRMUtils.getURLOrganizationToken(currentURL);

      var headers = {};
      if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
        headers["PUBLIC-ACCESS-API-KEY"] =
          process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
      }
      const response = await axios.post(
        "/googleLogin/" + token,
        { organization_token: currentToken },
        {
          headers: headers,
        }
      );
      const { accessToken, user } = response.data;

      setSession(STORAGE_KEY, accessToken);
      const userData = user.data;

      dispatch({
        type: ActionType.LOGINGOOGLE,
        payload: {
          user: userData,
        },
      });
      return userData;
    },
    [dispatch]
  );

  const facebookLogin = useCallback(
    async (token) => {
      const currentURL = window.location.origin + window.location.pathname;
      const currentToken = CRMUtils.getURLOrganizationToken(currentURL);

      var headers = {};
      if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
        headers["PUBLIC-ACCESS-API-KEY"] =
          process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
      }
      const response = await axios.post(
        "/facebookLogin/" + token,
        { organization_token: currentToken },
        {
          headers: headers,
        }
      );
      const { accessToken, user } = response.data;

      setSession(STORAGE_KEY, accessToken);
      const userData = user.data;

      dispatch({
        type: ActionType.FACEBOOKLOGIN,
        payload: {
          user: userData,
        },
      });
      return userData;
    },
    [dispatch]
  );

  const appleLogin = useCallback(
    async (token) => {
      const currentURL = window.location.origin + window.location.pathname;
      const currentToken = CRMUtils.getURLOrganizationToken(currentURL);
      var headers = {};
      if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
        headers["PUBLIC-ACCESS-API-KEY"] =
          process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
      }
      const response = await axios.post(
        "/appleLogin/" + token,
        { organization_token: currentToken },
        {
          headers: headers,
        }
      );
      const { accessToken, user } = response.data;

      setSession(STORAGE_KEY, accessToken);
      const userData = user.data;

      dispatch({
        type: ActionType.APPLELOGIN,
        payload: {
          user: userData,
        },
      });
      return userData;
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    var headers = {};
    if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
      headers["PUBLIC-ACCESS-API-KEY"] =
        process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
    }

    await axios.post("/logout", "", {
      headers: {
        ...{
          common: {
            Authorization: `Bearer ${localStorage.getItem(STORAGE_KEY)}`,
          },
        },
        ...headers,
      },
    });
    localStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const updateState = useCallback(
    async (payload) => {
      dispatch({
        type: ActionType.UPDATE_STATE,
        payload: payload,
      });
    },
    [dispatch]
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        loginGoogle,
        facebookLogin,
        appleLogin,
        signOut,
        updateState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
