import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  Button,
  Card,
  Typography,
  useTheme,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  SvgIcon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
} from "@mui/material";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import Controls from "src/sections/components/controls";
import { EntityMainInfo } from "src/sections/components/controls/EntityMainInfo";
import { Products } from "../Products";
import { Popup } from "src/sections/components/controls/Popup";
import { findIndex, isEmpty } from "lodash";
import { Scrollbar } from "../scrollbar";
import Api from "src/libs/api";
import { useTranslation } from "react-i18next";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { NewPatientForm } from "./NewPatientForm";
import CRMUtils from "src/utils";
import { AvailableOffers } from "../AvailableOffers";
import { AvailableOffersList } from "../AvailableOffersList";
import moment from "moment";
import NMIBehavior from "../NMIBehavior";
import { useDispatch } from "react-redux";
import { ReferenceCreditAplicator } from "../ReferenceCreditAplicator";
import { PurchaseExpirationCountdown } from "../PurchaseExpirationCountdown";
import TermsAndConditions from "../TermsAndConditions";
import { YtomorrowTermsAndConditions } from "../YtomorrowTermsAndConditions";

export const OrderForm = (props) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { setOpenPopup, user = null } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const NewUserSchema = Yup.object().shape({
    for_id: Yup.string().required("Please select an option"),
  });
  const [failureText, setFailureText] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isOffersPopupOpen, setIsOffersPopupOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [openProductList, setOpenProductList] = useState(false);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [availableOffers, setAvailableOffers] = useState([]);
  const defaultValues = useMemo(
    () => ({
      for_id: 1,
      submit: null,
    }),

    []
  );

  const dispatch = useDispatch();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [placePredictionsLocal, setPlacePredictionsLocal] = useState([]);
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false);
  const { t } = useTranslation();
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const updateProducts = (product) => {
    const index = findIndex(selectedProducts, ["unique_id", product.unique_id]);

    if (index != -1) {
      const updatedEvent = {
        ...selectedProducts[index],
        ...product,
      };

      let newProducts = [...selectedProducts];
      newProducts[index] = updatedEvent;
      setSelectedProducts([...newProducts]);
    }
  };

  const onSubmit = (data) => {
    var payload = data;
    if (data.dob) {
      payload["dob"] = moment(data.dob).format("YYYY-MM-DD");
    }
    payload["organization_id"] = user?.organization_id || null;
    payload["currency_id"] =
      selectedProducts.length > 0
        ? selectedProducts[0]?.currency?.id || null
        : null;
    payload["products"] = selectedProducts;
    payload["total_to_pay"] = selectedProducts.reduce((sum, product) => {
      return (
        sum +
        (product.express
          ? parseFloat(product.express_price)
          : parseFloat(product.standard_price))
      );
    }, 0);

    setFormData(payload);
    setOpenPaymentPopup(true);
  };

  const setSearch = (search) => {
    getPlacePredictions({ input: search });
  };

  useEffect(() => {
    if (placePredictions.length) {
      setPlacePredictionsLocal([...placePredictions]);
    }
  }, [placePredictions]);

  useEffect(() => {
    try {
      if (selectedAddress) {
        for (const component of selectedAddress.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "postal_code": {
              setValue("zip_code", component.long_name, {
                shouldValidate: true,
              });
              break;
            }
            case "locality":
              setValue("city", component.long_name, {
                shouldValidate: true,
              });
              break;
            case "administrative_area_level_1": {
              setValue("state", component.short_name, {
                shouldValidate: true,
              });

              break;
            }
            case "country":
              setValue("country", component.long_name, {
                shouldValidate: true,
              });

              break;
          }
        }
        setValue("address", selectedAddress.name, {
          shouldValidate: true,
        });

        setPlacePredictionsLocal([]);
      }
    } catch {
      console.error("selectedAddress error");
    }
  }, [selectedAddress]);

  const meOptionComponent = () => {
    var entity = {
      title: user?.fullname || "",
      subtitle:
        user && user.patient.data
          ? `${user.patient.data.address}, ${user.patient.data.city}, ${
              user.patient.data.state
            } ${user.patient.data?.zip_code || ""}`
          : "",
    };
    return (
      <div
        style={{
          marginLeft: 14,
          padding: 10,
          background: "#c2c2c226",
          borderRadius: 15,
        }}
      >
        <EntityMainInfo entity={entity} />
      </div>
    );
  };

  const existentPatientOptionComponent = () => {
    var myPatientsOptions =
      user?.patients?.data?.map((patient) => {
        return {
          id: patient.id,
          title: patient.fullname,
        };
      }) || [];
    return (
      <div>
        <Typography>Please select a patient: </Typography>
        <Controller
          name={"patient_id"}
          control={control}
          key={"patient_id"}
          render={({ field }) => (
            <Controls.Select
              onChange={(value) => {
                field.onChange(value);
                setSelectedPatient(value.target.value);
              }}
              value={field.value}
              allowEmpty={false}
              options={myPatientsOptions}
              fullWidth={true}
            />
          )}
        />
      </div>
    );
  };

  const patientsOptions = [
    {
      id: 0,
      label: t("orders_translation:new_order.order_for_options.new_patient"),
      component: <NewPatientForm control={control} setValue={setValue} />,
      disabled: false,
    },
    {
      id: 1,
      label: t("orders_translation:new_order.order_for_options.me"),
      disabled: false,
      component: !isEmpty(user?.patient?.data || []) ? (
        meOptionComponent()
      ) : (
        <div>
          <Typography variant="h6" sx={{ p: 1 }}>
            {t("orders_translation:new_order.user_no_patient_message")}
          </Typography>
          <NewPatientForm
            me={true}
            defaultValues={user}
            control={control}
            setValue={setValue}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: t(
        "orders_translation:new_order.order_for_options.existent_patient"
      ),
      disabled: user && user.patients && user.patients.data.length == 0,
      component: existentPatientOptionComponent(),
      tooltip:
        user && user.patients && user.patients.data.length == 0
          ? t("orders_translation:new_order.no_patients_message")
          : "",
    },
  ];

  const addProduct = (product) => {
    var tempProduct = { ...product };
    tempProduct["unique_id"] =
      selectedProducts.filter((a) => a.unique_id < 0).length > 0
        ? selectedProducts
            .filter((a) => a.unique_id < 0)
            .sort((a, b) => a.unique_id - b.unique_id)[0].unique_id - 1
        : -1;
    setSelectedProducts([tempProduct, ...selectedProducts]);

    toast.success(t("common:general.Product_added"), {
      style: { backgroundColor: "rgb(53 166 80 / 77%)" },
    });
  };

  const removeProduct = (product) => {
    setSelectedProducts(
      selectedProducts.filter((a) => a.unique_id != product.unique_id)
    );
  };

  const productActions = () => {
    return [
      {
        action: (product) => {
          return (
            <Button
              disabled={
                product.available_qty == 0 ||
                selectedProducts.filter((a) => a.id == product.id).length ==
                  product.available_qty
              }
              onClick={() => addProduct(product)}
              startIcon={<Icon icon="ic:round-plus" />}
            >
              {t("products_translation:add_products_form.add")}
            </Button>
          );
        },
      },
    ];
  };

  const renderSelectedProducts = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t("orders_translation:new_order.product_columns.name")}
            </TableCell>
            <TableCell>
              {t("orders_translation:new_order.product_columns.express")}
            </TableCell>
            <TableCell>
              {t("orders_translation:new_order.product_columns.price")}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedProducts.map((item) => {
            return (
              <TableRow key={item.unique_id}>
                <TableCell>
                  <Typography variant="subtitle2">
                    {CRMUtils.getFieldTranslation(item.name)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {item.express ? (
                    <Icon
                      fontSize={25}
                      icon="material-symbols:check-small-rounded"
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.base_price &&
                    item.base_price !=
                      (item.express
                        ? item.express_price
                        : item.standard_price) ? (
                      <Typography
                        style={{
                          textDecoration: "2px line-through red",
                          marginRight: 6,
                        }}
                      >
                        {" "}
                        ${item.base_price}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography
                      style={{
                        fontWeight:
                          item.base_price &&
                          item.base_price !=
                            (item.express
                              ? item.express_price
                              : item.standard_price)
                            ? 600
                            : 400,
                        fontSize:
                          item.base_price &&
                          item.base_price !=
                            (item.express
                              ? item.express_price
                              : item.standard_price)
                            ? 17
                            : 15,
                      }}
                    >
                      {" "}
                      {` ${item?.currency?.symbol || ""}${
                        item.express ? item.express_price : item.standard_price
                      } ${item?.currency?.iso_code || ""}`}
                    </Typography>
                  </div>
                  {item.base_price &&
                  item.base_price !=
                    (item.express
                      ? item.express_price
                      : item.standard_price) ? (
                    <Typography
                      style={{
                        color: "rgb(151 151 151)",
                        fontWeight: 500,
                        fontSize: 13,
                      }}
                    >
                      {`${t("offers:offers.orders_form.saved")} $${(
                        parseFloat(item.base_price) -
                        parseFloat(
                          item.express
                            ? item.express_price
                            : item.standard_price
                        )
                      ).toFixed(2)}`}
                    </Typography>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      removeProduct(item);
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <SvgIcon>
                      <Trash02Icon />
                    </SvgIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const getAvailableOffers = async () => {
    const { data } = await Api.getAvailableOffers();

    setAvailableOffers([...data.data]);
  };

  useEffect(() => {
    getAvailableOffers();
    setSelectedOption(patientsOptions[1]);
  }, []);

  const onClosePaymentForm = () => {
    setOpenPaymentPopup(false);
    setFailureText(null);
    setIsFormLoading(false);
  };
  const createOrder = async (payload) => {
    try {
      const { data } = await Api.createOrder({ ...formData, ...payload });

      if (data.payment_error) {
        if (data.payment_error.status == "declined") {
          setFailureText(
            t("payments:payment_form.payment_messages.transaction_declined")
          );
        } else if (data.payment_error.status == "error") {
          setFailureText(data.payment_error.message);
        } else {
          setFailureText(
            t("payments:payment_form.payment_messages.general_error")
          );
        }
      } else {
        toast.success(
          t("payments:payment_form.payment_messages.processing_payment"),
          {
            style: { backgroundColor: "rgb(53 166 80 / 77%)" },
          }
        );
        onClosePaymentForm();
        setOpenPopup(false);
      }
    } catch (err) {
      setFailureText(t("common:general.error_message"));
    } finally {
      setIsFormLoading(false);
    }
  };

  return user ? (
    <>
      <form method={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <PurchaseExpirationCountdown
            maxMinutes={user?.organization?.data?.price_access_duration || null}
            onOk={() => {
              onClosePaymentForm();
              setOpenPopup(false);
            }}
          />

          {availableOffers.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 8,
                background: "rgb(88 177 255 / 13%)",
                margin: 10,
                maxWidth: "100%",
                borderRadius: 8,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  style={{ width: 22, height: 22, marginRight: 4 }}
                  icon="bxs:offer"
                />
                <Typography style={{ fontSize: 16, fontWeight: 500 }}>
                  {" "}
                  {t("offers:offers.orders_form.dont_miss_our_offers")}
                </Typography>
              </div>
              <Button
                onClick={() => {
                  setIsOffersPopupOpen(true);
                }}
                style={{ fontSize: 16, fontWeight: 500 }}
              >
                {" "}
                <Typography style={{ fontWeight: 600 }}>
                  {"(" +
                    t("offers:offers.orders_form.click_for_more_details") +
                    ")"}
                </Typography>
              </Button>
            </div>
          ) : (
            ""
          )}

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  style={{
                    margin: 5,
                    color: theme.palette.primary.main,
                    fontSize: 22,
                  }}
                  icon="material-symbols:counter-1-outline"
                />
                <Typography variant="h6">
                  {t("orders_translation:new_order.patient_information")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ margin: "10px 16px" }}>
                <Controller
                  name={"for_id"}
                  control={control}
                  key={"for_id"}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel
                        style={{ fontSize: 16 }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        {t("orders_translation:new_order.order_for")}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                      >
                        {patientsOptions.map((option) => {
                          return (
                            <Tooltip title={option?.tooltip || ""}>
                              <FormControlLabel
                                value={option?.id}
                                onChange={() => {
                                  setSelectedProducts([]);

                                  setSelectedOption(option);
                                }}
                                control={<Radio />}
                                label={option?.label}
                                disabled={option?.disabled}
                              />
                            </Tooltip>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                {selectedOption?.component || ""}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  style={{
                    margin: 5,
                    color: theme.palette.primary.main,
                    fontSize: 22,
                  }}
                  icon="material-symbols:counter-2-outline"
                />
                <Typography variant="h6">
                  {t("orders_translation:new_order.configure_order")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "6px 2px",
                  flexWrap: "wrap",
                }}
              >
                <FormLabel
                  style={{ fontSize: 16 }}
                  id="demo-row-radio-buttons-group-label"
                >
                  {t("orders_translation:new_order.order_products")}
                </FormLabel>

                <Button
                  onClick={() => setOpenProductList(true)}
                  startIcon={<Icon icon="ic:round-plus" />}
                  variant="contained"
                >
                  {selectedProducts.length > 0
                    ? t("orders_translation:new_order.select_products")
                    : t("orders_translation:new_order.add_products")}
                </Button>
              </div>
              {renderSelectedProducts()}{" "}
              <div
                style={{
                  display: "flex",
                  margin: "8px 8px",
                  justifyContent: "end",
                }}
              >
                <Typography
                  style={{
                    textAlign: "right",
                    fontWeight: 600,
                    margin: "0px 15px",

                    fontSize: 15,
                  }}
                >
                  {`${t("orders_translation:new_order.total_to_pay")}: `}
                </Typography>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedProducts.find((a) => a.offer_id) ? (
                      <Typography
                        style={{
                          padding: "0px 10px",
                          textDecoration: "2px line-through red",
                          textAlign: "right",
                          fontWeight: 600,

                          fontSize: 15,
                        }}
                      >
                        {`${
                          selectedProducts[0]?.currency?.symbol || ""
                        }${selectedProducts
                          .reduce((sum, product) => {
                            return (
                              sum +
                              (product.base_price
                                ? product.base_price
                                : product.express
                                ? parseFloat(product.express_price)
                                : parseFloat(product.standard_price))
                            );
                          }, 0)
                          .toFixed(2)} ${
                          selectedProducts[0]?.currency?.iso_code || ""
                        }`}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        marginRight: 6,

                        fontSize: selectedProducts.find((a) => a.base_price)
                          ? 17
                          : 15,
                      }}
                    >
                      {`${
                        selectedProducts[0]?.currency?.symbol || ""
                      }${selectedProducts
                        .reduce((sum, product) => {
                          return (
                            sum +
                            (product.express
                              ? parseFloat(product.express_price)
                              : parseFloat(product.standard_price))
                          );
                        }, 0)
                        .toFixed(2)} ${
                        selectedProducts[0]?.currency?.iso_code || ""
                      }`}
                    </Typography>
                  </div>
                  {selectedProducts.find((a) => a.offer_id) ? (
                    <Typography
                      style={{
                        color: "rgb(151 151 151)",
                        fontWeight: 500,
                        fontSize: 13,
                        marginLeft: 10,
                      }}
                    >
                      {`${t("offers:offers.orders_form.saved")} $${(
                        selectedProducts.reduce((sum, product) => {
                          return (
                            sum +
                            (product.base_price
                              ? product.base_price
                              : product.express
                              ? parseFloat(product.express_price)
                              : parseFloat(product.standard_price))
                          );
                        }, 0) -
                        selectedProducts.reduce((sum, product) => {
                          return (
                            sum +
                            (product.express
                              ? parseFloat(product.express_price)
                              : parseFloat(product.standard_price))
                          );
                        }, 0)
                      ).toFixed(2)}`}
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ReferenceCreditAplicator
                maxCreditToApply={1000}
                setProducts={setSelectedProducts}
                products={selectedProducts}
              />
              <AvailableOffers
                setProducts={setSelectedProducts}
                patient_id={
                  selectedOption && selectedOption.id == 1
                    ? user?.patient?.data?.id || null
                    : selectedPatient && selectedOption && selectedOption.id
                    ? selectedPatient.id
                    : null
                }
                products={selectedProducts}
              />
            </AccordionDetails>
          </Accordion>
          <div style={{ padding: "2px 5px", paddingLeft: 18, marginBottom: 5 }}>
            <TermsAndConditions
              value={termsAndConditionsChecked}
              setValue={setTermsAndConditionsChecked}
              termsAndConditions={<YtomorrowTermsAndConditions />}
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              fullWidth
              disabled={
                isSubmitting ||
                selectedProducts.length == 0 ||
                !termsAndConditionsChecked
              }
              startIcon={
                isSubmitting ? <Icon icon="eos-icons:three-dots-loading" /> : ""
              }
              variant="contained"
              color="primary"
              type="submit"
            >
              {t("orders_translation:new_order.save_and_pay")}
            </Button>
          </div>
        </Card>

        <Popup
          onClose={() => {
            setOpenProductList(false);
          }}
          fullWidth={true}
          maxWidth="md"
          open={openProductList}
          title={t("products_translation:add_products_form.product")}
        >
          <Scrollbar style={{ maxHeight: 700, padding: "0px 10px" }}>
            <Products
              patient_id={
                selectedOption && selectedOption.id == 1
                  ? user && user.patient && user.patient.data
                    ? user.patient.data.id
                    : null
                  : selectedOption && selectedOption.id == 2
                  ? selectedPatient
                  : null
              }
              defaultProducts={[]}
              actions={productActions()}
              updateProducts={updateProducts}
            />
          </Scrollbar>
        </Popup>
      </form>
      <Popup
        onClose={onClosePaymentForm}
        fullWidth={true}
        maxWidth="sm"
        open={openPaymentPopup}
        title={t("payments:payment_form.title")}
      >
        <NMIBehavior
          currency={
            selectedProducts.length > 0
              ? selectedProducts[0]?.currency?.iso_code || null
              : null
          }
          setIsFormLoading={setIsFormLoading}
          isFormLoading={isFormLoading}
          setFailureText={setFailureText}
          failureText={failureText}
          merchantConfiguration={user?.merchantConfiguration?.data || null}
          totalToPay={formData?.total_to_pay}
          proccessTransaction={createOrder}
        />
        {/* <StripeOrderPaymentFormWrapper
          data={formData}
          setOpenPaymentPopup={setOpenPaymentPopup}
          setOpenPopup={setOpenPopup}
        /> */}
      </Popup>
      <Popup
        onClose={() => {
          setIsOffersPopupOpen(false);
        }}
        fullWidth={true}
        maxWidth="md"
        open={isOffersPopupOpen}
        title={t("offers:offers.title")}
      >
        <AvailableOffersList
          setAvailableOffers={setAvailableOffers}
          availableOffers={availableOffers}
        />
      </Popup>
    </>
  ) : (
    <LinearProgress />
  );
};
