import React, { useState } from "react";
import { useAuth } from "src/hooks/use-auth";
import { Button, Checkbox, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Popup } from "src/sections/components/controls/Popup";

const TermsAndConditions = (props) => {
  const { value, setValue, termsAndConditions = "", url = false } = props;
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div>
      <Typography
        variant="caption"
        style={{
          fontSize: 16,
        }}
      >
        <Checkbox
          checked={value}
          style={{ paddingLeft: 0 }}
          onChange={() => {
            setValue(!value);
          }}
          color="primary"
        />
        {`${t("common:termsAndConditions.title")} `}
        {url ? (
          <a
            href={termsAndConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${t("common:termsAndConditions.link_text")}`}
          </a>
        ) : (
          <Button
            style={{
              padding: 0,
              fontSize: 16,
              marginBottom: 2,
              marginLeft: 2,
              borderBottom: "1px solid",
              borderRadius: 0,
            }}
            onClick={() => {
              setOpenPopup(true);
            }}
          >{`${t("common:termsAndConditions.link_text")}`}</Button>
        )}
      </Typography>

      <Popup
        onClose={() => setOpenPopup(false)}
        fullWidth={true}
        open={openPopup}
        title={t("common:termsAndConditions.link_text").toUpperCase()}
      >
        {termsAndConditions}
      </Popup>
    </div>
  );
};

export default TermsAndConditions;
