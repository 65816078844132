import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Fab } from "@mui/material";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useMounted } from "src/hooks/use-mounted";
import { paths } from "src/paths";
import Api from "src/libs/api";
import { useNavigate } from "react-router";

function GoogleVerificationButton(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const { setLoading, setErrorMessage, uniqueToken=null } = props;
  const navigate = useNavigate();
  const isMounted = useMounted();
  const token_to_refer = searchParams.get("rt");
  const unique_token_to_refer = searchParams.get("rut");

  const getBasename = (url) => {
    try {
      const urlObj = new URL(url);
      const pathParts = urlObj.pathname.split("/");
      return pathParts[1];
    } catch (error) {
      console.error("Invalid URL", error);
      return null;
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setErrorMessage(null);
        setLoading(true);
        await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then((res) => res.data);

        const { data } = await Api.getGoogleUserInformation(
          tokenResponse.access_token,
          "?orgt=" +
            getBasename(window.location.href) +
            (uniqueToken && uniqueToken.email
              ? "&email=" + uniqueToken.email
              : "")
        );

        if (isMounted()) {
          navigate(
            unique_token_to_refer
              ? paths.auth.jwt.register + "?rut=" + unique_token_to_refer
              : token_to_refer
              ? paths.auth.jwt.register + "?rt=" + token_to_refer
              : paths.auth.jwt.register,
            { state: { data: data } }
          );
        }
      } catch (err) {
        console.log(err);
        if (isMounted()) {
          setLoading(false);
          setErrorMessage(err.data.message);
        }
      }
    },
    onError: (errorResponse) => console.log("ERROR:", errorResponse.error),
  });

  return (
    <Fab
      onClick={() => googleLogin()}
      style={{ background: "#ffffff17" }}
      aria-label="add"
    >
      <Icon style={{ fontSize: 25 }} icon="logos:google-icon" />
    </Fab>
  );
}

export default GoogleVerificationButton;
