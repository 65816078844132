import Select from "./Select";
import DatePicker from "./DatePicker";
import MenuComp from "./Menu";
import { Popup } from "./Popup";
import PageHeader from "./PageHeader";
import { EntityMainInfo } from "./EntityMainInfo";
import ConfirmDialog from "./ConfirmDialog";
import { MultiCheckBox } from "./MultiCheckBox";
import Autocomplete from "./Autocomplete";

const Controls = {
    Select,
    DatePicker,
    MenuComp,
    PageHeader,
    Popup,
    EntityMainInfo,
    ConfirmDialog,
    MultiCheckBox,
    Autocomplete
};

export default Controls;
