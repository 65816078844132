import { lazy } from "react";
import { Outlet } from "react-router-dom";
import { IssuerGuard } from "src/guards/issuer-guard";
import { GuestGuard } from "src/guards/guest-guard";
import { Layout as AuthLayout } from "src/layouts/auth/classic-layout";
import { Issuer } from "src/utils/auth";

const JwtLoginPage = lazy(() => import("src/pages/auth/jwt/login"));
const JwtRegisterPage = lazy(() => import("src/pages/auth/jwt/register"));
const JwtRegisterOptionsPage = lazy(() =>
  import("src/pages/auth/jwt/registerOptions")
);
const JwtRegisterSubmitPaymentPage = lazy(() =>
  import("src/pages/auth/jwt/finishRegistration")
);
const JwtForgotPassword = lazy(() =>
  import("src/pages/auth/jwt/forgotPassword")
);
const JwtCreateNewPassword = lazy(() =>
  import("src/pages/auth/jwt/createNewPassword")
);

export const authRoutes = [
  {
    path: "auth",
    element: (
      <IssuerGuard issuer={Issuer.JWT}>
        <GuestGuard>
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        </GuestGuard>
      </IssuerGuard>
    ),
    children: [
      {
        path: "login",
        element: <JwtLoginPage />,
      },
      {
        path: "register",
        element: <JwtRegisterPage />,
      },
      {
        path: "registeroptions",
        element: <JwtRegisterOptionsPage />,
      },
      {
        path: "register/finish-registration",
        element: <JwtRegisterSubmitPaymentPage />,
      },
      {
        path: "/auth/forgot-password",
        element: <JwtForgotPassword />,
      },
      {
        path: "/auth/create-new-password",
        element: <JwtCreateNewPassword />,
      },
    ],
  },
];
