import axios from "axios";
import { useAuth } from "src/hooks/use-auth";
import CRMUtils from "src/utils";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_KEY || `http://localhost:8000/api`,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer ${token}`;

  if (process.env.REACT_APP_PUBLIC_ACCESS_API_KEY) {
    config.headers["PUBLIC-ACCESS-API-KEY"] =
      process.env.REACT_APP_PUBLIC_ACCESS_API_KEY;
  }

  const currentToken = CRMUtils.getURLOrganizationToken(window.location.href);

  if (currentToken) {
    config.headers["ORGANIZATION_TOKEN"] = currentToken;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    let json = error.response.data;
    let message = json.message;

    // if (error.response.status === 403 && error.response.data.organization) {
    //   addConte
    // }
    if (error.response.status === 401) {
    } else if (
      error.response.status === 403 &&
      message == "Invalid scope(s) provided."
    ) {
      removeLocalStorage();

      window.location.href = "/auth/login";
    } else if (error.response.status === 404) {
      message = "El recurso no existe";
    }
    return Promise.reject(error.response);
  }
);
function removeLocalStorage() {
  localStorage.removeItem("accessToken");
}

function apiGet(url_code, getFilters = null, id = null, contact_id = null) {
  var url = url_code != null ? apiUrl[url_code] : "";

  if (url == null || url.length == 0) {
    console.log("!!! GET ERROR : No url code found");
    return "";
  }

  if (id && url.indexOf("{id}") > 0) {
    url = url.replace("$", "");
    var temp = url.split("{id}");
    url = temp[0] + id + temp[1];
  }

  if (contact_id && url.indexOf("{contact_id}") > 0) {
    url = url.replace("$", "");
    var temp = url.split("{contact_id}");
    url = temp[0] + contact_id + temp[1];
  }

  var include,
    params,
    filters = [];
  var page,
    limit = 0;
  var search = null;
  var sort = null;

  if (getFilters != null) {
    include = getFilters.include != null ? getFilters.include : include;
    page = getFilters.page != null ? getFilters.page : page;
    limit = getFilters.limit != null ? getFilters.limit : limit;
    params = getFilters.params != null ? getFilters.params : params;
    filters = getFilters.filters != null ? getFilters.filters : filters;
    search = getFilters.search != null ? getFilters.search : search;
    sort = getFilters.sort != null ? getFilters.sort : sort;
  }
  var urlPart = "";
  var operator = "";
  if (include != null && include.length > 0) {
    include.forEach((element) => {
      operator = operator == "" ? "?" : "&";
      urlPart += operator == "?" ? "?include=" : ",";
      urlPart += element;
    });
  }
  if (page != null && page > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "page=" + page;
  }
  if (limit != null && limit > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "limit=" + limit;
  }
  /*if (params != null && params.length > 0) {
    params.forEach((element) => {
      urlPart += urlPart == "" ? "?" : "&";
      urlPart += element.code + "=" + element.value;
    });
  }*/
  if (search != null && search.length > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "search=" + search;
  }

  if (sort != null && sort.length > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "sort=" + sort;
  }
  url += urlPart;

  url = setParamsAndFiltersUrl(url, params, operator, filters);

  return instance.get(url);
  //return instance.get(`/accounting/expenseitems?include=accExpenseCategory`);
}

function setParamsAndFiltersUrl(url, params, operator, filters = null) {
  //var params = _params!=null?_params.entries():null;
  if (params != null) {
    for (var key in params) {
      var value = params[key];
      var find = "${" + key + "}";
      if (url.includes(find)) {
        url = url.replace(find, value);
      } else {
        operator = operator == "" ? "?" : "&";
        url += operator + key + "=" + value;
      }
    }
  }
  if (filters != null) {
    for (var key in filters) {
      var value = filters[key];
      key = key.replace("___", ".");
      var str = "filter[" + key + "]=" + value;
      operator = operator == "" ? "?" : "&";
      url += operator + str;
    }
  }
  return url;
}

const apiUrl = {
  /*users*/
  GET_USERS: "/users",
  GET_NOTIFICATIONS: "/users/${id}/notifications",

  /*roles*/
  GET_ROLES: "/roles",

  /*logs*/
  GET_LOGS: "/logs",
  GET_LOGSBYMODEL: "/logs/bymodel",

  /*products*/
  GET_PRODUCTS: "/products",

  /*producttypes*/
  GET_PRODUCTTYPES: "/producttypes",

  /*patients*/
  GET_PATIENTS: "/patients",
  GET_MYPATIENTS: "/user/${id}/mypatients",

  /*orders*/
  GET_ORDERS: "/orders",
  GET_MYORDERS: "/user/${id}/myorders",

  /*orderitems*/
  GET_ORDERITEMS: "/orderitems",

  /*shippers*/
  GET_SHIPPERS: "/shippers",

  /*laboratories*/
  GET_LABORATORIES: "/laboratories",

  /*senders*/
  GET_SENDERS: "/senders",

  /*recipient_models*/
  GET_RECIPIENTMODELS: "/recipientmodels",

  /*shipping*/
  GET_SHIPPING: "/shipping",

  /*pickups*/
  GET_PICKUPS: "/shipping/getpickups",

  /*samples*/
  GET_SAMPLESREPORT: "/samples",

  /*template_categories*/
  GET_TEMPLATECATEGORIES: "/templates_categories",

  /*templates*/
  GET_TEMPLATES: "/templates",

  /*campaigns*/
  GET_CAMPAIGNS: "/campaigns",

  /*offers*/
  GET_OFFERS: "/offers",

  /*offer_types*/
  GET_OFFERTYPES: "/offer_types",

  /*organizations*/
  GET_ORGANIZATIONS: "/organizations",

  /*kits*/
  GET_KITS: "/kits",

  /*departments*/
  GET_DEPARTMENTS: "/departments",

  /*campaign*/
  GET_SENTCAMPAIGNDETAILS: "/campaigns/${id}/details",
};

const Api = {
  getDashboardData: function (payload) {
    return instance.post(`/dashboarddata`, payload);
  },

  //google
  getGoogleUserInformation: function (token, params = "") {
    return instance.get(`/users/googleaccountinfo/${token}${params}`);
  },

  //facebook
  getFacebookUserInformation: function (token, params = "") {
    return instance.get(`/users/facebookaccountinfo/${token}${params}`);
  },

  //permissions
  getPermissions: function () {
    return instance.get(`/permissions`);
  },

  //general
  getDoctors: function (params = "") {
    return instance.get(`/doctors${params}`);
  },
  uploadImage: function (payload) {
    return instance.post(`/upload_image`, payload);
  },

  getLanguages: function () {
    return instance.get(`/languages`);
  },

  //users
  getUsersToDropdown: function () {
    return instance.get(`/usersdropdown`);
  },
  updateUserSetting: function (id, payload) {
    return instance.post(`/users/${id}/updatesettings`, payload);
  },
  getAllNotificationsByUser: function (id, params = "") {
    return instance.get(`/users/${id}/notifications?${params}`);
  },
  markAllNotificationAsRead: function (id) {
    return instance.post(`/users/${id}/notifications/allasread`);
  },
  markNotificationAsRead: function (id) {
    return instance.post(`/notifications/${id}`);
  },
  getTotalUnreadNotifications: function (id) {
    return instance.post(`/users/${id}/unreadnotifications`);
  },

  registerToken: function (id, payload) {
    return instance.post(`/users/${id}/tokens`, payload);
  },
  updateUser: function (payload, id) {
    return instance.post(`/users/${id}`, payload);
  },

  getUser: function (id) {
    return instance.get(`/users/${id}`);
  },
  getMyReferrals: function (id, params = "") {
    return instance.get(`/users/${id}/myreferrals${params}`);
  },

  createUser: function (payload) {
    return instance.post(`/users`, payload);
  },

  deleteUser: function (id) {
    return instance.delete(`/users/${id}`);
  },

  changeUserPassword: function (payload, id) {
    return instance.post(`/users/${id}/changepassword`, payload);
  },
  getUserSamples: function (id) {
    return instance.get(`/user/${id}/mysamples`);
  },

  getUserOrders: function (params = "", id) {
    return instance.get(`/user/${id}/myorders${params}`);
  },

  switchUserOrganization: function (user_id, organization_id) {
    return instance.patch(`/users/${user_id}/organizations/${organization_id}`);
  },

  updateUserLanguage: function (user_id, language) {
    return instance.patch(`/users/${user_id}/languages/${language}`);
  },

  generateUserUniqueReferralToken: function (id, payload) {
    return instance.post(`/users/${id}/referral_tokens`, payload);
  },

  checkKitCodeDuplicity: function (id, code) {
    return instance.get(`/organizations/${id}/${code}`);
  },

  getAvailableUserReferralCredits: function (organization_id, user_id) {
    return instance.get(
      `/organizations/${organization_id}/users/${user_id}/available-referral-credits`
    );
  },

  //statuses
  getStatusByModel: function (model, params = "") {
    return instance.get(`/statuses/bymodel/${model}${params}`);
  },

  //roles
  getRoles: function () {
    return instance.get(`/roles`);
  },

  updateRole: function (payload, id) {
    return instance.post(`/roles/${id}`, payload);
  },

  createRole: function (payload) {
    return instance.post(`/roles`, payload);
  },

  deleteRole: function (id) {
    return instance.delete(`/roles/${id}`);
  },

  //products
  getProducts: function (params = "") {
    return instance.get(`/products${params}`);
  },

  getRequiredProductsToSubscribe: function (payload = null) {
    return instance.post(`/products/requiredtosubscribe`, payload);
  },

  updateProduct: function (payload, id) {
    return instance.post(`/products/${id}`, payload);
  },

  createProduct: function (payload) {
    return instance.post(`/products`, payload);
  },

  deleteProduct: function (id) {
    return instance.delete(`/products/${id}`);
  },

  getAvailableProductsToBuy: function (params = "") {
    return instance.get(`/products/available_to_buy${params}`);
  },

  //orders
  getOrders: function () {
    return instance.get(`/orders`);
  },

  getOrderPayments: function (id) {
    return instance.get(`/orders/${id}/payments`);
  },

  getOrder: function (id) {
    return instance.get(`/orders/${id}`);
  },

  updateOrder: function (payload, id) {
    return instance.post(`/orders/${id}`, payload);
  },

  createOrder: function (payload) {
    return instance.post(`/orders`, payload);
  },

  deleteOrder: function (id) {
    return instance.delete(`/orders/${id}`);
  },

  addOrderItems: function (payload, id) {
    return instance.post(`/orders/${id}/additems`, payload);
  },
  sendOrderPaymentRequest: function (payload) {
    return instance.post(`/orders_send_payment_request`, payload);
  },
  //productTypes
  getProductTypes: function () {
    return instance.get(`/producttypes`);
  },

  updateProductType: function (payload, id) {
    return instance.post(`/producttypes/${id}`, payload);
  },

  createProductType: function (payload) {
    return instance.post(`/producttypes`, payload);
  },

  deleteProductType: function (id) {
    return instance.delete(`/producttypes/${id}`);
  },

  //patients
  getPatients: function (params = "") {
    return instance.get(`/patients${params}`);
  },

  updatePatient: function (payload, id) {
    return instance.post(`/patients/${id}`, payload);
  },

  createPatient: function (payload) {
    return instance.post(`/patients`, payload);
  },

  deletePatient: function (id) {
    return instance.delete(`/patients/${id}`);
  },

  getPatientComments: function (patient_id) {
    return instance.get(`/patients/comments/${patient_id}`);
  },

  updatePatientComment: function (payload, id) {
    return instance.post(`/patients/comments/${id}`, payload);
  },

  storePatientComment: function (payload, id) {
    return instance.post(`/patients/${id}/comments`, payload);
  },

  getPatientOrders: function (id) {
    return instance.get(`/orders/patient/${id}`);
  },

  //orderitems
  updateOrderItemSample: function (payload, id) {
    return instance.post(`/orders/item/${id}/sample`, payload);
  },
  payOrderItem: function (payload, id) {
    return instance.post(`/orders/item/${id}/payorderitem`, payload);
  },
  changeOrderItemDoctor: function (payload, id) {
    return instance.patch(`/orders/item/${id}/change_doctor`, payload);
  },
  changeOrderItemVisibility: function (payload, id) {
    return instance.patch(`/orders/item/${id}/change_visibility`, payload);
  },

  //sample
  updateSample: function (payload, id) {
    return instance.post(`/samples/${id}`, payload);
  },
  getSamplesByStatus: function (payload = null) {
    return instance.post(`/samplesbystatus`, payload);
  },

  getEntities: function (apiParams) {
    return apiGet(
      apiParams.dataUrl.code,
      apiParams.filters,
      apiParams.dataUrl.id ? apiParams.dataUrl.id : null
    );
  },
  attachResultsToSample: function (payload, id) {
    return instance.post(`/samples/${id}/results`, payload);
  },
  updateSampleStatus: function (status_id, id) {
    return instance.patch(`/samples/${id}/status/${status_id}`);
  },

  //payments
  uncompletedFirstPayment: function (id) {
    return instance.get(`users/uncompletedfirstpayment/${id}`);
  },

  retryFirstPayment: function (payload) {
    return instance.post(`/payments/retryfirstpayment`, payload);
  },

  retrySubmitFirstPayment: function (payload) {
    return instance.post(`/payments/retrysubmitfirstpayment`, payload);
  },

  //stripepayment
  stripeCreatePaymentIntent: function (payload) {
    return instance.post(`/stripe/paymentintent`, payload);
  },

  stripeRetrievePaymentIntent: function (id) {
    return instance.get(`/stripe/paymentintent/${id}`);
  },

  //logs
  getLogsModelsDropDown: function (payload) {
    return instance.get(`/logsmodelsDropDown`);
  },

  //register
  register: function (payload) {
    return instance.post(`/register`, payload);
  },

  registerWithoutOnlinePayment: function (payload) {
    return instance.post(`/register-without-payment`, payload);
  },

  //shippers
  getShippers: function (params = "") {
    return instance.get(`/shippers${params}`);
  },
  createShipper: function (payload) {
    return instance.post(`/shippers`, payload);
  },
  updateShipper: function (id, payload) {
    return instance.post(`/shippers/${id}`, payload);
  },
  deleteShipper: function (id, payload) {
    return instance.delete(`/shippers/${id}`, payload);
  },

  //senders
  getSenders: function (params = "") {
    return instance.get(`/senders${params}`);
  },
  createSender: function (payload) {
    return instance.post(`/senders`, payload);
  },
  updateSender: function (id, payload) {
    return instance.post(`/senders/${id}`, payload);
  },
  deleteSender: function (id, payload) {
    return instance.delete(`/senders/${id}`, payload);
  },

  //laboratories
  getLaboratories: function (params = "") {
    return instance.get(`/laboratories${params}`);
  },
  createLaboratory: function (payload) {
    return instance.post(`/laboratories`, payload);
  },
  updateLaboratory: function (id, payload) {
    return instance.post(`/laboratories/${id}`, payload);
  },
  deleteLaboratory: function (id, payload) {
    return instance.delete(`/laboratories/${id}`, payload);
  },

  //recipientsModels
  getRecipientModels: function (params = "") {
    return instance.get(`/recipientmodels${params}`);
  },
  createRecipientModel: function (payload) {
    return instance.post(`/recipientmodels`, payload);
  },
  updateRecipientModel: function (id, payload) {
    return instance.post(`/recipientmodels/${id}`, payload);
  },
  deleteRecipientModel: function (id, payload) {
    return instance.delete(`/recipientmodels/${id}`, payload);
  },
  getElegibleModelsToDropdown: function (payload) {
    return instance.post(`/recipientmodels/modelstodropdown`, payload);
  },

  //general
  getItemsByModel: function (payload) {
    return instance.post(`/itemsbymodel`, payload);
  },
  getOffersCloseToExpiring: function () {
    return instance.get(`/offers_close_to_expiring`);
  },

  //shipping
  validateShippingTime: function (payload) {
    return instance.post(`/shipping/transittime`, payload);
  },

  getPackageTypes: function (sender_id) {
    return instance.get(`/shipping/packagetypes/${sender_id}`);
  },

  makeShipping: function (payload) {
    return instance.post(`/shipping/makeshipping`, payload);
  },

  getAvailabeItemsToShipping: function (payload) {
    return instance.post(`/samplestoshipping`, payload);
  },

  voidShipment: function (payload) {
    return instance.post(`/shipping/void`, payload);
  },

  trackingShipment: function (payload) {
    return instance.post(`/shipping/tracking`, payload);
  },

  ratingShipment: function (payload) {
    return instance.post(`/shipping/rating`, payload);
  },
  getShipmentsByStatus: function (payload = null) {
    return instance.post(`/shipmentsbystatus`, payload);
  },

  getShipmentsForPickup: function (params = "") {
    return instance.get(`/shipping/getshipmentsforpickup${params}`);
  },

  createPickup: function (payload) {
    return instance.post(`/shipping/schedulepickup`, payload);
  },

  voidPickup: function (payload) {
    return instance.post(`/shipping/voidpickup`, payload);
  },

  ratePickUp: function (payload) {
    return instance.post(`/shipping/ratepickup`, payload);
  },

  //templates
  getTemplates: function (params = "") {
    return instance.get(`/templates${params}`);
  },
  createTemplate: function (payload) {
    return instance.post(`/templates`, payload);
  },
  updateTemplate: function (payload, id) {
    return instance.post(`/templates/${id}`, payload);
  },
  deleteTemplate: function (id) {
    return instance.delete(`/templates/${id}`);
  },

  //template_categories
  getTemplateCategories: function (params = "") {
    return instance.get(`/templates_categories${params}`);
  },
  createTemplateCategory: function (payload) {
    return instance.post(`/templates_categories`, payload);
  },
  updateTemplateCategory: function (payload, id) {
    return instance.post(`/templates_categories/${id}`, payload);
  },
  deleteTemplateCategory: function (id) {
    return instance.delete(`/templates_categories/${id}`);
  },

  //campaigns
  getCampaigns: function (params = "") {
    return instance.get(`/campaigns${params}`);
  },
  createCampaign: function (payload) {
    return instance.post(`/campaigns`, payload);
  },
  updateCampaign: function (payload, id) {
    return instance.post(`/campaigns/${id}`, payload);
  },
  deleteCampaign: function (id) {
    return instance.delete(`/campaigns/${id}`);
  },
  getTimelineCampaignEvents: function () {
    return instance.get(`/timeline-campaign-events`);
  },
  getSentCampaignEvents: function (id, params = "") {
    return instance.get(`/campaigns/${id}/details${params}`);
  },

  //offers
  getOffers: function (params = "") {
    return instance.get(`/offers${params}`);
  },
  createOffer: function (payload) {
    return instance.post(`/offers`, payload);
  },
  updateOffer: function (payload, id) {
    return instance.post(`/offers/${id}`, payload);
  },
  deleteOffer: function (id) {
    return instance.delete(`/offers/${id}`);
  },
  checkOrderProducts: function (payload) {
    return instance.post(`/check_order_products`, payload);
  },
  getAvailableOffers: function () {
    return instance.get(`/available_offers`);
  },

  //offer_types
  getOfferTypes: function (params = "") {
    return instance.get(`/offer_types${params}`);
  },
  createOfferType: function (payload) {
    return instance.post(`/offer_types`, payload);
  },
  updateOfferType: function (payload, id) {
    return instance.post(`/offer_types/${id}`, payload);
  },
  deleteOfferType: function (id) {
    return instance.delete(`/offer_types/${id}`);
  },

  //merchant
  sendPaymentRequest: function (payload) {
    return instance.post(`/hooks/merchants/sendPaymentRequest`, payload);
  },

  //organizations
  getOrganizations: function (params = "") {
    return instance.get(`/organizations`);
  },
  createOrganization: function (payload) {
    return instance.post(`/organizations`, payload);
  },
  updateOrganization: function (payload, id) {
    return instance.post(`/organizations/${id}`, payload);
  },
  deleteOrganization: function (id) {
    return instance.delete(`/organizations/${id}`);
  },
  getOrganizationMerchantConfiguration: function (id) {
    return instance.get(
      `/hooks/merchants/organizations/${id}/merchant_configuration`
    );
  },
  getOrganizationByToken: function (token = "", auth = true) {
    if (auth) {
      return instance.get(`/auth-organizations/${token}`);
    }
    return instance.get(`/organizations/${token}`);
  },

  getProductsStatistics: function (params = "") {
    return instance.get(`/organizations-products-dashboard${params}`);
  },

  //countries
  getCountries: function (params = "") {
    return instance.get(`/countries${params}`);
  },

  //currencies
  getCurrencies: function (params = "") {
    return instance.get(`/currencies${params}`);
  },

  //kits
  getKits: function (params = "") {
    return instance.get(`/kits${params}`);
  },
  createKit: function (payload) {
    return instance.post(`/kits`, payload);
  },
  updateKit: function (payload, id) {
    return instance.post(`/kits/${id}`, payload);
  },
  deleteKit: function (id) {
    return instance.delete(`/kits/${id}`);
  },

  //get-referral-token-info
  getReferralToken: function (token) {
    return instance.get(`/referral-tokens/${token}`);
  },

  //sample-types
  getSampleTypes: function (params = "") {
    return instance.get(`/sample-types${params}`);
  },

  //departments
  getDepartments: function () {
    return instance.get(`/departments`);
  },

  updateDepartment: function (payload, id) {
    return instance.post(`/departments/${id}`, payload);
  },

  createDepartment: function (payload) {
    return instance.post(`/departments`, payload);
  },

  deleteDepartment: function (id) {
    return instance.delete(`/departments/${id}`);
  },

  //forgot-pwd methods
  getModelToken: function (token) {
    return instance.get(`/model-token/${token}`);
  },
  createNewPassword: function (id, payload) {
    return instance.post(`/users/${id}/create-new-password`, payload);
  },
  sendForgotPwdEmail: function (payload) {
    return instance.post(`/forgot-pwd-email`, payload);
  },
};

export default Api;
