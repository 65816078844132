import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import moment from "moment";
import CRMUtils from "src/utils";
import { OfferRequirements } from "./OfferRequirements";
import { findIndex } from "lodash";
import { useTheme } from "@emotion/react";

export const AvailableOffersList = (props) => {
  const { t } = useTranslation();
  const {
    availableOffers = [],
    setAvailableOffers,
    titleExtraAction = "",
    offersLoading=false,
  } = props;
  const theme = useTheme();
  const changeOffer = (id, fields) => {
    const index = findIndex(availableOffers, ["id", id]);
    const updatedEvent = {
      ...availableOffers[index],
      ...fields,
    };

    let newOffers = [...availableOffers];
    newOffers[index] = updatedEvent;
    setAvailableOffers([...newOffers]);
  };

  return (
    <>
      {availableOffers.length > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              padding: "0px 12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              style={{ width: 22, height: 22, marginRight: 4 }}
              icon="bxs:offer"
            />
            <Typography style={{ fontSize: 17, fontWeight: 600 }}>
              {t("offers:offers.orders_form.dont_miss_our_offers")}
            </Typography>
          </div>
          {titleExtraAction}
        </div>
      ) : offersLoading ? (
        <LinearProgress />
      ) : (
        <div
          style={{
            textAlign: "center",
            background: "#9494942b",
            padding: "22px 6px",
            borderRadius: 22,
          }}
        >
          {" "}
          <Icon
            style={{ width: 38, height: 38 }}
            icon={"hugeicons:file-not-found"}
          />
          <Typography
            style={{ fontSize: 16, fontWeight: 500, textAlign: "center" }}
          >
            {t("offers:offers.orders_form.no_offers")}
          </Typography>
        </div>
      )}

      {availableOffers.map((offer) => {
        return (
          <div
            style={{
              margin: "8px 8px",
              backgroundColor: "rgb(179 179 179 / 11%)",
              borderRadius: 10,
            }}
          >
            <Accordion
              style={{ boxShadow: "none", background: "none" }}
              expanded={offer.expanded}
              onChange={() => {
                changeOffer(offer.id, {
                  expanded: !offer.expanded,
                });
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    borderRadius: 10,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontWeight: 500, fontSize: 17 }}>
                          {CRMUtils.getFieldTranslation(offer.name)}
                        </Typography>
                        <Typography
                          color="primary"
                          style={{
                            fontSize: 17,
                            fontWeight: 600,
                            marginLeft: 10,
                          }}
                        >
                          {"(" +
                            (offer.expanded
                              ? t("common:general.Less_details")
                              : t("common:general.More_details")) +
                            "...)"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {offer.end_date ? (
                    <div
                      style={{
                        color: offer?.color || "",
                        marginLeft: 25,
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        color="primary"
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          marginRight: 5,

                          textTransform: "uppercase",
                        }}
                      >
                        {t("offers:offers.form.end_date")}
                        {": "}
                      </Typography>
                      <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                        {" "}
                        {` ${moment(offer.end_date).format(
                          "MM/DD/YYYY,"
                        )}`}{" "}
                      </Typography>
                      <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                        {" "}
                        {` ${moment(offer.end_date).format("hh:mm A")}`}{" "}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </AccordionSummary>

              <AccordionDetails
                style={{
                  backgroundColor: "#9b9b9b12",
                  borderLeft: "2px solid",
                  borderLeftColor: theme.palette.primary.main,
                  marginBottom: 8,
                }}
              >
                <Typography style={{ textAlign: "justify", margin: 10 }}>
                  {CRMUtils.getFieldTranslation(offer?.description || null)}
                </Typography>

                <br />

                <OfferRequirements offer={offer} />
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </>
  );
};
