import { useTranslation } from 'react-i18next';
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { LanguagePopover } from './language-popover';
import { Icon } from '@iconify/react';

const languages = {
  en: 'twemoji:flag-united-states',
  es: 'emojione-v1:flag-for-spain',
  fr: 'twemoji:flag-france'
};

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const popover = usePopover();


  const flag = languages[i18n.language];

  return (
    <>
      <Tooltip title="Language">
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <Box
            sx={{
              width: 28,
              '& img': {
                width: '100%'
              }
            }}
          >
            <SvgIcon>
              <Icon icon={flag} />
            </SvgIcon>

          </Box>
        </IconButton>
      </Tooltip>
      <LanguagePopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
